import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export enum ThemeColors {
  color1 = "#A173BF",
  color1Hover = "#76548C",
  color2 = "#EAF205",
  color2Hover = "#B9BF04",
  color3 = "#F25244", // error color
  color3Hover = "#BF4136",
  color4 = "#0D0D0D",
  color5 = "#404040",
  color6 = "#BFBFBF",
  color7 = "#F2F2F2",
  color8 = "#E6E6E6",
  white = "#ffffff"
}

@Injectable({
  providedIn: 'root'
})
export class IosStatusBarService {

  constructor(@Inject(PLATFORM_ID) private platformId: string,) { }

  changeNativeStatusBarColor(color: string) {

    try {

      // Native StatusBar available
      if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() === 'android' && Capacitor.isPluginAvailable('StatusBar')) {

        // Tint statusbar color
        StatusBar.setBackgroundColor({
          color: color
        });
        StatusBar.setStyle({
          style: Style.Light
        });


      }
    } catch (error) {

      console.error(error);

    }

  }
}
