import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID, signal, WritableSignal } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ChatService } from 'src/app/chat/chat.service';
import { environment } from 'src/environments/environment';
import { BlockingInfo } from '../interfaces/user.interface';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BlockUserService {

  $notifyAboutBlockingStatusChange: Subject<string> = new Subject<string>();

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  public blockUser(id: string | number): void {

    if (isPlatformBrowser(this.platformId) && this.authService.getIsAuthenticated() && id) {

      const postData = new FormData();
      postData.append("blockedId", id.toString());
      ;
      let params: HttpParams = new HttpParams().set("blockedId", id.toString());

      this.$notifyAboutBlockingStatusChange.next(id.toString());

      this.http.post<any>(environment.apiUrl + "muteUser", null, { params: params, withCredentials: true })
        .subscribe({
          next: response => {

          },
          error: error => {

            console.log(error);

          }
        })

    }

  }

}
