import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsocketService } from './websocket.service';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

const config: SocketIoConfig = {
  url: environment.websocketUrl, options: {
    withCredentials: false,
    transports: ['polling' ],
  }
};


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // AuthModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [WebsocketService]
})
export class WebsocketModule {}
