import { MatIconModule } from '@angular/material/icon';
import { MyProfileDataService } from '../../services/my-profile-data.service';
import { Component, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit, HostListener, Optional, Inject, ChangeDetectionStrategy, ChangeDetectorRef, input, Input } from '@angular/core';
import Cropper from 'cropperjs';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { v4 as uuidv4 } from 'uuid';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule
  ],
  selector: 'rs-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})
export class ImageCropperComponent implements AfterViewInit {

  @Input("isSelectingProfilePicture") isSelectingProfilePicture: boolean = false;
  @ViewChild("cropperDialog") cropperDialog: ElementRef;

  imageWidth: number = null;
  imageHeight: number = null;
  readonly aspectRatio: number = 3 / 2;

  private myProfilePictureCropperCanvas;
  private ctx;
  cropBoxSizeInitialized: boolean = false;

  cropper;
  // Cant use css for width since it needs to be an style property for the cropper framework
  croperDialogContainerWidth: string;
  private currentHorizontalScale = 1;
  private currentVerticalScale = 1;

  private readonly mobileBreakpoint: number = 800;

  constructor(
    public dialogRef: MatDialogRef<ImageCropperComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public file: File,
    private myProfileDataService: MyProfileDataService,
  ) {

  }
  ngAfterViewInit() {

    setTimeout(() => {

      this.initializeCanvas();

    }, 1000);

  }
  private initializeCanvas(): void {

    let that = this;

    this.croperDialogContainerWidth = window.innerWidth < this.mobileBreakpoint ? "100vw" : "65vw";

    setTimeout(_ => {

      this.myProfilePictureCropperCanvas = document.getElementById("rs-my-profile-picture-cropper-canvas");
      this.ctx = this.myProfilePictureCropperCanvas.getContext("2d");

      var img = document.getElementById("my-profile-profile-picture") as HTMLImageElement;

      this.imageWidth = img.clientWidth;
      this.imageHeight = img.clientHeight;

      setTimeout(() => {

        that.ctx.drawImage(img, 0, 0, img.clientWidth, img.clientHeight);

        this.cropper = new Cropper(that.myProfilePictureCropperCanvas, {
          aspectRatio: 2 / 2, //3 / 2,
          dragMode: "crop",
          viewMode: 2,
          modal: true,
          background: false,
          crop(event) {

          },
          cropstart(event) {

          },
          ready(event) {

            if (!this.cropBoxSizeInitialized) {

              var boxData = this.cropper.cropBoxData;

              // this.cropper.setCropBoxData({ left: 11.25, top: 7.5, height: boxData.maxHeight - 22.5, width: boxData.maxWidth - 22.5 })

              this.cropBoxSizeInitialized = true;

            }

          }
        });

      }, 1);
    })

  }
  scaleHorizontal(): void {

    this.currentHorizontalScale = this.currentHorizontalScale * -1;
    this.cropper.scale(this.currentHorizontalScale, this.currentVerticalScale);

  }
  scaleVertical(): void {

    this.currentVerticalScale = this.currentVerticalScale * -1;
    this.cropper.scale(this.currentHorizontalScale, this.currentVerticalScale);

  }
  applyCropping() {

    const croppedCanvas = this.cropper.getCroppedCanvas() as HTMLCanvasElement;

    if (croppedCanvas) croppedCanvas.toBlob(blob => {

      // the uploads function works with a file so the blob needs to be converted
      let blobFile: any = blob;
      let date = new Date();
      blobFile.lastModifiedDate = date;
      blobFile.name = uuidv4();

      this.myProfileDataService.uploadImage(blobFile);
    });

    this.dialogRef.close();

  }
  closeDialog() {

    this.dialogRef.close();

  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {

    // this.imageWidth = this.cropperDialog.nativeElement.clientWidth;
    // this.imageHeight = this.cropperDialog.nativeElement.clientHeight;

    this.croperDialogContainerWidth = window.innerWidth < this.mobileBreakpoint ? "100vw" : "65vw";


  }

}



