import { Component } from '@angular/core';

@Component({
  selector: 'ios-status-bar',
  standalone: true,
  imports: [],
  templateUrl: './ios-status-bar.component.html',
  styleUrl: './ios-status-bar.component.scss'
})
export class IosStatusBarComponent {

}
