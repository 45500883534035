import { AfterViewChecked, AfterViewInit, Component, Input, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { After } from 'v8';

@Component({
  selector: 'rs-splash-screen',
  standalone: true,
  imports: [
    MatIconModule
  ],
  templateUrl: './splash-screen.component.html',
  styleUrl: './splash-screen.component.scss'
})
export class SplashScreenComponent {

  @Input() infoText = "Rattlestork";

  constructor() { }
}
