import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {

  showSplashScreen = signal<boolean>(false);

  constructor() {
    console.log("SplashScreenService: Service created");
  }

  startSplashScreen() {

    this.showSplashScreen.set(true);
    console.log("SplashScreenService: SplashScreen started");

  }
  stopSplashScreen() {

    this.showSplashScreen.set(false);
    console.log("SplashScreenService: SplashScreen stopped");

  }
  isSplashScreenActive() {

    return this.showSplashScreen();

  }

}
