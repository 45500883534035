import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export function validValue(acceptedValues: string[]): ValidatorFn {
  return (fc: AbstractControl): ValidationErrors => {

    let result: ValidationErrors = null;

    let values: any = fc.value;
    if (!Array.isArray(fc.value)) values = [values];

    for (let value of values) {

      if (acceptedValues.indexOf(value) == -1) result = { error: 'Invalid input!' };

    }

    return result;

  };
}
