import { Inject, Injectable, Injector, OnInit, PLATFORM_ID, inject, Pipe, OnDestroy, AfterViewInit } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subscription, takeUntil } from 'rxjs';
import { SwPush } from '@angular/service-worker';
import { isPlatformBrowser } from '@angular/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

export interface Message {
  _id: string,
  from: string,
  to: string,
  viewed: boolean
  message: string,
  createdAt: string,
  image?: string
}

@Injectable({
  providedIn: 'root'
})
export class WebsocketService implements AfterViewInit, OnDestroy {

  readonly VAPID_PUBLIC_KEY = "BMOl7Y0HDTjKB-EsZOUCCjyhp5fKip1g-LUuU6SgtVwdGP5qNXmaRkJeEEoQ4YnwHPR6a1DU6LQ-YU6bMvgzDOo";
  readonly restEndpointUrl: string = environment.websocketUrl;

  $incomingMessage: BehaviorSubject<any> = new BehaviorSubject(null);
  private socket;
  subscriptions: Subscription = new Subscription();

  constructor(
    private swPush: SwPush,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cookieService: SsrCookieService
  ) {
    if (isPlatformBrowser(this.platformId)) this.socket = inject(Socket);

  }
  ngAfterViewInit(): void {


  }
  ngOnDestroy(): void {

    this.subscriptions.unsubscribe();

  }
  public initWebSocketConnection(authService: AuthService, origin) {

    if (isPlatformBrowser(this.platformId)) {

      // Listen to authentication changes
      this.subscriptions.add(authService.getAuthStatusListener().subscribe(isAuthenticated => {

        if (!isAuthenticated) this.disconnectSocket();
        else {

          this.registerSocket(authService);
          this.newMessageListener();

        }

      }));

    }

  }
  /**
   * Registers the websocket. If the connection fails false will
   * be returned, else true.
   */
  public registerSocket(authService: AuthService): void {

    if (isPlatformBrowser(this.platformId)) {

      this.socket.on("connect", () => {

        this.registerActiveSocketAtServer(authService);
        this.addSocketErrorHandling();

      });

      if (!this.socket.ioSocket.connected) this.socket.ioSocket.connect()
      else {

        this.registerActiveSocketAtServer(authService);
        this.addSocketErrorHandling();

      }

    }

  }
  private addSocketErrorHandling() {

    this.socket.on("connect_error", (err) => {
      // the reason of the error, for example "xhr poll error"
      console.error(err.message);

      // some additional description, for example the status code of the initial HTTP response
      console.error(err.description);

      // some additional context, for example the XMLHttpRequest object
      console.error(err.context);
    });

  }
  /**
   * Registers the active socket in the user database to enable receiving messages in real time
   */
  private registerActiveSocketAtServer(authService: AuthService): void {

    if (isPlatformBrowser(this.platformId)) {


      let authToken = "Bearer " + authService.getToken();

      this.socket.on("registration-result", res => console.log(res));

      this.socket.emit("register-active-socket", authToken);

    }

  }
  private newMessageListener(): void {

    if (isPlatformBrowser(this.platformId)) {

      this.socket.fromEvent("new message").subscribe({
        next: message => {

          this.$incomingMessage.next(message);

        }
      });

    }

  }
  public disconnectSocket(): void {

    if (isPlatformBrowser(this.platformId)) this.socket.disconnect();

  }
  /**
   * Creates a message object, sends the message
   * to the server and returns the send message
   * object.
   *
   * @param content of the message to be send
   * @param activeChatID
   * @returns
   */
  public async sendMessage(
    content: string,
    activeChatID: string,
    authService: AuthService,
    image: string // Base 64 encoded image
  ) {

    if (isPlatformBrowser(this.platformId)) {

      const messageContent = document.getElementById("rs-chat-form-input-div").innerHTML;
      const authToken = "Bearer " + authService.getToken();
      //const imageString: string = await image.text();

      const message: Message = {
        _id: "",
        from: this.cookieService.get('rs-my-id'),
        to: activeChatID,
        viewed: false,
        message: messageContent,
        createdAt: new Date().toISOString(),
        image: image
      }

      this.socket.emit(
        "private-message",
        activeChatID,
        messageContent,
        authToken,
        image
      );

      return message

    }

  }
  subscribeToNotifications(authService) {

    if (isPlatformBrowser(this.platformId)) {

      this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
        .then(sub => {

          /**
           * sub is the subscription object which will be passed to the subscription service
           * and looks like:
           *
           * {
           *  "endpoint": "https://fcm.googleapis.com/fcm/send/cbx2QC6AGbY:APA91bEjTzUxaBU7j-YN7ReiXV-MD-bmk2pGsp9ZVq4Jj0yuBOhFRrUS9pjz5FMnIvUenVqNpALTh5Hng7HRQpcUNQMFblTLTF7aw-yu1dGqhBOJ-U3IBfnw3hz9hq-TJ4K5f9fHLvjY",
           *  "expirationTime": null,
           *  "keys": {
           *   "p256dh": "BOXYnlKnMkzlMc6xlIjD8OmqVh-YqswZdut2M7zoAspl1UkFeQgSLYZ7eKqKcx6xMsGK7aAguQbcG9FMmlDrDIA=",
           *   "auth": "if-YFywyb4g-bFB1hO9WMw=="
           * }
           */
          this.socket.emit("register-push-subscriber", sub, "Bearer " + authService.getToken());

        })
        .catch(err => console.error("Could not subscribe to notifications", err));
    }

  }
}
