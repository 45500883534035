import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { SsrCookieService } from "ngx-cookie-service-ssr";
import { Observable } from "rxjs";
import { Paths } from 'src/app/app-routing.module';

/**
 * Guard for the language selection page. If the app runs as a native app and no language is set, the user is redirected to the language selection page.
 */
@Injectable()
export class AppLanguageSelectGuard {

  readonly paths = Paths;

  constructor(private router: Router, private cookieService: SsrCookieService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    const runsAsNative = Capacitor.isNativePlatform();
    const language = this.cookieService.check('rs-my-lang');

    if (runsAsNative && !language) {

      this.router.navigate(["/" + this.paths.SelectLanguage]);

      return false;

    } else return true;

  }

}
