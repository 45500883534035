import { Inject, Injectable, LOCALE_ID, PLATFORM_ID, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(
    @Inject(DOCUMENT) public document: Document, // <- this is ssr friendly, see docs for more info
    @Inject(PLATFORM_ID) private platformId: Object,
    private rendererFactory: RendererFactory2,
    private title: Title,
    private meta: Meta,

    private router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) { }
  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
  }

  updateOgUrl(url: string) {

    this.meta.updateTag({ property: 'og:url', content: url + this.document.documentElement.lang })

  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }
  /**
 * Inject the State into the bottom of the <head>
 */
  addCanonicalUrl() {

    let domainName = environment.apiUrl;

    let tag: LinkDefinition = { rel: 'canonical', href: domainName + this.locale + this.router.url };

    try {

      // Check if there are already canonical links, and if so, remove them

      // The if clause is to prevent the error: 'Error within linkService :  ReferenceError: document is not defined'
      // The error just occurs when running the SSR, so we need to check if the document is defined
      if (isPlatformBrowser(this.platformId)) {

        let canonicalLinks: NodeList = document.querySelectorAll("link[rel='canonical']");

        if (canonicalLinks.length > 0) {
          canonicalLinks.forEach((link: HTMLLinkElement) => {

            link.parentElement.removeChild(link);

          });
        }
      }

      const renderer = this.rendererFactory.createRenderer(this.document, {
        id: '-1',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      });

      const link = renderer.createElement('link');
      const head = this.document.head;
      const selector = this._parseSelector(tag);

      if (head === null) {
        throw new Error('<head> not found within DOCUMENT.');
      }

      Object.keys(tag).forEach((prop: string) => {
        return renderer.setAttribute(link, prop, tag[prop]);
      });

      // [TODO]: get them to update the existing one (if it exists) ?
      renderer.appendChild(head, link);

    } catch (e) {
      console.error('Error within linkService : ', e);
    }
  }
  private _parseSelector(tag: LinkDefinition): string {
    // Possibly re-work this
    const attr: string = tag.rel ? 'rel' : 'hreflang';
    return `${attr}="${tag[attr]}"`;
  }
}

export type LinkDefinition = {
  charset?: string;
  crossorigin?: string;
  href?: string;
  hreflang?: string;
  media?: string;
  rel?: string;
  rev?: string;
  sizes?: string;
  target?: string;
  type?: string;
} & {
  [prop: string]: string;
};
