import Dexie, { Collection, PromiseExtended } from 'dexie';

export interface ITableSchema {
  user: string;
  image: string;
}
/**
 * This class is used to create a database for the thumbnails
 *
 * Example: https://dev.to/andyhaskell/using-dexie-js-to-write-slick-indexeddb-code-304o
 */
class ThumbnailDB extends Dexie {

  thumbnails: Dexie.Table<ITableSchema, string>;

  constructor() {
    super('rs-thumbnails');

    this.version(1).stores(
      {
        thumbnails: 'user',
      });

    this.thumbnails = this.table('thumbnails');
  }
  addThumbnail(id, image) {

    return this.thumbnails.add({ user: id, image: image });

  }
  getThumbnail(id): PromiseExtended<ITableSchema> {

    if (this.thumbnails) return this.thumbnails.get(id);
    else return undefined;

  }
  clearThumbnails() {

    return this.thumbnails.clear();

  }
}

export const thumbnailDB = new ThumbnailDB();
