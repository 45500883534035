<div id="nav-bar" [class.logged-in]="userIsAuthenticated">

  <!-- Hamburger menu of the mobile top bar (logged out)-->
  <div *ngIf="showMobileVersion && !showAppMenu()" id="ham-menu-wrapper" (click)="onHamburgerClicked()" [ngClass]="">

    <div class="menu">
      <div style="height: 1px; visibility: hidden;"></div>
      <div class="one active-element" [ngClass]="hamburgerClicked ? 'active' : 'null'"></div>
      <div class="two active-element" [ngClass]="hamburgerClicked ? 'active' : 'null'"></div>
      <div class="three active-element" [ngClass]="hamburgerClicked ? 'active' : 'null'"></div>
      <div style="height: 1px; visibility: hidden;"></div>
    </div>

  </div>

  <!-- Sidenav mobile -->
  <div *ngIf="hamburgerClicked" id="sidenav-container" [class.used-on-app-menu]="showAppMenu()">
    <rs-sidenav></rs-sidenav>
  </div>

  <!-- Logo of the mobile menu bar -->
  <div *ngIf="showMobileVersion && !userIsAuthenticated" id="rs-nav-bar-logo-mobile"
    [class.logged-in]="userIsAuthenticated">

    <a i18n-area-label="@@nav-bar-link1" area-label="Home Button" class="rs-nav-bar-logo-mobile-button" mat-icon-button
      [routerLink]="['/']" (click)="goHome()" [disableRipple]="true">

      <mat-icon *ngIf="appService.onBrowserPlattForm" id="nav-bar-mobile-logo-large" svgIcon="rs-logo-home-button"
        inline="true"></mat-icon>
      <mat-icon *ngIf="appService.onBrowserPlattForm" id="nav-bar-mobile-logo-small" svgIcon="rs-logo-purple"
        inline="true"></mat-icon>

    </a>

  </div>

  <!-- Logo of the desktop menu bar -->
  <div *ngIf="!showMobileVersion" style="position: absolute; left: 12px; top: 3px;">

    <a i18n-area-label="@@nav-bar-link2" area-label="Home Button" class="rs-nav-bar-logo-desktop-button" mat-icon-button
      routerLink='/' (click)="goHome()" [disableRipple]="true">

      <mat-icon *ngIf="appService.onBrowserPlattForm" svgIcon="rs-logo-purple" inline="true"></mat-icon>

    </a>

  </div>

  <!-- Menu of the menu bar if no app menu is shown -->
  <div *ngIf="!showAppMenu()" class="rs-nav-bar-message-notification">

    <a i18n-area-label="@@nav-bar-link3" area-label="Suche"
      *ngIf="authService.getIsAuthenticated() && !showMobileVersion" style="margin: 10px 70px 0 0;"
      class="nav-bar-button" [routerLink]="['/' + paths.Users]" i18n="@@nav-bar-3">
      <h5>Suche</h5>
    </a>

    <a i18n-area-label="@@nav-bar-link4" area-label="Blog" *ngIf="!showMobileVersion" style="margin: 10px 70px 0 0;"
      class="nav-bar-button" [routerLink]="['/' + paths.BlogLandingPage]" i18n="@@nav-bar-4">
      <h5>Blog</h5>
    </a>

    <a i18n-area-label="@@nav-bar-link5" area-label="Profil"
      *ngIf="authService.getIsAuthenticated() && !showMobileVersion" style="margin: 10px 70px 0 0;"
      class="nav-bar-button" [routerLink]="['/' + paths.MyProfile]" i18n="@@nav-bar-2">
      <h5>Profil</h5>
    </a>

    <a i18n-area-label="@@nav-bar-link6" area-label="FAQ" *ngIf="!showMobileVersion" [routerLink]="['/' + paths.FAQ]"
      style="margin: 10px 70px 0 0;" class="nav-bar-button" i18n="@@nav-bar-5">
      <h5>FAQ</h5>
    </a>

    <!-- Logout button shown when logged in on desktop-->
    @if (authService.getIsAuthenticated() && !showMobileVersion) {
    <a i18n-area-label="@@nav-bar-link7" area-label="Abmelden" style="margin: 10px 70px 0 0;" class="nav-bar-button"
      (click)="onLogout()" i18n="@@nav-bar-6">
      <h5>Abmelden</h5>
    </a>
    }

    <!-- Mail icon hown when logged in on desktop and tablet -->
    @if (authService.getIsAuthenticated()) {
    <a i18n-area-label="@@nav-bar-link8" area-label="Chat" mat-icon-button class="rs-nav-bar-messages-desktop-button"
      [routerLink]="['/chat']" disableRipple="true">

      <mat-icon *ngIf="numberOfUnreadMessages == 0 && appService.onBrowserPlattForm" svgIcon="rs-icon-mail"
        inline="true"></mat-icon>
      <mat-icon *ngIf="numberOfUnreadMessages != 0 && appService.onBrowserPlattForm" svgIcon="rs-icon-mail"
        matBadge="{{numberOfUnreadMessages}}" inline="true"></mat-icon>

    </a>
    }


    <button i18n-area-label="@@nav-bar-Sprachbutton" area-label="Sprache" mat-icon-button
      [matMenuTriggerFor]="languageMenu" [disableRipple]="true" class="rs-nav-bar-language-desktop-button"><mat-icon
        style="transform: scale(1.2);">language</mat-icon> </button>

    <mat-menu #languageMenu="matMenu" id="rs-nav-bar-language-menu">

      @for (language of languageMetaInfo; track language.name){

      <a mat-menu-item (click)="languageService.navigate(language.locale)">
        <div class="language-menu-label-container">
          <span class="language-menu-label">{{language.name}}</span>
          <span>
            <mat-icon class="language-menu-check-mark"></mat-icon>
          </span>
        </div>
      </a>

      }

    </mat-menu>

    <a mat-icon-button [routerLink]="['/' + paths.Settings]" disableRipple="true">

      <mat-icon svgIcon="rs-icon-settings" style="height: 30px; width: 15px; position: relative; top: -4.5px;"
        inline="true"></mat-icon>

    </a>

  </div>

  <!-- App Menu -->
  @if (showAppMenu()) {
  <div id="rs-app-menu">

    <a i18n-area-label="@@nav-bar-link23" area-label="Home Button" class="menu-item-container" [routerLink]="['/']"
      (click)="goHome()" [class.active]="appService.currentURLPath() == paths.Home">

      <div id="app-menu-home-link-wrapper" [class.active]="appService.currentURLPath() == paths.Home">

        <mat-icon id="app-menu-home-icon" *ngIf="appService.onBrowserPlattForm" svgIcon="rs-logo-purple"
          inline="true"></mat-icon>

        <p class="app-menu-btn-label">HOME</p>

      </div>

      <div class="app-menu-ripple" [class.active]="appService.currentURLPath() == paths.Home"></div>

    </a>

    <a i18n-area-label="@@nav-bar-link24" area-label="Suche" class="menu-item-container"
      [routerLink]="['/' + paths.Users]" [class.active]="appService.currentURLPath() == paths.Users">

      <div id="app-menu-search-link-wrapper" [class.active]="appService.currentURLPath() == paths.Users">

        <div class="app-menu-icon-container">

          <mat-icon id="app-menu-search-icon" *ngIf="appService.onBrowserPlattForm" svgIcon="rs-search-person"
            inline="true"></mat-icon>

        </div>

        <p class="app-menu-btn-label" [class.active]="appService.currentURLPath() == paths.Users"
          i18n="@@app-menu-search-button-label">SUCHE</p>

      </div>

      <div class="app-menu-ripple" style="margin-left: -3px;"></div>


    </a>

    <a i18n-area-label="@@nav-bar-link25" area-label="Chat" class="menu-item-container"
      [routerLink]="['/' + paths.Chat]" [class.active]="appService.currentURLPath() == paths.Chat">

      <div id="app-menu-chat-link-wrapper" [class.active]="appService.currentURLPath() == paths.Chat">

        <div class="app-menu-icon-container">

          @if(appService.onBrowserPlattForm && numberOfUnreadMessages == 0) {
          <mat-icon id="app-menu-mail-icon" svgIcon="rs-icon-mail" inline="true"></mat-icon>
          }

          @if(appService.onBrowserPlattForm && numberOfUnreadMessages != 0) {
          <mat-icon id="app-menu-mail-icon" svgIcon="rs-icon-mail" inline="true" matBadge="{{numberOfUnreadMessages}}"
            matBadgeSize="small"></mat-icon>
          }

        </div>

        <p class="app-menu-btn-label" i18n="@@app-menu-chat-button-label">CHAT</p>

      </div>

      <div class="app-menu-ripple" [class.active]="appService.currentURLPath() == paths.Chat"></div>

    </a>

    <a i18n-area-label="@@nav-bar-link26" area-label="Mehr" class="menu-item-container"
      [routerLink]="['/' + paths.MyProfile]" [class.active]="appService.currentURLPath() == paths.MyProfile">

      <div id="app-menu-profile-link-wrapper" [class.active]="appService.currentURLPath() == paths.MyProfile">

        @if (hasProfileImage()) {

        <img onContextMenu="return false;" [src]="thumbnail() | safeHtml" id="app-menu-user-image" alt="user image">

        } @else if (hasThumbnail()) {

        <img onContextMenu="return false;" [src]="myProfileDataService.myProfileForm.value['defaultProfilePicture']"
          id="app-menu-user-image" alt="user image" />

        } @else {

        <mat-icon id="app-menu-profile-icon" *ngIf="appService.onBrowserPlattForm" svgIcon="rs-icon-person"
          inline="true"></mat-icon>

        }

        <p class="app-menu-btn-label" i18n="@@app-menu-profile-button-label">PROFIL</p>

      </div>

      <div class="app-menu-ripple"></div>

    </a>

    <div class="menu-item-container" [routerLink]="['/' + paths.More]"
      [class.active]="appService.currentURLPath() == paths.More">

      <div id="app-menu-ham-menu-link-wrapper" [class.active]="appService.currentURLPath() == paths.More">

        <div class="app-menu-icon-container">

          <mat-icon id="app-menu-ham-menu-icon" *ngIf="appService.onBrowserPlattForm" svgIcon="rs-icon-hamburger"
            inline="true">
          </mat-icon>

        </div>

        <p class="app-menu-btn-label" i18n="@@app-menu-ham-menu-button-label">MEHR</p>

      </div>

      <div class="app-menu-ripple" style="margin-left: 3px;"></div>

    </div>

  </div>
  }


</div>
