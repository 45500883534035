import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private apiUrl: string = environment.apiUrl;
  public languagesMetaInfo = {
    'de': {
      name: 'Deutsch',
      flag: '🇩🇪',
      locale: 'de'
    },
    'en': {
      name: 'English',
      flag: '🇬🇧',
      locale: 'en'
    },
    'es': {
      name: 'Español',
      flag: '🇪🇸',
      locale: 'es'
    },
    'fr': {
      name: 'Français',
      flag: '🇫🇷',
      locale: 'fr'
    },
    'ms': {
      name: 'Melayu',
      flag: '🇲🇾',
      locale: 'ms'
    },
    'pt': {
      name: 'Português',
      flag: '🇵🇹',
      locale: 'pt'
    },
    'ru': {
      name: 'Русский',
      flag: '🇷🇺',
      locale: 'ru'
    },
    'hi': {
      name: 'हिन्दी',
      flag: '🇮🇳',
      locale: 'hi'
    },
    'zh': {
      name: '简体中文',
      flag: '🇨🇳',
      locale: 'zh'
    },
    'it': {
      name: 'Italiano',
      flag: '🇮🇹',
      locale: 'it'
    },
    'pl': {
      name: 'Polski',
      flag: '🇵🇱',
      locale: 'pl'
    },
    'nl': {
      name: 'Nederlands',
      flag: '🇳🇱',
      locale: 'nl'
    },
    'tr': {
      name: 'Türkçe',
      flag: '🇹🇷',
      locale: 'tr'
    }
  }
  public supportedLanguages = Object.keys(this.languagesMetaInfo);

  constructor(
    private cookieService: SsrCookieService,
    @Inject(PLATFORM_ID) private platformId: string
  ) {

  }
  /**
   * @param locale - one of the supported locales (equals the key of the languagesMetaInfo object)
   * @param route - the route to navigate to after changing the language, defaults to the home route
   */
  navigate(locale: string, route?: string) {

    let path = this.apiUrl + locale + (route ? '/' + route : '/');

    if (isPlatformBrowser(this.platformId)) {
      if (Capacitor.isNativePlatform()) this.cookieService.set('rs-my-lang', locale, { expires: 365, path: '/', sameSite: 'Strict' });
      window.location.href = path;
    }

  }
}
