import { SplashScreenService } from './splash-screen/splash-screen.service';
import { UserListService } from './user-list/user-list.service';
import { MyProfileDataService } from './profiles/services/my-profile-data.service';
import { WebsocketService } from './websocket/websocket.service';
import { Component, Inject, PLATFORM_ID, Injector, OnInit, OnDestroy, AfterViewInit, ApplicationRef, NgZone, signal } from '@angular/core';
import { AppService, AuthService } from './auth/auth.service';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Subscription, filter, map, mergeMap } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, ActivatedRoute, ActivationEnd, NavigationStart } from '@angular/router';
import { SEOService } from './seo/seo.service';
import { AnalyticsService } from './analytics/analytics.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Paths } from './app-routing.module';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {

  readonly title = 'RattleStork';
  private subscriptions: Subscription = new Subscription();
  private paths = Paths;
  public showNavigation = signal(true);

  constructor(
    private authService: AuthService,
    private websocketService: WebsocketService,
    private swUpdate: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: string,
    private router: Router,
    private _seoService: SEOService,
    private activatedRoute: ActivatedRoute,
    private myProfileDataService: MyProfileDataService,
    private analyticsService: AnalyticsService,
    private userListService: UserListService,
    private zone: NgZone,
    public appService: AppService,
    public splashScreenService: SplashScreenService
  ) {

    this.addDeepLinkRedirect();
    this.removeTermlyForNativeApps();
    this.startSEOHandler();
    this.startAppMenuHandler();
    this.styleAndroidStatusBar();
    this.myProfileDataService.startProfileService();
    this.authService.autoAuthUser();

  }
  ngAfterViewInit() {

    if (isPlatformBrowser(this.platformId)) {

      // this.analyticsService.initCookieBanner();
      this.checkForNewVersion();

    }

  }
  ngOnDestroy(): void {

    if (this.subscriptions) this.subscriptions.unsubscribe();
    this.websocketService.disconnectSocket();
    this.userListService.thumbnails.clearThumbnails();

  }
  private removeTermlyForNativeApps() {

    if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() !== 'web') {

      const termly = document.getElementById('termly-script');
      console.log(termly);
      if (termly) termly.remove();

      const termlyCode = document.getElementById('termly-code-snippet-support');
      if (termlyCode) termlyCode.remove();

    }

  }
  /**
   * Add a listener for deep links and redirect to the appropriate page.
   * TODO: Remove event listener when not opened as an app
   */
  private addDeepLinkRedirect() {

    if (!isPlatformBrowser(this.platformId)) return;

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://beerswift.app/tabs/tab2
        // slug = /tabs/tab2
        const slug = event.url.split(".app").pop();
        if (slug) {
          console.log("Redirecting to: ", slug);
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });

  }
  /**
   * Remove the menu on certain routes like login and registration
   */
  private startAppMenuHandler() {

    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {

      if (isPlatformBrowser(this.platformId) && this.isRouteWithoutMenu(event)) {

        this.showNavigation.set(false);

      } else {

        this.showNavigation.set(true);

      }

    });

  }
  private isRouteWithoutMenu(event: NavigationEnd) {

    let parsedUrl = event.url.replace("/", "");

    return parsedUrl === this.paths.Login || parsedUrl === this.paths.Registration;

  }
  private styleAndroidStatusBar() {

    // Native StatusBar available
    if (isPlatformBrowser(this.platformId) && Capacitor.getPlatform() === 'android' && Capacitor.isPluginAvailable('StatusBar')) {

      // Tint statusbar color
      StatusBar.setBackgroundColor({
        color: "#EAF205"
      });
      StatusBar.setStyle({
        style: Style.Light
      });

    }

  }
  private checkForNewVersion() {

    this.swUpdate.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      map(evt => window.location.reload())
    );
  }
  private startSEOHandler() {

    this.subscriptions.add(this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this._seoService.updateTitle(event['title']);
        this._seoService.updateOgUrl(event['ogUrl']);
        this._seoService.updateDescription(event['description']);
      }));

  }
}
