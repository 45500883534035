import { DomSanitizer } from '@angular/platform-browser';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { isPlatformServer } from '@angular/common';

@NgModule({
})
export class IconRegistryModule {

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, @Inject(PLATFORM_ID) platformId: string) {

    try {

      const domain = (isPlatformServer(platformId)) ? '/RattleStork/browser/' : './../';

      iconRegistry.addSvgIcon('rs-logo-home-button', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 2.svg'));
      iconRegistry.addSvgIcon('rs-logo-purple', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe3.svg'));
      iconRegistry.addSvgIcon('rs-icon-badge', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 4.svg'));
      iconRegistry.addSvgIcon('rs-icon-mail', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 6.svg'));
      iconRegistry.addSvgIcon('rs-icon-hamburger', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 10.svg'));
      iconRegistry.addSvgIcon('rs-icon-person', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 11.svg'));
      iconRegistry.addSvgIcon('rs-icon-dont-show', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 12.svg'));
      iconRegistry.addSvgIcon('rs-icon-delete', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 13.svg'));
      iconRegistry.addSvgIcon('rs-icon-arrow-right', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 57.svg'));
      iconRegistry.addSvgIcon('rs-icon-bar', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 64.svg'));
      iconRegistry.addSvgIcon('rs-icon-check', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 77.svg'));
      iconRegistry.addSvgIcon('rs-icon-bell', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 89.svg'));
      iconRegistry.addSvgIcon('rs-icon-glass', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 91.svg'));
      iconRegistry.addSvgIcon('rs-icon-fat-arrow', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 20.svg'));
      iconRegistry.addSvgIcon('rs-icon-star', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 21.svg'));
      iconRegistry.addSvgIcon('rs-icon-location', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 35.svg'));
      iconRegistry.addSvgIcon('rs-icon-birthday', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 110.svg'));
      iconRegistry.addSvgIcon('rs-icon-smeyley', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 36.svg'));
      iconRegistry.addSvgIcon('rs-icon-speech-bubble', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 39.svg'));
      iconRegistry.addSvgIcon('rs-icon-lock', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 40.svg'));
      iconRegistry.addSvgIcon('rs-icon-sent', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 45.svg'));
      iconRegistry.addSvgIcon('rs-icon-plus', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 46.svg'));
      iconRegistry.addSvgIcon('rs-icon-x', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 47.svg'));
      iconRegistry.addSvgIcon('rs-icon-right', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 48.svg'));
      iconRegistry.addSvgIcon('rs-icon-left', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 49.svg'));
      iconRegistry.addSvgIcon('rs-icon-up', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 50.svg'));
      iconRegistry.addSvgIcon('rs-icon-down', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Pfad 51.svg'));
      iconRegistry.addSvgIcon('rs-carousel-arrow-left', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/carousel-arrow-left.svg'));
      iconRegistry.addSvgIcon('rs-carousel-arrow-right', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/caroussel-arrow-right.svg'));
      iconRegistry.addSvgIcon('rs-addPhoto', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/addAPhoto.svg'));
      iconRegistry.addSvgIcon('rs-deletePhoto', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/delete.svg'));
      iconRegistry.addSvgIcon('rs-chabo', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/images/chabo_thumbnail.png'));
      iconRegistry.addSvgIcon('rs-chaya', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/images/chaya_thumbnail.png'));
      iconRegistry.addSvgIcon('rs-placeholder-male', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-male-1', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male-1.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-male-2', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male-2.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-male-3', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male-3.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-male-4', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male-4.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-male-5', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-male-5.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female-1', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female-1.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female-2', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female-2.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female-3', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female-3.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female-4', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female-4.svg'));
      iconRegistry.addSvgIcon('rs-placeholder-female-5', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/placeholder-female-5.svg'));
      iconRegistry.addSvgIcon('rs-search-person', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/search_person.svg'));
      iconRegistry.addSvgIcon('rs-block-user-icon', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/Gruppe 315.svg'));


      iconRegistry.addSvgIcon('rs-icon-impressum', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-impressum.svg'));
      iconRegistry.addSvgIcon('rs-icon-datenschutz', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-datenschutz.svg'));
      iconRegistry.addSvgIcon('rs-icon-kontakt', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-kontakt.svg'));
      iconRegistry.addSvgIcon('rs-icon-faq', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-faq.svg'));
      iconRegistry.addSvgIcon('rs-icon-settings', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-settings.svg'));
      iconRegistry.addSvgIcon('rs-icon-blog', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/icons/icon-blog.svg'));




      // OLD

      iconRegistry.addSvgIcon('rs-login', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/login.svg'));
      iconRegistry.addSvgIcon('rs-bday', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/bday.svg'));
      iconRegistry.addSvgIcon('rs-key', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/key.svg'));
      iconRegistry.addSvgIcon('rs-mail', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/mail.svg'));
      iconRegistry.addSvgIcon('rs-person-outline', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/person-outline.svg'));
      iconRegistry.addSvgIcon('rs-setPhotoToPrivate', sanitizer.bypassSecurityTrustResourceUrl('assets/old/setPrivate.svg'));
      iconRegistry.addSvgIcon('rs-chat', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/chat.svg'));
      iconRegistry.addSvgIcon('rs-age', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/birthday-cake.svg'));
      iconRegistry.addSvgIcon('rs-location', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/location.svg'));
      iconRegistry.addSvgIcon('rs-last-active', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/open-account-login.svg'));
      iconRegistry.addSvgIcon('rs-profile', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/person-outline-2.svg'));
      iconRegistry.addSvgIcon('move', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/move.svg'));
      iconRegistry.addSvgIcon('arrows-alt-h', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/arrows-alt-h.svg'));
      iconRegistry.addSvgIcon('arrows-alt-v', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/arrows-alt-v.svg'));
      iconRegistry.addSvgIcon('check', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/check.svg'));
      iconRegistry.addSvgIcon('cross-circle', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/cross-circle.svg'));
      iconRegistry.addSvgIcon('rotate-left', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/rotate-left.svg'));
      iconRegistry.addSvgIcon('rotate-right', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/rotate-right (1).svg'));
      iconRegistry.addSvgIcon('zoom-in', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/zoom-in.svg'));
      iconRegistry.addSvgIcon('zoom-out', sanitizer.bypassSecurityTrustResourceUrl(domain + 'assets/old/cropper-toolbar/zoom-out.svg'));

    } catch (error) {

      console.log(error);

    }


  }

}
