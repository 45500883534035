<div mat-dialog-content #cropperDialog id="cropper-dialog-container" [style.width]="croperDialogContainerWidth" [style.max-width]="croperDialogContainerWidth" style="aspect-ratio: 3 / 2;">

  <canvas id="rs-my-profile-picture-cropper-canvas" [width]="imageWidth" [height]="imageHeight">
    Your browser does not support the HTML5 canvas tag.
  </canvas>

  <div id="cropping-toolbar">

    <button mat-icon-button class="toolbar-icon" style="margin-left: 15px;" (click)="cropper.zoom(0.1)">
      <mat-icon svgIcon="zoom-in">zoom-in</mat-icon>
    </button>
    <button mat-icon-button class="toolbar-icon" (click)="cropper.zoom(-0.1)">
      <mat-icon svgIcon="zoom-out">zoom-out</mat-icon>
    </button>
    <button mat-icon-button class="toolbar-icon" (click)="cropper.rotate(-90)">
      <mat-icon svgIcon="rotate-left">rotate-left</mat-icon>
    </button>
    <button mat-icon-button class="toolbar-icon" (click)="cropper.rotate(90)">
      <mat-icon svgIcon="rotate-right">rotate-right</mat-icon>
    </button>
    <button mat-icon-button class="toolbar-icon" (click)="scaleHorizontal()">
      <mat-icon svgIcon="arrows-alt-h">arrows-alt-h</mat-icon>
    </button>
    <button mat-icon-button class="toolbar-icon" (click)="scaleVertical()">
      <mat-icon svgIcon="arrows-alt-v">arrows-alt-v</mat-icon>
    </button>
    <button mat-icon-button id="check-button" class="toolbar-icon" (click)="applyCropping()">
      <mat-icon svgIcon="check">check</mat-icon>
    </button>
    <button mat-icon-button (click)="closeDialog()" id="cancel-button" class="toolbar-icon" style="margin-right: 15px;">
      <mat-icon svgIcon="cross-circle">cross-circle</mat-icon>
    </button>

  </div>

</div>
