import { IosStatusBarService, ThemeColors } from './../ios-status-bar/ios-status-bar.service';
import { Component, OnInit, HostListener, ElementRef, AfterViewInit, isDevMode, Input, OnDestroy } from '@angular/core';
import { AppService } from '../app.service';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute, Params, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Paths } from '../app-routing.module';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  standalone: true,
  imports: [
    RouterModule,
    CommonModule,
    MatIconModule,
    MatDividerModule
  ],
  selector: 'rs-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input("fill") fill: boolean = false;
  apiUrl: string = environment.apiUrl;
  paths = Paths;

  constructor(
    public authService: AuthService,
    public appService: AppService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private iosStatusBarService: IosStatusBarService
  ) {

    this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color3);

  }
  ngOnInit() {

  }
  ngOnDestroy(): void {
    this.iosStatusBarService.changeNativeStatusBarColor(ThemeColors.color2);
  }
  onLogout() {
    this.authService.logout();
  }
  onClick() {
    this.appService.showHamburgerMenu = false;
  }
  /**
   * Keeps the activeChatID query param if set. This is needed
   * when routing from the chat, with set active chat id, to
   * the chat (same site routing using the sidebar).
   */
  onRouteToChat() {

    let queryParams: Params;
    let activeChatId = this.activatedRoute.snapshot.queryParams.activeChatId;

    if (activeChatId) queryParams = { activeChatId: activeChatId };

    this.router.navigate(['/chat'], { queryParams: queryParams });

  }
  useLanguage(language: string) {

    if (!isDevMode()) {
      if (language === 'en') {
        location.href = environment.apiUrl + language;
      } else if (language === 'de') {
        location.href = environment.apiUrl + language;
      }
    }

  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    // TODO: Close sidenav if click outside the sidenav when opened

    // if (this.appService.showHamburgerMenu = true) {

    //   if (this.eRef.nativeElement.contains(event.target)) {
    //     this.appService.showHamburgerMenu = true;
    //   } else {

    //     if (this.appService.showHamburgerMenu) {
    //       this.appService.showHamburgerMenu = false;
    //     }

    //   }

    // }

  }
}
