import { inject, InjectionToken, NgModule, } from '@angular/core';
import { Routes, RouterModule, BaseRouteReuseStrategy, ActivatedRouteSnapshot, RouteReuseStrategy, Route, Router, PreloadAllModules, NoPreloading } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { InitProfileGuard } from './profiles/init-profile/init-profile.guard';
import { AppLanguageSelectGuard } from './app-language-select/app-language-select.guard';

/**
 * !!!!!!!!! IMPORTANT !!!!!!!!!
 *
 * Wenn die rechte Seite des Ausdrucks geändert wird, muss auch die
 * sitemap.xml angepasst werden.
 */
export enum Paths {
  Home = '',
  Login = 'login',
  FAQ = 'faq',
  Registration = 'registration',
  Users = 'users',
  MyProfile = 'myProfile',
  InitProfile = 'initProfile',
  Profile = 'profile',
  Chat = 'chat',
  Contact = 'contact',
  DataProtection = 'datenschutz',
  Impressum = 'impressum',
  Unsubscribe = 'unsubscribe',
  Settings = 'settings',
  ResetPassword = 'reset-password',
  ForgotPassword = 'forgot-password',
  GoPremium = 'go-premium',
  BlogLandingPage = 'blog',
  More = 'more',
  SelectLanguage = 'select-language',
  ReportUser = 'report-user',

  TermsOfUse ='TermsOfUse',
  AcceptableUsePolicy ='AcceptableUsePolicy',
  CookiePolicy = 'CookiePolicy',
  EULA = 'EULA',
  ReturnPolicy = 'ReturnPolicy',

  BlogCostSpermBank = 'parasite-sperm-bank',
  BlogCostSpermBankCopy = 'parasite-kiwu',
  BlogRefertilisierungFrau = 'refertilisierung-frau',
  BlogRefertilisierungMann = 'refertilisierung-mann',
  BlogBuerokratie = 'buerokratie',
  BlogZehnUnfruchtFrauen = 'ZehnUnfruchtFrauen',
  BlogPilleAbsetzen = 'PilleAbsetzen',
  BlogKostenAI = 'kostenAI',
  BlogFindeSpender = 'FindeSpender',
  BlogWerdeSpender = 'WerdeSpender',
  BlogFehlgeburt = 'fehlgeburt',
  BlogFrühgeburt = 'frühgeburt',
  BlogBrust = 'brust',
  BlogMenstruationDespitePregnancy = 'menstruation-despite-pregnancy',
  BlogPid = 'pid',
  BlogMutterpass = 'mutterpass',
  BlogChlamydia = 'chlamydia',
  BlogEmilia = 'emilia',
  BlogZervix = 'Zervix',
  BlogMenopause = 'Menopause',
  Blog10TipsAndTricks = '10-tips-and-tricks',
  BlogSurrogacy = 'surrogacy',
  BlogPCO = 'pco',
  BlogModAbst = 'modernisierung-abstammungsrecht',
  BlogDiseases = 'diseases',
  BlogUeberlebenSpermien = 'UeberlebenSpermien',
  BlogVaccination = 'vaccination',
  BlogSpermiogram = 'spermiogramm',
  BlogIUI = 'iui',
  BlogIcsi = 'icsi',
  BlogArtificialInsemnation = 'artificial-insemnation',
  BlogIVF = 'ivf',
  BlogICI = 'ici',
  BlogOvulation = 'ovulation',
  BlogEinnistung = 'einnistung',
  BlogGelbkörperschwäche = 'gelbkörperschwäche',
  BlogLhOvu = 'lh',
  BlogLineaNigra = 'linea-nigra',
  BlogKlapper = 'klapperstorch',
  BlogBechermethode = 'bechermethode',
  BlogHomeInsemination = 'home-insemination',
  BlogSpermDonation = 'private-samenspende',
  BlogSpermCramps = 'spermakrämpfe',
  BlogSemenCongestion = 'samenstau',
  BlogPreEjaculate = 'lusttropfen',
  BlogTurkeyBasterPregnancy = 'turkey-baster',
  BlogCoParenting = 'co-parenting',
  BlogEizellenspende = 'eizellenspende',
  BlogÖsterreich = 'österreich',
  BlogFrankreich = 'frankreich',
  BlogSpanien = 'spanien',
  BlogMexico = 'mexico',
  BlogItalien = 'italien',
  BlogPolen = 'polen',
  BlogNiederlande = 'niederlande',
  BlogPortugal = 'portugal',
  BlogBrasilien = 'brasilien',
  BlogRussland = 'russland',
  BlogTürkei = 'türkei',
  BlogIndien = 'indien',
  BlogUK = 'uk',
  BlogIndonesien = 'indonesien',
  BlogMalaysia = 'malaysia',
  BlogChina = 'china',
  BlogRisikofaktoren = 'ZehnUnfruchtMann',


}
// https://www.auroria.io/angular-route-reuse-strategy/
export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (future.routeConfig === curr.routeConfig) || future.data.reuseComponent;
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot(AppRoutingModule.generalRoutes, {
      onSameUrlNavigation: 'reload',
      initialNavigation: 'enabledBlocking',
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: NoPreloading,
      enableViewTransitions: true,
    })
  ],
  exports: [RouterModule],
  providers: [
    // AuthGuard,
    AppLanguageSelectGuard,
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }
  ]
})
export class AppRoutingModule {

  static readonly generalRoutes: Routes = [
    {
      path: Paths.SelectLanguage,
      data: {
        title: $localize`:@@titleSelectLanguageRoute:Sprache wählen`,
        description:
          $localize`:@@descriptionSelectLanguageRoute:Sprache wählen`,
        ogUrl: 'https://rattlestork.org/',
      },
      loadComponent: () => import('./app-language-select/app-language-select.component').then(m => m.AppLanguageSelectComponent),
    },
    {
      path: Paths.More,
      data: {
        title: $localize`:@@titleResetPasswordRoute:RattleStork | RattleStork`,
        description:
          $localize`:@@descriptionResetPasswordRoute:RattleStork | RattleStork`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./sidenav/app-sidenav/app-sidenav.component').then(m => m.AppSidenavComponent),
    },
    {
      path: Paths.ResetPassword,
      data: {
        title: $localize`:@@titleResetPasswordRoute:Passwort zurücksetzen`,
        description:
          $localize`:@@descriptionResetPasswordRoute:Passwort zurücksetzen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./auth/reset-pwd/reset-pwd.component').then(m => m.ResetPwdComponent),
    },
    {
      path: Paths.ForgotPassword,
      data: {
        title: $localize`:@@titleForgotPasswordRoute:Passwort vergessen`,
        description:
          $localize`:@@descriptionForgotPasswordRoute:Passwort vergessen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./auth/forgot-pwd/forgot-pwd.component').then(m => m.ForgotPwdComponent),
    },
    {
      path: Paths.Settings,
      data: {
        title: $localize`:@@routingPathTitleSettings: Einstellungen`,
        description:
          $localize`:@@routingPathDescriptionSettings: Einstellungen`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./settings/settings.component').then(m => m.SettingsComponent),
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.BlogLandingPage,
      data: {
        title: $localize`:@@titleBlogLandingPageRoute:Blog`,
        description:
          $localize`:@@descriptionBlogLandingPageRoute:Blog`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blog-landing-page/blog-landing-page.component').then(m => m.BlogLandingPageComponent),
    },
    {
      path: Paths.Login,
      data: {
        title: $localize`:@@titleLoginPageRoute:Login`,
        description:
          $localize`:@@descriptionLoginPageRoute:Login`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./auth/login/login.component').then(m => m.LoginComponent),
    },
    {
      path: Paths.Registration,
      data: {
        title: $localize`:@@titleRegistrationPageRoute:Registrierung`,
        description:
          $localize`:@@descriptionRegistrationPageRoute:Registriere dich jetzt auf RattleStork.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./auth/registration/registration.component').then(m => m.RegistrationComponent),
    },
    {
      path: Paths.Users,
      data: {
        title: $localize`:@@titleUserSearchPageRoute:Suche`,
        description:
          $localize`:@@descriptionUserSearchPageRoute:Suche`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./user-list/user-list.component').then(m => m.UserListComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.MyProfile,
      data: {
        title: $localize`:@@titleMyProfilePageRoute:Mein Profil`,
        description:
          $localize`:@@descriptionMyProfilePageRoute:Mein Profil`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./profiles/my-profile/myProfile.component').then(m => m.MyProfileComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always",
    },
    {
      path: Paths.InitProfile,
      data: {
        title: $localize`:@@titleMyProfilePageRoute:Profil einrichten`,
        description:
          $localize`:@@descriptionMyProfilePageRoute:Profil einrichten`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./profiles/init-profile/init-profile.component').then(m => m.InitProfileComponent),
      canActivate: [AuthGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.Profile,
      data: {
        title: $localize`:@@titleProfilePageRoute:Profil`,
        description:
          $localize`:@@descriptionProfilePageRoute:Profil`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./profiles/foreign-profile/profile.component').then(m => m.ProfileComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.Chat,
      data: {
        title: $localize`:@@titleChatPageRoute:Chat`,
        description:
          $localize`:@@descriptionChatPageRoute:Chat`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./chat/chat-root/chat.component').then(m => m.ChatComponent),
      canActivate: [AuthGuard, InitProfileGuard],
      runGuardsAndResolvers: "always"
    },
    {
      path: Paths.ReportUser,
      loadComponent: () => import('./report-user/report-user.component').then(m => m.ReportUserComponent)
    },
    {
      path: Paths.Contact,
      loadChildren: () => import('./footer/contact-service/contact-service-de.module').then(m => m.ContactServiceModule)
    },
    {
      path: Paths.Unsubscribe,
      loadComponent: () => import('./unsubscribe/unsubscribe.component').then(m => m.UnsubscribeComponent)
    },
    {
      path: Paths.Home,
      data: {
        title: $localize`:@@titleHomeRoute:Samenspende, Bechermethode, Heiminsemination, Co-Parenting`,
        description:
          $localize`:@@descriptionHomeRoute:Erfüllen Sie Ihren Kinderwunsch mit Unterstützung von Deutschlands größter Plattform für private Samenspende – täglich helfen wir Hunderten Menschen.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./home/home.component').then(m => m.HomeComponent),
      canActivate: [AppLanguageSelectGuard],
      runGuardsAndResolvers: "always",
    },
    {
      path: Paths.GoPremium,
      data: {
        title: $localize`:@@titleGoPremiumRoute:Premium`,
        description:
          $localize`:@@descriptionGoPremiumRoute:Premium.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./go-premium/go-premium.component').then(m => m.GoPremiumComponent)
    },
    {
      path: Paths.DataProtection,
      data: {
        title: $localize`:@@titleDataProtectionDePage:Datenschutz`,
        description:
          $localize`:@@descriptionDataProtectionDePage:Hier findest du Informationen zum Thema Datenschutz auf RattleStork.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./footer/data-protection/en/data-protection-en.component').then(m => m.DataProtectionEnComponent)
    },
    {
      path: Paths.TermsOfUse,
      data: {
        title: $localize`:@@titleTermsOfUse:Terms Of Use`,
        description:
          $localize`:@@descriptionTermsOfUseEnPage:Hier findest du die Terms Of Use von RattleStork.`,
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./footer/terms-of-use/en/terms-of-use-en.component').then(m => m.TermsOfUseEnComponent)
    },
  {
    path: Paths.AcceptableUsePolicy,
    data: {
      title: $localize`:@@titleAcceptableUsePolicyEnPage:Nutzungsbedingungen`,
      description:
        $localize`:@@descriptionAcceptableUsePolicyEnPage:Hier findest du die Nutzungsbedingungen von RattleStork.`,
      ogUrl: 'https://rattlestork.org/'
    },
    loadComponent: () => import('./footer/acceptable-use-policy/en/acceptable-use-policy-en.component').then(m => m.AcceptableUsePolicyEnComponent)
  },
{
  path: Paths.CookiePolicy,
  data: {
    title: $localize`:@@titleCookiePolicyEnPage:Cookie Policy`,
    description:
      $localize`:@@descriptionCookiePolicyEnPage:Hier findest du die Cookie Policy von RattleStork.`,
    ogUrl: 'https://rattlestork.org/'
  },
  loadComponent: () => import('./footer/cookie-policy/en/cookie-policy-en.component').then(m => m.CookiePolicyEnComponent)
},
{
  path: Paths.EULA,
  data: {
    title: $localize`:@@titleEULAEnPage:EULA`,
    description:
      $localize`:@@descriptionEULAEnPage:Hier findest du die EULA von RattleStork.`,
    ogUrl: 'https://rattlestork.org/'
  },
  loadComponent: () => import('./footer/EULA/en/EULA-en.component').then(m => m.EULAEnComponent)
},
{
  path: Paths.ReturnPolicy,
  data: {
    title: $localize`:@@titleReturnPolicyEnPage:Rückgaberecht`,
    description:
      $localize`:@@descriptionReturnPolicyEnPage:Rückgaberecht von RattleStork.`,
    ogUrl: 'https://rattlestork.org/'
  },
  loadComponent: () => import('./footer/return-policy/en/return-policy-en.component').then(m => m.ReturnPolicyEnComponent)
},
];
  static readonly germanRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Frequently Asked Questions (FAQ)",
        description: "Frequently Asked Questions (FAQ)",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/de/de-faq.component').then(m => m.DeFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/de/impressum.module').then(m => m.ImpressumModule)
    },
    {
      path: Paths.BlogBechermethode,
      data: {
        title: "Bechermethode: schwanger werden ohne Sex",
        description: "Die Bechermethode ist eine Form der Heiminsemination, bei der das Sperma durch Masturbation gewonnen und in einem speziellen Becher aufgefangen wird.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/bechermethode/bechermethode.component').then(m => m.BechermethodeDeComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Alles, was Sie über moderne Familienmodelle wissen müssen",
        description: "Alles über Co-Parenting: Grundlagen, Methoden, rechtliche Herausforderungen und Vorteile dieser modernen Familienform.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/co-parenting/co-parenting.component').then(m => m.CoParentingDeComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Eisprung verstehen: Der Schlüssel zur Erhöhung Ihrer Fruchtbarkeit",
        description: "Verstehen Sie den Eisprung und steigern Sie Ihre Fruchtbarkeit. Unser Leitfaden erklärt die Ovulation, Zyklusphasen und Methoden zur Ovulationsüberwachung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/ei/ei.component').then(m => m.EiDeComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Eizellenspende in Deutschland: Alles zu Chancen, Risiken und Gesetzen",
        description: "Alles zur Eizellenspende in Deutschland: Chancen, Risiken, rechtliche Rahmenbedingungen und internationale Vergleiche. Erfahren Sie, was Sie wissen müssen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeDeComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Einnistung: Definition, Zeitpunkt und essentielle Informationen zur frühen Schwangerschaft",
        description: "Wann und wie erfolgt die Einnistung? Erfahren Sie, wie lange der Prozess dauert, welche Symptome auftreten können und welche Faktoren den Erfolg beeinflussen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/einnistung/einnistung.component').then(m => m.EinnistungDeComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Heiminsemination: Künstliche Befruchtung zu Hause",
        description: "Erfahren Sie, wie die Heiminsemination funktioniert, welche Utensilien nötig sind und welche Vorteile diese private Methode der künstlichen Befruchtung bietet.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/heiminsemination/heiminsemination.component').then(m => m.HeiminseminationComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Ungeimpfte Sperma vs. Geimpfte Sperma: Fakten zur Spermienqualität nach der COVID-19-Impfung",
        description: "Wie beeinflusst die COVID-19-Impfung die Spermienqualität? Unser Artikel liefert wissenschaftliche Erkenntnisse und klärt über Mythen auf.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/impf/impf.component').then(m => m.ImpfDeComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intrazervikale Insemination (ICI): Die schonende Methode zur Familienplanung",
        description: "Erfahren Sie mehr über die intrazervikale Insemination (ICI) – eine flexible und kostengünstige Methode zur Familienplanung mit vielen Vorteilen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/ici/ici.component').then(m => m.IciDeComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Alles über Intrauterine Insemination (IUI): Ablauf, Kosten und Erfolgsaussichten",
        description: "Informieren Sie sich über die intrauterine Insemination (IUI): Ablauf, Kosten, Voraussetzungen und Erfolgsaussichten dieser verbreiteten Methode.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/iui/iui.component').then(m => m.IuiDeComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Künstliche Befruchtung: Methoden, Erfolgschancen und Kosten im Überblick",
        description: "Alles zur künstlichen Befruchtung: IUI, IVF, ICSI, Kosten, Erfolgschancen und Finanzierungsmöglichkeiten. Ein umfassender Leitfaden für Ihren Kinderwunsch.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/kunstl/kunstl.component').then(m => m.KunstlDeComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Die Revolution der Reproduktionsmedizin zur Behandlung männlicher Unfruchtbarkeit",
        description: "Wie die Intrazytoplasmatische Spermieninjektion (ICSI) die Reproduktionsmedizin verändert: Chancen für Paare mit Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/icsi/icsi.component').then(m => m.IcsiDeComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-Vitro-Befruchtung (IVF): Umfassender Leitfaden: Kosten, Schritte und mehr",
        description: "Alles über die In-Vitro-Befruchtung (IVF): Kosten, detaillierter Ablauf und neueste Fortschritte. Ein umfassender Leitfaden zur assistierten Reproduktion.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/ivf/ivf.component').then(m => m.IvfDeComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Übertragbare Krankheiten bei der Samenspende: Risiken und Schutzmaßnahmen",
        description: "Welche Krankheiten können durch Samenspende übertragen werden? Erfahren Sie alles über Risiken, Schutzmaßnahmen und genetische Tests.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/krankheiten/krankheiten.component').then(m => m.KrankheitenDeComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Leihmutterschaft: Rechtliche Fragen, Kosten, traditionelle und gestionale Modelle",
        description: "Alles zur Leihmutterschaft: Kosten, rechtliche Aspekte und Unterschiede zwischen traditionellen und gestationalen Modellen. Finanzierungsoptionen inklusive.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/leihmut/leihmut.component').then(m => m.LeihmutDeComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Lusttropfen und Schwangerschaft: Kann ich vom Lusttropfen schwanger werden? Wie hoch ist das Risiko wirklich?",
        description: "Kann der Lusttropfen zu einer Schwangerschaft führen? Unser Artikel erklärt, ob er Spermien enthält und wie hoch das Risiko einer Schwangerschaft ist.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/lusttropfen/lusttropfen.component').then(m => m.LusttropfenDeComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Schwangerschaftsblutungen: Ursachen und Unterschiede zur Menstruation",
        description: "Blutungen in der Schwangerschaft: Was sind die Ursachen, Unterschiede zur Menstruation und wann sollten Sie einen Arzt aufsuchen? Wichtige Infos hier.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/mensschwang/mensschwang.component').then(m => m.MensschwangDeComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polyzystisches Ovarialsyndrom (PCOS): Ursachen, Symptome und Behandlung im Überblick",
        description: "Erfahren Sie alles über das Polyzystische Ovarialsyndrom (PCOS): Ursachen, Symptome, Diagnose und Behandlungsmöglichkeiten für dieses häufige Syndrom.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/pco/pco.component').then(m => m.PCODeComponent)
    },
    {
      path: Paths.BlogSpermDonation,
      data: {
        title: "Private Samenspende: Eine kostengünstige Alternative zur Samenbank in Deutschland",
        description: "Private Samenspende als kostengünstige Alternative zu Samenbanken. Vergleichen Sie Kosten, Vorteile und erfahren Sie mehr über diese Option zur Familienplanung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/private-samenspende/private-samenspende.component').then(m => m.PrivateSamenspendeDeComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Spermakrämpfe: Ursachen, Symptome und Behandlung",
        description: "Spermakrämpfe erklärt: Ursachen, Symptome und effektive Behandlungsmöglichkeiten. Erfahren Sie, wie Sie diese Beschwerden behandeln und vorbeugen können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfDeComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Wie du schneller schwanger wirst: 10 erprobte Strategien für eine erfolgreiche Schwangerschaft",
        description: "10 Tipps für eine schnellere Schwangerschaft: Berechnung fruchtbarer Tage, Ernährung und mehr. Erhöhen Sie Ihre Chancen auf eine erfolgreiche Schwangerschaft.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangDeComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Samenstau, Kavaliersschmerz, Blaue Hoden",
        description: "Alles über Samenstau und Kavaliersschmerzen: Ursachen, Symptome, Behandlung und Vorbeugung dieser unangenehmen Beschwerden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/samenstau/samenstau.component').then(m => m.SamenstauDeComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramm: Alles Wichtige zur Analyse der männlichen Fruchtbarkeit",
        description: "Alles über das Spermiogramm: Durchführung, Kosten und Interpretation der Ergebnisse. Ein Leitfaden zur Bewertung der männlichen Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/spermiogramm/spermiogramm.component').then(m => m.SpermiogramDeComponent)
    },
    {
      path: Paths.BlogModAbst,
      data: {
        title: "Reform des Abstammungsrechts 2024: Ein großer Schritt für Regenbogenfamilien",
        description: "Die Reform des Abstammungsrechts 2024: Neue Regelungen und wie sie Regenbogenfamilien, insbesondere lesbischen Paaren, zugutekommen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/modernisierung-abstammungsrecht/modernisierung-abstammungsrecht.component').then(m => m.ModernisierungAbstammungsrechtComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Die Legende vom Klapperstorch: Warum bringt der Storch die Kinder?",
        description: "Erfahren Sie die Geschichte hinter der Legende vom Klapperstorch. Warum wurde der Storch zum Symbol für die Geburt von Kindern?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/klapper/klapper.component').then(m => m.KlapperComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH-Anstieg und Ovulation: Ihr Guide zum Verständnis und zur Überwachung der Fruchtbarkeit",
        description: "Was passiert während des LH-Anstiegs? Entdecken Sie die Rolle des luteinisierenden Hormons und seine Bedeutung für den Eisprung und die Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/lh/lh.component').then(m => m.LhDeComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Deutsche Samenbanken im Vergleich: Kosten, Behandlungen und Vergütungen",
        description: "Vergleichen Sie Kosten, Bedingungen und Wartezeiten der führenden Samenbanken in Deutschland. Finden Sie die beste Option für Ihre Bedürfnisse.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankDeComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Vergleich der Top-Kinderwunschkliniken in Deutschland – Kosten und Leistungen",
        description: "Vergleichen Sie die besten Kinderwunschkliniken in Deutschland: Kosten, Behandlungen und Wartezeiten. Finden Sie die passende Klinik für Ihre Behandlung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteDeComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Wie Chlamydien-Infektionen Ihre Fruchtbarkeit beeinträchtigen: Symptome, Risiken und Schutzmaßnahmen",
        description: "Chlamydien-Infektionen beeinträchtigen Fruchtbarkeit. Erfahren Sie mehr über Symptome, Risiken und Schutzmaßnahmen zur Vermeidung dieser häufigen Infektion.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/clam/clam.component').then(m => m.ClamDeComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Meine Erfahrungen als Spenderkind in einer LGBTQ+ Familie",
        description: "Erfahren Sie von Emilia, wie es ist, als Spenderkind in einer LGBTQ+ Familie aufzuwachsen. Ein Blick auf Liebe, Herausforderungen und die Bedeutung von Familie.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/emilia/emilia.component').then(m => m.EmiliaDeComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Fehlgeburt: Ursachen, Symptome und wie Sie Hilfe finden können",
        description: "Fehlgeburt: Ursachen, Symptome und Hilfe. Erfahren Sie, wie Sie Unterstützung finden und mit den Herausforderungen umgehen können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtDeComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Frühgeburt: Ursachen, Symptome und moderne Behandlungsoptionen im Überblick",
        description: "Frühgeburt: Erfahren Sie mehr über Ursachen, Symptome und moderne Behandlungsoptionen. Wichtige Tipps zur Prävention und Unterstützung für betroffene Eltern.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/fruehgeb/fruehgeb.component').then(m => m.ClamDeComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Mutterpass: Ein unverzichtbarer Begleiter durch die Schwangerschaft",
        description: "Erfahren Sie, warum der Mutterpass essenziell für Ihre Schwangerschaft ist und was die neue elektronische Version bietet.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/mutterpass/mutterpass.component').then(m => m.MutterpassComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Präimplantationsdiagnostik (PID) in Deutschland: Verfahren, rechtliche Aspekte und Erfahrungen",
        description: "Erfahren Sie alles über Präimplantationsdiagnostik (PID) in Deutschland: Verfahren, rechtliche Aspekte und aktuelle ethische Diskussionen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/PID/PID.component').then(m => m.PIDDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilisierung nach Sterilisation: Möglichkeiten und Erfolgschancen",
        description: "Nach einer Sterilisation wieder fruchtbar werden: Ein Überblick über Refertilisierung, Erfolgschancen und mögliche Alternativen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/referti-frau/referti-frau.component').then(m => m.RefertiFrauDeComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilisierung: Chancen und Risiken der Umkehrung einer Vasektomie",
        description: "Erfahren Sie alles über Refertilisierung nach Vasektomie: Erfolgschancen, Risiken und alternative Methoden zur Wiederherstellung der Fruchtbarkeit.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/referti-mann/referti-mann.component').then(m => m.RefertiMannDeComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Unfruchtbarkeit bei Männern: Die 10 häufigsten Ursachen und wie du sie erkennst",
        description: "Erfahre alles über Unfruchtbarkeit bei Männern. Die 10 häufigsten Ursachen, wie du sie erkennst und Behandlungsmöglichkeiten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtDeComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Brustwachstum und Schmerzen während der Schwangerschaft: Ursachen und Tipps",
        description: "Entdecken Sie die Ursachen von Brustwachstum und Schmerzen während der Schwangerschaft und erhalten Sie hilfreiche Tipps zur Linderung und Pflege.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangDeComponent)
    },
    {
      path: Paths.BlogBuerokratie,
      data: {
        title: "Bürokratie nach der Geburt: Wichtige Schritte für Eltern in Deutschland",
        description: "Erfahren Sie alles Wichtige über die bürokratischen Schritte nach der Geburt in Deutschland: Vaterschaftsanerkennung, Geburtsurkunde, Kindergeld und mehr.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/buerokratie/buerokratie.component').then(m => m.BuerokratieComponent)
    },
    {
      path: Paths.BlogÖsterreich,
      data: {
        title: "Samenspende Österreich: Anleitung, Gesetzeslage und Suche nach einem Samenspender",
        description: "Alles, was Sie über Samenspende in Österreich wissen müssen: Gesetzliche Anforderungen, private Spender, Kosten und wie Sie den besten Samenspender finden.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/österreich/österreich.component').then(m => m.ÖsterreichComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Den perfekten Samenspender finden: Der umfassende Ratgeber für Frauen",
        description: "Finden Sie den idealen Samenspender mit unserem umfassenden Ratgeber: Tipps, Optionen und rechtliche Hinweise.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/findespender/findespender.component').then(m => m.FindespenderDeComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Wie wird man Samenspender? Ein Leitfaden für Männer",
        description: "Willst du Samenspender werden? Hier findest du alles Wichtige zu den Voraussetzungen, dem Ablauf und den finanziellen sowie rechtlichen Aspekten.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/werdespender/werdespender.component').then(m => m.WerdespenderDeComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Unfruchtbarkeit bei Frauen: Die 10 häufigsten Ursachen und wie du sie erkennst",
        description: "Entdecke die 10 häufigsten Ursachen für weibliche Unfruchtbarkeit und erfahre, wie du sie erkennen und behandeln kannst.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenDeComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Wie lange überleben Spermien?",
        description: "Wie lange überleben Spermien? Erfahren Sie, wie lange Sperma unter verschiedenen Bedingungen überleben können und welche Faktoren ihre Lebensdauer beeinflussen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienDeComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Die Pille Absetzen: Gründe, Methoden und Was Danach Passiert",
        description: "Pille absetzen: Gründe, Tipps und was danach passiert. Erfahre, wie du den Übergang meisterst und mögliche Veränderungen deines Körpers.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenDeComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Künstliche Befruchtung: Kosten für Kinderwunschkliniken, Samenbanken und finanzielle Hilfen im Überblick",
        description: "Erfahre alles über die Kosten der künstlichen Befruchtung, Versicherungsoptionen und finanzielle Hilfen. Tipps für eine kosteneffiziente Kinderwunschbehandlung.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/kostenAI/kostenAI.component').then(m => m.KostenaiDEComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Wie Zervixschleim Ihre fruchtbaren Tage enthüllt: Tipps zur genauen Beobachtung",
        description: "Zervixschleim als Indikator für fruchtbare Tage: Lernen Sie, wie Sie diesen natürlichen Marker nutzen, um Ihre Fruchtbarkeit effektiv zu bestimmen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/zervix/zervix.component').then(m => m.ZervixDEComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natürliche Wege zur Verzögerung der Menopause: Wissenschaftlich fundierte Methoden und Ernährungstipps",
        description: "Erfahren Sie, wie Sie die Menopause durch Ernährung, Lebensstil und natürliche Methoden hinauszögern können. Tipps für ein gesundes Altern.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/menopause/menopause.component').then(m => m.MenopauseDeComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Ursachen und Bedeutung der dunklen Linie während der Schwangerschaft",
        description: "Was ist die Linea Nigra? Erfahren Sie, warum diese dunkle Linie während der Schwangerschaft auftritt und wie Sie ihre Pigmentierung reduzieren können.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraDeComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Gelbkörperschwäche: Ursachen, Symptome und wirksame Behandlungsoptionen",
        description: "Erfahren Sie alles über Gelbkörperschwäche: Ursachen, Symptome, Diagnose und wirksame Behandlungsmöglichkeiten zur Unterstützung bei Fruchtbarkeitsproblemen.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/de/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeDeComponent)
    }
  ];
  static readonly englishRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Frequently Asked Questions (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/en/en-faq.component').then(m => m.EnFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Egg Donation in the United Kingdom: Opportunities, Risks, and Legal Framework",
        description: "Egg Donation in the United Kingdom: Opportunities, Risks, and Legal Framework",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeEnComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Male Infertility: The 10 Most Common Causes and How to Identify Them",
        description: "Male Infertility: The 10 Most Common Causes and How to Identify Them",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtEnComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "UK Sperm Banks Compared: Costs, Treatments, and Compensation",
        description: "UK Sperm Banks Compared: Costs, Treatments, and Compensation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankEnComponent)
    },
    {
      path: Paths.BlogIndien,
      data: {
        title: "Sperm Donation in India: Guide, Legal Framework, and Finding a Sperm Donor",
        description: "Sperm Donation in India: Guide, Legal Framework, and Finding a Sperm Donor.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/indien/indien.component').then(m => m.IndienEnComponent)
    },
    {
      path: Paths.BlogUK,
      data: {
        title: "Sperm Donation in the United Kingdom: Guide, Legal Framework, and Finding a Sperm Donor",
        description: "Sperm Donation in the United Kingdom: Guide, Legal Framework, and Finding a Sperm Donor.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/uk/uk.component').then(m => m.UkComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Artificial Insemination: Costs for Fertility Clinics, Sperm Banks, and Financial Aid in the UK",
        description: "Artificial Insemination: Costs for Fertility Clinics, Sperm Banks, and Financial Aid in the UK",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/kostenAI/kostenAI.component').then(m => m.KostenaiEnComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparison of Top Fertility Clinics in the United Kingdom – Costs and Services",
        description: "Comparison of Top Fertility Clinics in the United Kingdom – Costs and Services",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteEnComponent)
    },
    {
      path: Paths.BlogTurkeyBasterPregnancy,
      data: {
        title: "Turkey Baster Pregnancy - A Home Insemiantion Guide",
        description: "Turkey Baster Pregnancy - A Home Insemiantion Guide",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/turkey-baster-pregnancy/turkey-baster-pregnancy.component').then(m => m.TurkeyBasterPregnancyEnComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Home Insemination - Artificial insemination at home",
        description: "Home Insemination - Artificial insemination at home",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/home-insemination/home-insemination.component').then(m => m.HomeInseminationEnComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Pre-implantation Genetic Diagnosis (PGD) in the UK: Procedures, Legal Aspects, and Experiences",
        description: "Pre-implantation Genetic Diagnosis (PGD) in the UK: Procedures, Legal Aspects, and Experiences",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/PID/PID.component').then(m => m.PIDEnComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Maternity Notes: An Essential Companion Throughout Pregnancy in the United Kingdom",
        description: "Maternity Notes: An Essential Companion Throughout Pregnancy in the United Kingdom",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/mutterpass/mutterpass.component').then(m => m.MutterpassEnComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting in 2024: Navigating Modern Family Dynamics",
        description: "Co-Parenting in 2024: Navigating Modern Family Dynamics",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingEnComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Pre-ejaculate and Pregnancy: Can I Get Pregnant from Pre-ejaculate? What Are the Real Risks?",
        description: "Pre-ejaculate and Pregnancy: Can I Get Pregnant from Pre-ejaculate? What Are the Real Risks?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateEnComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Blue Balls, Testicular Pain, and Sperm Retention",
        description: "Blue Balls, Testicular Pain, and Sperm Retention",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/samenstau-en/samenstau-en.component').then(m => m.SamenstauEnComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Sperm Cramps: Causes, Symptoms, and Treatment",
        description: "Sperm Cramps: Causes, Symptoms, and Treatment",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsEnComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        description: "Implantation: Definition, Timing, and Essential Information on Early Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/einnistung-en/einnistung-en.component').then(m => m.EinnistungEnComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        description: "In Vitro Fertilization (IVF): Comprehensive Guide, Costs, Steps, and More",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/ivf-en/ivf-en.component').then(m => m.IvfEnComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surrogacy: Legal Issues, Costs, Traditional and Gestational Models",
        description: "Surrogacy: Legal Issues, Costs, Traditional and Gestational Models",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/leihmut-en/leihmut-en.component').then(m => m.LeihmutEnComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polycystic Ovary Syndrome (PCOS): Causes, Symptoms, and Treatment Overview",
        description: "Polycystic Ovary Syndrome (PCOS): Causes, Symptoms, and Treatment Overview",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/pco-en/pco-en.component').then(m => m.PcoEnComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Semen Analysis: Everything You Need to Know About Evaluating Male Fertility",
        description: "Semen Analysis: Everything You Need to Know About Evaluating Male Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammEnComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "The Stork Legend Explained: Why Storks Are Said to Bring Babies",
        description: "The Stork Legend Explained: Why Storks Are Said to Bring Babies?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/klapper-en/klapper-en.component').then(m => m.KlapperEnComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "How to Get Pregnant Faster: 10 Proven Strategies for Success",
        description: "How to Get Pregnant Faster: 10 Proven Strategies for Success",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/schnell-en/schnell-en.component').then(m => m.SchnellEnComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Transmissible Diseases in Sperm Donation: Risks and Protective Measures",
        description: "Transmissible Diseases in Sperm Donation: Risks and Protective Measures",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/krank-en/krank-en.component').then(m => m.KrankEnComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Pregnancy Bleeding: Causes and Differences from Menstruation",
        description: "Pregnancy Bleeding: Causes and Differences from Menstruation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/mensschw-en/mensschw-en.component').then(m => m.MensschwEnComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Unvaccinated vs. Vaccinated Sperm: Understanding Sperm Quality After COVID-19 Vaccination",
        description: "Unvaccinated vs. Vaccinated Sperm: Understanding Sperm Quality After COVID-19 Vaccination",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/impf-en/impf-en.component').then(m => m.ImpfEnComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Understanding Ovulation: The Key to Increasing Your Fertility",
        description: "Understanding Ovulation: The Key to Increasing Your Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/ei-en/ei-en.component').then(m => m.EiEnComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        description: "All About Intrauterine Insemination (IUI): Procedure, Costs, and Success Rates",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/iui-en/iui-en.component').then(m => m.IuiEnComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        description: "ICSI: The Revolution in Reproductive Medicine for Treating Male Infertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/icsi/icsi.component').then(m => m.IcsiEnComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Artificial Insemination | RattleStork",
        description: "Artificial Insemination",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/kunst-en/kunst-en.component').then(m => m.KunstEnComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        description: "Intracervical Insemination (ICI): The Gentle Method for Family Planning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/ici-en/ici-en.component').then(m => m.IciEnComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Surge and Ovulation: Your Guide to Understanding and Monitoring Fertility",
        description: "LH Surge and Ovulation: Your Guide to Understanding and Monitoring Fertility",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/lh/lh.component').then(m => m.LhEnComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "How Chlamydia Infections Affect Your Fertility: Symptoms, Risks, and Precautions",
        description: "How Chlamydia Infections Affect Your Fertility: Symptoms, Risks, and Precautions",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/clam-en/clam-en.component').then(m => m.ChlamydiaEnComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview: My Experience as a Donor-Conceived Child in an LGBTQ+ Family",
        description: "Interview: My Experience as a Donor-Conceived Child in an LGBTQ+ Family",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/emilia-en/emilia-en.component').then(m => m.EmiliaEnComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Miscarriage: Causes, Symptoms, and How to Find Support",
        description: "Miscarriage: Causes, Symptoms, and How to Find Support",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtEnComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Premature Birth: Overview of Causes, Symptoms, and Modern Treatment Options",
        description: "Premature Birth: Overview of Causes, Symptoms, and Modern Treatment Options",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtEnComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Reversal of Sterilization: Options and Success Rates",
        description: "Reversal of Sterilization: Options and Success Rates",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauEnComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilization: Opportunities and Risks of Reversing a Vasectomy",
        description: "Refertilization: Opportunities and Risks of Reversing a Vasectomy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannEnComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Breast Growth and Pain During Pregnancy: Causes and Tips",
        description: "Breast Growth and Pain During Pregnancy: Causes and Tips",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/brust-en/brust-en.component').then(m => m.BrustEnComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Finding the Perfect Sperm Donor: The Comprehensive Guide for Women",
        description: "Finding the Perfect Sperm Donor: The Comprehensive Guide for Women",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/findespender-en/findespender-en.component').then(m => m.FindeSpenderEnComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "How to Become a Sperm Donor? A Guide for Men",
        description: "How to Become a Sperm Donor? A Guide for Men",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderEnComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Female Infertility: The 10 Most Common Causes and How to Recognize Them",
        description: "Female Infertility: The 10 Most Common Causes and How to Recognize Them",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenEnComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "How Long Can Sperm live?",
        description: "The survival of sperm is a topic surrounded by many myths and misconceptions. From hot baths to electromagnetic radiation, much can affect their lifespan.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienEnComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Stopping the Pill: Reasons, Methods, and What Happens Next",
        description: "Stopping the Pill: Reasons, Methods, and What Happens Next",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenEnComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "How Cervical Mucus Reveals Your Fertile Days: Tips for Accurate Observation",
        description: "How Cervical Mucus Reveals Your Fertile Days: Tips for Accurate Observation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/zervix-en/zervix-en.component').then(m => m.ZervixEnComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natural Ways to Delay Menopause: Scientifically Backed Methods and Nutritional Tips",
        description: "Natural Ways to Delay Menopause: Scientifically Backed Methods and Nutritional Tips",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/menopause-en/menopause-en.component').then(m => m.MenopauseEnComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        description: "Linea Nigra: Causes and Significance of the Dark Line During Pregnancy",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/linea-en/linea-en.component').then(m => m.LineaNigraEnComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        description: "Luteal Phase Deficiency: Causes, Symptoms, and Effective Treatment Options",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/en/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeEnComponent)
    },
  ];
  static readonly chineseRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "常见问题（FAQ）| RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/zh/ch-faq.component').then(m => m.ZhFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "卵子捐赠在中国、香港和台湾：机会、风险及法律解读",
        description: "卵子捐赠在中国、香港和台湾：机会、风险及法律解读",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeZhComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "男性不育：10个最常见的原因及如何识别",
        description: "男性不育：10个最常见的原因及如何识别",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtZhComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "人工授精：了解中国生育诊所、精子库和财政支持的费用",
        description: "人工授精：了解中国生育诊所、精子库和财政支持的费用",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/kostenAI/kostenAI.component').then(m => m.KostenaiZhComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "精子银行对比：费用、治疗和补偿",
        description: "精子银行对比：费用、治疗和补偿",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankZhComponent)
    },
    {
      path: Paths.BlogChina,
      data: {
        title: "中国精子捐赠指南：法律情况及捐赠者搜索",
        description: "中国精子捐赠指南：法律情况及捐赠者搜索.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/china/china.component').then(m => m.ChinaComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "中国、香港和台湾顶级生育诊所比较 - 费用和服务",
        description: "中国、香港和台湾顶级生育诊所比较 - 费用和服务",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteZhComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "精子捐赠中的传染病：风险与防护措施",
        description: "精子捐赠中的传染病：风险与防护措施",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/krank-en/krank-en.component').then(m => m.KrankZhComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "多囊卵巢综合征（PCOS）：原因、症状及治疗概述",
        description: "多囊卵巢综合征（PCOS）：原因、症状及治疗概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/pco-en/pco-en.component').then(m => m.PcoZhComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Unvaccinated Sperm vs. Vaccinated Sperm | RattleStork",
        description: "Unvaccinated Sperm vs. Vaccinated Sperm",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/impf-en/impf-en.component').then(m => m.ImpfZhComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "绝育术后再生育：可能性和成功率",
        description: "绝育术后再生育：可能性和成功率",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauZhComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "逆行性输精管吻合术：输精管结扎术逆转的机会与风险",
        description: "逆行性输精管吻合术：输精管结扎术逆转的机会与风险",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannZhComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "停用避孕药：原因、方法及其后的变化",
        description: "停用避孕药：原因、方法及其后的变化",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenZhComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "找到完美的精子捐献者：女性全面指南",
        description: "找到完美的精子捐献者：女性全面指南",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/findespender-en/findespender-en.component').then(m => m.FindeSpenderZhComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "如何成为精子捐赠者？男性指南",
        description: "如何成为精子捐赠者？男性指南",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderZhComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "衣原体感染如何影响您的生育能力：症状、风险和防护措施",
        description: "衣原体感染如何影响您的生育能力：症状、风险和防护措施",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/clam-en/clam-en.component').then(m => m.ChlamydiaZhComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "推迟更年期的自然方法：基于科学的策略与饮食建议",
        description: "推迟更年期的自然方法：基于科学的策略与饮食建议",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/menopause-en/menopause-en.component').then(m => m.MenopauseZhComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "访谈：作为LGBTQ+家庭中的捐赠者子女，我的经历",
        description: "访谈：作为LGBTQ+家庭中的捐赠者子女，我的经历",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/emilia-en/emilia-en.component').then(m => m.EmiliaZhComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "如何更快怀孕：10个成功怀孕的经过验证的策略",
        description: "如何更快怀孕：10个成功怀孕的经过验证的策略",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/schnell-en/schnell-en.component').then(m => m.SchnellZhComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "流产：原因、症状以及如何寻求帮助",
        description: "流产：原因、症状以及如何寻求帮助",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtZhComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "早产：原因、症状及现代治疗方案概述",
        description: "早产：原因、症状及现代治疗方案概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtZhComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "怀孕期间的乳房发育与疼痛：原因与建议",
        description: "怀孕期间的乳房发育与疼痛：原因与建议",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/brust-en/brust-en.component').then(m => m.BrustZhComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "女性不孕症：最常见的10个原因及如何识别它们",
        description: "女性不孕症：最常见的10个原因及如何识别它们",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenZhComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "黄体生成素激增和排卵：理解和监测生育力的指南",
        description: "黄体生成素激增和排卵：理解和监测生育力的指南",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/lh/lh.component').then(m => m.LhZhComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "怀孕期间出血：原因及与月经的区别",
        description: "怀孕期间出血：原因及与月经的区别",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/mensschw-en/mensschw-en.component').then(m => m.MensschwZhComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "宫颈粘液如何揭示您的排卵期：精确观察技巧",
        description: "宫颈粘液如何揭示您的排卵期：精确观察技巧",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/zervix-en/zervix-en.component').then(m => m.ZervixZhComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "前列腺液与怀孕：前列腺液会导致怀孕吗？风险有多大？",
        description: "前列腺液与怀孕：前列腺液会导致怀孕吗？风险有多大？",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateZhComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "中国的植入前遗传学诊断：过程、法律规定与实践经验",
        description: "中国的植入前遗传学诊断：过程、法律规定与实践经验",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/PID/PID.component').then(m => m.PIDZhComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "家庭授精：无需性交即可怀孕",
        description: "家庭授精：无需性交即可怀孕",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/home-insemination/home-insemination.component').then(m => m.HomeInseminationZhComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "精液分析：全面解析男性生育力的重要测试",
        description: "精液分析：全面解析男性生育力的重要测试",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammZhComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "妊娠纹：怀孕期间出现的黑线的原因及意义",
        description: "妊娠纹：怀孕期间出现的黑线的原因及意义",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/linea-en/linea-en.component').then(m => m.LineaNigraZhComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "黄体功能不全：原因、症状和有效的治疗方案",
        description: "黄体功能不全：原因、症状和有效的治疗方案",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeZhComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "代孕：法律问题、费用、传统和妊娠代孕模式",
        description: "代孕：法律问题、费用、传统和妊娠代孕模式",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/leihmut-en/leihmut-en.component').then(m => m.LeihmutZhComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "了解排卵：提高生育能力的关键",
        description: "了解排卵：提高生育能力的关键",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/ei-en/ei-en.component').then(m => m.EiZhComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "胚胎着床：定义、时间和早期怀孕的重要信息",
        description: "胚胎着床：定义、时间和早期怀孕的重要信息",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/einnistung-en/einnistung-en.component').then(m => m.EinnistungZhComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "精液痉挛：原因、症状及治疗方法",
        description: "精液痉挛：原因、症状及治疗方法",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsZhComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "2024年共同养育：现代家庭模式的全面指南",
        description: "2024年共同养育：现代家庭模式的全面指南",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingZhComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "精子的存活时间：影响因素与改善精子质量的实用建议",
        description: "精子的存活时间：影响因素与改善精子质量的实用建议",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienZhComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "体外受精：综合指南——费用、步骤等",
        description: "体外受精：综合指南——费用、步骤等",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/ivf-en/ivf-en.component').then(m => m.IvfZhComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "精液积聚、骑士痛、蓝球症",
        description: "精液积聚、骑士痛、蓝球症",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/samenstau-en/samenstau-en.component').then(m => m.SamenstauZhComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "鹳鸟的传说：为什么鹳鸟送孩子?",
        description: "鹳鸟的传说：为什么鹳鸟送孩子?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/klapper-en/klapper-en.component').then(m => m.KlapperZhComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "关于宫腔内人工授精：过程、费用和成功率",
        description: "关于宫腔内人工授精：过程、费用和成功率",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/iui-en/iui-en.component').then(m => m.IuiZhComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "胞浆内单精子注射：治疗男性不育的生殖医学革命",
        description: "胞浆内单精子注射：治疗男性不育的生殖医学革命",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/icsi/icsi.component').then(m => m.IcsiZhComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "人工授精：方法、成功率和费用概述",
        description: "人工授精：方法、成功率和费用概述",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/kunst-en/kunst-en.component').then(m => m.KunstZhComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "宫颈内人工授精：家庭计划的温和方法",
        description: "宫颈内人工授精：家庭计划的温和方法",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/zh/ici-en/ici-en.component').then(m => m.IciZhComponent)
    },
  ];
  static readonly spanishRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Preguntas frecuentes (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/es/esp-faq.component').then(m => m.EspFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Ovodonación en España: Todo sobre Oportunidades, Riesgos y Legislación",
        description: "Ovodonación en España: Todo sobre Oportunidades, Riesgos y Legislación",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeEsComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilidad en los hombres: Las 10 causas más comunes y cómo reconocerlas",
        description: "Infertilidad en los hombres: Las 10 causas más comunes y cómo reconocerlas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtEsComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Reproducción Asistida: Costos de Clínicas de Fertilidad, Bancos de Esperma y Ayudas Financieras en España",
        description: "Reproducción Asistida: Costos de Clínicas de Fertilidad, Bancos de Esperma y Ayudas Financieras en España",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/kostenAI/kostenAI.component').then(m => m.KostenaiEsComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Comparativa de Bancos de Semen en España: Costos, Tratamientos y Compensaciones",
        description: "Comparativa de Bancos de Semen en España: Costos, Tratamientos y Compensaciones",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankEsComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparación de las Mejores Clínicas de Fertilidad en España: Costos y Servicios",
        description: "Comparación de las Mejores Clínicas de Fertilidad en España: Costos y Servicios",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteEsComponent)
    },
    {
      path: Paths.BlogSpanien,
      data: {
        title: "Donación de Esperma en España: Guía, Legislación y Búsqueda de un Donante",
        description: "Donación de Esperma en España: Guía, Legislación y Búsqueda de un Donante",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/spanien/spanien.component').then(m => m.SpanienComponent)
    },
    {
      path: Paths.BlogMexico,
      data: {
        title: "Donación de Esperma en México: Guía, Legislación y Búsqueda de un Donante",
        description: "Donación de Esperma en México: Guía, Legislación y Búsqueda de un Donante",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/mexico/mexico.component').then(m => m.MexicoComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Embarazo con Jeringa - Guía de Inseminación Artificial Casera",
        description: "Embarazo con Jeringa - Guía de Inseminación Artificial Casera",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/h-i-esp/h-i-esp.component').then(m => m.HIEspComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Todo lo que necesitas saber sobre los modelos familiares modernos",
        description: "Co-Parenting 2024: Todo lo que necesitas saber sobre los modelos familiares modernos",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/co-pa-esp/co-pa-esp.component').then(m => m.CoPaEspComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Preimplantacional (DGP) en España: Procedimientos, Aspectos Legales, Éticos y Experiencias Personales",
        description: "Diagnóstico Genético Preimplantacional (DGP) en España: Procedimientos, Aspectos Legales, Éticos y Experiencias Personales",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/PID/PID.component').then(m => m.PIDEsComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Gota preseminal y embarazo: ¿Puedo quedar embarazada por la gota preseminal? ¿Cuál es realmente el riesgo?",
        description: "Gota preseminal y embarazo: ¿Puedo quedar embarazada por la gota preseminal? ¿Cuál es realmente el riesgo?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/lusttro-esp/lusttro-esp.component').then(m => m.LusttroEspComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestión seminal, dolor testicular, testículos azules",
        description: "Congestión seminal, dolor testicular, testículos azules",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/samenstau-esp/samenstau-esp.component').then(m => m.SamenstauEspComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Calambres de Esperma: Causas, Síntomas y Tratamiento",
        description: "Calambres de Esperma: Causas, Síntomas y Tratamiento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/spermakrampf-esp/spermakrampf-esp.component').then(m => m.SpermakrampfEspComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        description: "Implantación: Definición, Momento y Información Esencial sobre el Embarazo Temprano",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/einnistung-esp/einnistung-esp.component').then(m => m.EinnistungEspComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminación Intracervical (ICI) | RattleStork",
        description: "Inseminación Intracervical (ICI)",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/ici-esp/ici-esp.component').then(m => m.IciEspComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        description: "Fecundación In Vitro (FIV): Guía Completa: Costos, Pasos y Más",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/ivf-esp/ivf-esp.component').then(m => m.IvfEspComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Subrogación: Cuestiones legales, costos, modelos tradicionales y gestacionales",
        description: "Subrogación: Cuestiones legales, costos, modelos tradicionales y gestacionales",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/leihmut-esp/leihmut-esp.component').then(m => m.LeihmutEspComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Síndrome de Ovario Poliquístico (SOP): Causas, Síntomas y Tratamiento en Resumen",
        description: "Síndrome de Ovario Poliquístico (SOP): Causas, Síntomas y Tratamiento en Resumen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/pco-esp/pco-esp.component').then(m => m.PcoEspComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermiograma: Todo lo que necesitas saber sobre el análisis de la fertilidad masculina",
        description: "Espermiograma: Todo lo que necesitas saber sobre el análisis de la fertilidad masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/spermiogramm-esp/spermiogramm-esp.component').then(m => m.SpermiogrammEspComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La leyenda de la cigüeña: ¿Por qué trae la cigüeña a los niños?",
        description: "La leyenda de la cigüeña: ¿Por qué trae la cigüeña a los niños?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/klapper-esp/klapper-esp.component').then(m => m.KlapperEspComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Cómo quedar embarazada más rápido: 10 estrategias comprobadas para un embarazo exitoso",
        description: "Cómo quedar embarazada más rápido: 10 estrategias comprobadas para un embarazo exitoso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/schnell-esp/schnell-esp.component').then(m => m.SchnellEspComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Enfermedades transferibles durante la donación de esperma | RattleStork",
        description: "Enfermedades transferibles durante la donación de esperma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/krank-esp/krank-esp.component').then(m => m.KrankEspComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        description: "Fecundación artificial: métodos, probabilidades de éxito y costos en resumen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/kunst-esp/kunst-esp.component').then(m => m.KunstEspComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sangrado durante el embarazo: Causas y diferencias con la menstruación",
        description: "Sangrado durante el embarazo: Causas y diferencias con la menstruación",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/menschschw-esp/menschschw-esp.component').then(m => m.MenschschwEspComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma no vacunado vs. Esperma vacunado: Hechos sobre la calidad del esperma después de la vacunación contra COVID-19",
        description: "Esperma no vacunado vs. Esperma vacunado: Hechos sobre la calidad del esperma después de la vacunación contra COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/impf-esp/impf-esp.component').then(m => m.ImpfEspComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Comprender la ovulación: La clave para aumentar tu fertilidad",
        description: "Comprender la ovulación: La clave para aumentar tu fertilidad",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/ei-esp/ei-esp.component').then(m => m.EiEspComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        description: "Todo sobre la Inseminación Intrauterina (IUI): Proceso, Costos y Probabilidades de Éxito",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/iui-esp/iui-esp.component').then(m => m.IuiEspComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: La Revolución de la Medicina Reproductiva para el Tratamiento de la Infertilidad Masculina",
        description: "ICSI: La Revolución de la Medicina Reproductiva para el Tratamiento de la Infertilidad Masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/icsi/icsi.component').then(m => m.IcsiEspComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH y ovulación: Su guía para comprender y monitorear la fertilidad",
        description: "Aumento de LH y ovulación: Su guía para comprender y monitorear la fertilidad",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/lh/lh.component').then(m => m.LhEspComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Cómo las infecciones por clamidia afectan tu fertilidad: síntomas, riesgos y medidas de protección",
        description: "Cómo las infecciones por clamidia afectan tu fertilidad: síntomas, riesgos y medidas de protección",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/clam-esp/clam-esp.component').then(m => m.ClamydiaEspComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Entrevista: Mis experiencias como hijo donante en una familia LGBTQ+",
        description: "Entrevista: Mis experiencias como hijo donante en una familia LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/emilia-esp/emilia-esp.component').then(m => m.EmiliaEspComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto espontáneo: causas, síntomas y cómo encontrar ayuda",
        description: "Aborto espontáneo: causas, síntomas y cómo encontrar ayuda",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/fehlgeburt-esp/fehlgeburt-esp.component').then(m => m.FehlgeburtEspComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: Causas, síntomas y opciones de tratamiento modernas en perspectiva",
        description: "Parto prematuro: Causas, síntomas y opciones de tratamiento modernas en perspectiva",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/fruehgeburt-esp/fruehgeburt-esp.component').then(m => m.FruehgeburtEspComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Reversión de la esterilización: Posibilidades y tasas de éxito",
        description: "Reversión de la esterilización: Posibilidades y tasas de éxito",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/refertifrau-esp/refertifrau-esp.component').then(m => m.RefertifrauEspComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Reversión de vasectomía: Oportunidades y riesgos de la refertilización",
        description: "Reversión de vasectomía: Oportunidades y riesgos de la refertilización",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/refertimann-esp/refertimann-esp.component').then(m => m.RefertimannEspComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crecimiento y dolor en los senos durante el embarazo: causas y consejos",
        description: "Crecimiento y dolor en los senos durante el embarazo: causas y consejos",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/brust-esp/brust-esp.component').then(m => m.BrustEspComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Cómo encontrar al donante de esperma perfecto: La guía completa para mujeres",
        description: "Cómo encontrar al donante de esperma perfecto: La guía completa para mujeres",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/findespender-esp/findespender-esp.component').then(m => m.FindespenderEspComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "¿Cómo convertirse en donante de esperma? Una guía para hombres",
        description: "¿Cómo convertirse en donante de esperma? Una guía para hombres",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/werdespender-esp/werdespender-esp.component').then(m => m.WerdespenderEspComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilidad en mujeres: Las 10 causas más comunes y cómo identificarlas",
        description: "Infertilidad en mujeres: Las 10 causas más comunes y cómo identificarlas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/zehnunfruchtfrau-esp/zehnunfruchtfrau-esp.component').then(m => m.ZehnunfruchtfrauEspComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Tiempo de Vida de los Espermatozoides",
        description: "Duración de Vida de los Espermatozoides: ¿Cuánto Tiempo Pueden Sobrevivir?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/ueberleben-esp/ueberleben-esp.component').then(m => m.UeberlebenEspComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Dejar de Tomar la Píldora: Razones, Métodos y Qué Sucede Después",
        description: "Dejar de Tomar la Píldora: Razones, Métodos y Qué Sucede Después",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/pilleab-esp/pilleab-esp.component').then(m => m.PilleabEspComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Cómo el moco cervical revela tus días fértiles: Consejos para una observación precisa",
        description: "Cómo el moco cervical revela tus días fértiles: Consejos para una observación precisa",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/zervix-esp/zervix-esp.component').then(m => m.ZervixEspComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Formas Naturales de Retrasar la Menopausia: Métodos Científicos y Consejos de Nutrición",
        description: "Formas Naturales de Retrasar la Menopausia: Métodos Científicos y Consejos de Nutrición",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/meonpause-esp/meonpause-esp.component').then(m => m.MeonpauseEspComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        description: "Linea Nigra: Causas y Significado de la Línea Oscura Durante el Embarazo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/linea-esp/linea-esp.component').then(m => m.LineaEspComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuficiencia del cuerpo lúteo: causas, síntomas y opciones de tratamiento efectivas",
        description: "La insuficiencia del cuerpo lúteo es un trastorno funcional del cuerpo lúteo en el ovario",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/es/gelbkörper-esp/gelbkörper-esp.component').then(m => m.GelbkörperEspComponent)
    }
  ];
  static readonly frenchRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Questions fréquemment posées (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },

      loadComponent: () => import('./faq/fr/fr-faq.component').then(m => m.FrFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Don d'ovocytes en France : Opportunités, risques et législation",
        description: "Don d'ovocytes en France : Opportunités, risques et législation",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeFrComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilité chez les hommes : Les 10 causes les plus fréquentes et comment les reconnaître",
        description: "Infertilité chez les hommes : Les 10 causes les plus fréquentes et comment les reconnaître",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtFrComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Procréation Médicalement Assistée (PMA) en France : Coûts des cliniques, banques de sperme et aides financières",
        description: "Procréation Médicalement Assistée (PMA) en France : Coûts des cliniques, banques de sperme et aides financières",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/kostenAI/kostenAI.component').then(m => m.KostenaiFrComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Banques de sperme en France: Coûts, traitements et compensations",
        description: "Banques de sperme en France: Coûts, traitements et compensations",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankFrComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparaison des meilleures cliniques de fertilité en France – Coûts et services",
        description: "Comparaison des meilleures cliniques de fertilité en France – Coûts et services",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteFrComponent)
    },
    {
      path: Paths.BlogFrankreich,
      data: {
        title: "Don de sperme en France : Guide, législation et recherche d'un donneur de sperme",
        description: "Don de sperme en France : Guide, législation et recherche d'un donneur de sperme",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/frankreich/frankreich.component').then(m => m.FrankreichComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnostic Génétique Préimplantatoire (DPI) en France : Procédures, Aspects Juridiques et Expériences",
        description: "Diagnostic Génétique Préimplantatoire (DPI) en France : Procédures, Aspects Juridiques et Expériences",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/PID/PID.component').then(m => m.PIDFrComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Carnet de Santé Maternité : Un compagnon indispensable pendant la grossesse en France",
        description: "Carnet de Santé Maternité : Un compagnon indispensable pendant la grossesse en France",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/mutterpass/mutterpass.component').then(m => m.MutterpassFrComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Insémination Artisanale : Guide de l'Insémination à Domicile",
        description: "Insémination Artisanale : Guide de l'Insémination à Domicile",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/home-insemination-fr/home-insemination-fr.component').then(m => m.HomeInseminationFrComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting en 2024 : Tout ce que vous devez savoir sur les nouveaux modèles familiaux",
        description: "Co-Parenting en 2024 : Tout ce que vous devez savoir sur les nouveaux modèles familiaux",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/co-parenting-fr/co-parenting-fr.component').then(m => m.CoParentingFrComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Goutte pré-éjaculatoire et grossesse : Puis-je tomber enceinte à cause de la goutte pré-éjaculatoire ? Quel est le vrai risque ?",
        description: "Goutte pré-éjaculatoire et grossesse : Puis-je tomber enceinte à cause de la goutte pré-éjaculatoire ? Quel est le vrai risque ?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/lusttropfen-fr/lusttropfen-fr.component').then(m => m.LusttropfenFrComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Accumulation de sperme, Douleur des testicules, Testicules bleus",
        description: "Accumulation de sperme, Douleur des testicules, Testicules bleus",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/samenstau-fr/samenstau-fr.component').then(m => m.SamenstauFrComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Crampes spermatiques : causes, symptômes et traitement",
        description: "Crampes spermatiques : causes, symptômes et traitement",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/spermakrampf-fr/spermakrampf-fr.component').then(m => m.SpermakrampfFrComponent)
    }
    ,
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantation : Définition, moment et informations essentielles sur le début de la grossesse",
        description: "Implantation : Définition, moment et informations essentielles sur le début de la grossesse",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/einnistung-fr/einnistung-fr.component').then(m => m.EinnistungFrComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Insémination intracervicale (ICI) : La méthode douce de planification familiale",
        description: "Insémination intracervicale (ICI) : La méthode douce de planification familiale",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/ici-fr/ici-fr.component').then(m => m.IciFrComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fécondation In Vitro (FIV) : Guide complet : Coûts, étapes et plus",
        description: "Fécondation In Vitro (FIV) : Guide complet : Coûts, étapes et plus",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/ivf-fr/ivf-fr.component').then(m => m.IvfFrComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestation pour autrui : questions juridiques, coûts, modèles traditionnels et gestationnels",
        description: "Gestation pour autrui : questions juridiques, coûts, modèles traditionnels et gestationnels",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/leihmut-fr/leihmut-fr.component').then(m => m.LeihmutFrComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Syndrome des ovaires polykystiques (SOPK) : Causes, symptômes et traitement en un coup d'œil",
        description: "Syndrome des ovaires polykystiques (SOPK) : Causes, symptômes et traitement en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/pco-fr/pco-fr.component').then(m => m.PcoFrComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermogramme : Tout ce qu'il faut savoir sur l'analyse de la fertilité masculine",
        description: "Spermogramme : Tout ce qu'il faut savoir sur l'analyse de la fertilité masculine",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/spermiogramm-fr/spermiogramm-fr.component').then(m => m.SpermiogrammFrComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La légende de la cigogne : Pourquoi apporte-t-elle les bébés?",
        description: "La légende de la cigogne : Pourquoi apporte-t-elle les bébés?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/klapper-fr/klapper-fr.component').then(m => m.KlapperFrComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Comment tomber enceinte plus rapidement : 10 stratégies éprouvées pour une grossesse réussie",
        description: "Comment tomber enceinte plus rapidement : 10 stratégies éprouvées pour une grossesse réussie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/schnell-fr/schnell-fr.component').then(m => m.SchnellFrComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Maladies transmissibles lors du don de sperme : risques et mesures de protection",
        description: "Maladies transmissibles lors du don de sperme : risques et mesures de protection",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/krank-fr/krank-fr.component').then(m => m.KrankFrComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fécondation artificielle : Méthodes, chances de succès et coûts en un coup d'œil",
        description: "Fécondation artificielle : Méthodes, chances de succès et coûts en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/kunst-fr/kunst-fr.component').then(m => m.KunstFrComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Saignements pendant la grossesse : causes et différences avec les menstruations",
        description: "Saignements pendant la grossesse : causes et différences avec les menstruations",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/mensschw-fr/mensschw-fr.component').then(m => m.MensschwFrComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperme Non Vacciné vs. Sperme Vacciné : Faits sur la Qualité du Sperme après la Vaccination contre la COVID-19",
        description: "Sperme Non Vacciné vs. Sperme Vacciné : Faits sur la Qualité du Sperme après la Vaccination contre la COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/impf-fr/impf-fr.component').then(m => m.ImpfFrComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Comprendre l'ovulation : La clé pour augmenter votre fertilité",
        description: "Comprendre l'ovulation : La clé pour augmenter votre fertilité",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/ei-fr/ei-fr.component').then(m => m.EiFrComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tout sur l'Insémination Intra-utérine (IIU) : Processus, Coût et Taux de Réussite",
        description: "Tout sur l'Insémination Intra-utérine (IIU) : Processus, Coût et Taux de Réussite",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/iui-fr/iui-fr.component').then(m => m.IuiFrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI : La révolution de la médecine reproductive pour traiter l'infertilité masculine",
        description: "ICSI : La révolution de la médecine reproductive pour traiter l'infertilité masculine",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/icsi/icsi.component').then(m => m.IcsiFrComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Montée de la LH et ovulation : votre guide pour comprendre et surveiller la fertilité",
        description: "Montée de la LH et ovulation : votre guide pour comprendre et surveiller la fertilité",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/lh/lh.component').then(m => m.LhFrComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Comment les infections à Chlamydia affectent votre fertilité : Symptômes, risques et mesures de protection",
        description: "Comment les infections à Chlamydia affectent votre fertilité : Symptômes, risques et mesures de protection",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/clam-fr/clam-fr.component').then(m => m.ClamydiaFrComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview : Mon expérience en tant qu'enfant de donneur dans une famille LGBTQ+",
        description: "Interview : Mon expérience en tant qu'enfant de donneur dans une famille LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/emilia-fr/emilia-fr.component').then(m => m.EmiliaFrComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Fausses couches : Causes, symptômes et comment trouver de l'aide",
        description: "Fausses couches : Causes, symptômes et comment trouver de l'aide",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/fehlgeburt-fr/fehlgeburt-fr.component').then(m => m.FehlgeburtFrComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Prématurité : causes, symptômes et options de traitement modernes en un coup d'œil",
        description: "Prématurité : causes, symptômes et options de traitement modernes en un coup d'œil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/fruehgeburt-fr/fruehgeburt-fr.component').then(m => m.FruehgeburtFrComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Réversion de la stérilisation : Options et taux de réussite",
        description: "Réversion de la stérilisation : Options et taux de réussite",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/refertifrau-fr/refertifrau-fr.component').then(m => m.RefertifrauFrComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Réfertilisation : Opportunités et Risques de la Réversibilité d'une Vasectomie",
        description: "Réfertilisation : Opportunités et Risques de la Réversibilité d'une Vasectomie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/refertimann-fr/refertimann-fr.component').then(m => m.RefertimannFrComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Croissance des seins et douleurs pendant la grossesse : causes et conseils",
        description: "Croissance des seins et douleurs pendant la grossesse : causes et conseils",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/brust-fr/brust-fr.component').then(m => m.BrustFrComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Trouver le donneur de sperme idéal : Le guide complet pour les femmes",
        description: "Trouver le donneur de sperme idéal : Le guide complet pour les femmes",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/findespender-fr/findespender-fr.component').then(m => m.FindespenderFrComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Comment devenir donneur de sperme ? Un guide pour les hommes",
        description: "Comment devenir donneur de sperme ? Un guide pour les hommes",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/werdespender-fr/werdespender-fr.component').then(m => m.WerdespenderFrComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilité chez les femmes : Les 10 causes les plus courantes et comment les reconnaître",
        description: "Infertilité chez les femmes : Les 10 causes les plus courantes et comment les reconnaître",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/zehnunfruchtfrau-fr/zehnunfruchtfrau-fr.component').then(m => m.ZehnunfruchtfrauFrComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quelle est la durée de vie des spermatozoïdes ?",
        description: "La durée de vie des spermatozoïdes est un sujet entouré de nombreux mythes et de malentendus. Divers facteurs peuvent affecter leur longévité.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/ueberleben-fr/ueberleben-fr.component').then(m => m.UeberlebenFrComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Arrêter la pilule : raisons, méthodes et ce qui se passe ensuite",
        description: "Arrêter la pilule : raisons, méthodes et ce qui se passe ensuite",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/pilleab-fr/pilleab-fr.component').then(m => m.PilleabFrComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Comment la glaire cervicale révèle vos jours fertiles : Conseils pour une observation précise",
        description: "Comment la glaire cervicale révèle vos jours fertiles : Conseils pour une observation précise",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/zervix-fr/zervix-fr.component').then(m => m.ZervixFrComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Moyens naturels pour retarder la ménopause : méthodes scientifiquement prouvées et conseils nutritionnels",
        description: "Moyens naturels pour retarder la ménopause : méthodes scientifiquement prouvées et conseils nutritionnels",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/menopause-fr/menopause-fr.component').then(m => m.MenopauseFrComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra : Causes et Signification de la Ligne Sombre Pendant la Grossesse",
        description: "Linea Nigra : Causes et Signification de la Ligne Sombre Pendant la Grossesse",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/linea-fr/linea-fr.component').then(m => m.LineaFrComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insuffisance lutéale : causes, symptômes et options de traitement efficaces",
        description: "Insuffisance lutéale : causes, symptômes et options de traitement efficaces",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/fr/gelbkörper-fr/gelbkörper-fr.component').then(m => m.GelbkörperFrComponent)
    }
  ];
  static readonly hindiRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "अक्सर पूछे जाने वाले प्रश्न (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },

      loadComponent: () => import('./faq/hi/hindi-faq.component').then(m => m.HindiFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "पुरुषों में बांझपन: 10 सबसे सामान्य कारण और उन्हें पहचानने के तरीके",
        description: "पुरुषों में बांझपन: 10 सबसे सामान्य कारण और उन्हें पहचानने के तरीके",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtHiComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "एलएच वृद्धि और ओव्यूलेशन: उर्वरता को समझने और निगरानी के लिए आपका गाइड",
        description: "एलएच वृद्धि और ओव्यूलेशन: उर्वरता को समझने और निगरानी के लिए आपका गाइड",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/lh/lh.component').then(m => m.LhHiComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "महिलाओं में बांझपन: 10 प्रमुख कारण और उन्हें कैसे पहचाने",
        description: "महिलाओं में बांझपन: 10 प्रमुख कारण और उन्हें कैसे पहचाने",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenHiComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "कृत्रिम गर्भाधान: भारत में उपचार की लागत, स्पर्म बैंक और वित्तीय सहायता का अवलोकन",
        description: "कृत्रिम गर्भाधान: भारत में उपचार की लागत, स्पर्म बैंक और वित्तीय सहायता का अवलोकन",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/kostenAI/kostenAI.component').then(m => m.KostenaiHiComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "अवांछित शुक्राणु बनाम टीकाकरण किए गए शुक्राणु: COVID-19 टीकाकरण के बाद शुक्राणु गुणवत्ता के तथ्य",
        description: "अवांछित शुक्राणु बनाम टीकाकरण किए गए शुक्राणु: COVID-19 टीकाकरण के बाद शुक्राणु गुणवत्ता के तथ्य",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/impf-en/impf-en.component').then(m => m.ImpfHiComponent)
    },
    {
      path: Paths.BlogIndien,
      data: {
        title: "भारत में शुक्राणु दान: गाइड, कानूनी ढांचा, और शुक्राणु दाता कैसे खोजें",
        description: "भारत में शुक्राणु दान: गाइड, कानूनी ढांचा, और शुक्राणु दाता कैसे खोजें.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/indien/indien.component').then(m => m.IndienHiComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "नसबंदी के बाद पुनः गर्भधारण: विकल्प और सफलता की संभावना",
        description: "नसबंदी के बाद पुनः गर्भधारण: विकल्प और सफलता की संभावना",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauHiComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "पॉलीसिस्टिक ओवरी सिंड्रोम (PCOS): कारण, लक्षण और उपचार का अवलोकन",
        description: "पॉलीसिस्टिक ओवरी सिंड्रोम (PCOS): कारण, लक्षण और उपचार का अवलोकन",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/pco-en/pco-en.component').then(m => m.PcoHiComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Why and how you should stop taking the pill | RattleStork",
        description: "Why and how you should stop taking the pill",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenHiComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "वीर्य दान में संचरित होने वाली बीमारियाँ: जोखिम और सुरक्षा उपाय",
        description: "वीर्य दान में संचरित होने वाली बीमारियाँ: जोखिम और सुरक्षा उपाय",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/krank-en/krank-en.component').then(m => m.KrankHiComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "कैसे क्लैमाइडिया संक्रमण आपकी प्रजनन क्षमता को प्रभावित करते हैं: लक्षण, जोखिम और बचाव के उपाय",
        description: "कैसे क्लैमाइडिया संक्रमण आपकी प्रजनन क्षमता को प्रभावित करते हैं: लक्षण, जोखिम और बचाव के उपाय",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/clam-en/clam-en.component').then(m => m.ChlamydiaHiComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "परफेक्ट स्पर्म डोनर ढूंढना: महिलाओं के लिए एक व्यापक गाइड",
        description: "परफेक्ट स्पर्म डोनर ढूंढना: महिलाओं के लिए एक व्यापक गाइड",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/findespender-en/findespender-en.component').then(m => m.FindeSpenderHiComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "वीर्यदाता कैसे बनें? पुरुषों के लिए एक मार्गदर्शिका",
        description: "वीर्यदाता कैसे बनें? पुरुषों के लिए एक मार्गदर्शिका",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderHiComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "पुन: प्रजनन: नसबंदी को उलटने के अवसर और जोखिम",
        description: "पुन: प्रजनन: नसबंदी को उलटने के अवसर और जोखिम",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannHiComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "रजोनिवृत्ति को देरी से लाने के प्राकृतिक तरीके: वैज्ञानिक रूप से प्रमाणित विधियाँ और आहार सुझाव",
        description: "रजोनिवृत्ति को देरी से लाने के प्राकृतिक तरीके: वैज्ञानिक रूप से प्रमाणित विधियाँ और आहार सुझाव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/menopause-en/menopause-en.component').then(m => m.MenopauseHiComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "साक्षात्कार: एक LGBTQ+ परिवार में डोनरकिड होने के मेरे अनुभव",
        description: "साक्षात्कार: एक LGBTQ+ परिवार में डोनरकिड होने के मेरे अनुभव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/emilia-en/emilia-en.component').then(m => m.EmiliaHiComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "गर्भपात: कारण, लक्षण और सहायता कैसे प्राप्त करें",
        description: "गर्भपात: कारण, लक्षण और सहायता कैसे प्राप्त करें",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtHiComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "समय से पूर्व प्रसव: कारण, लक्षण और आधुनिक उपचार विकल्पों का अवलोकन",
        description: "समय से पूर्व प्रसव: कारण, लक्षण और आधुनिक उपचार विकल्पों का अवलोकन",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtHiComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "गर्भावस्था के दौरान रक्तस्राव: कारण और मासिक धर्म से अंतर",
        description: "गर्भावस्था के दौरान रक्तस्राव: कारण और मासिक धर्म से अंतर",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/mensschw-en/mensschw-en.component').then(m => m.MensschwHiComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "तेज़ी से गर्भवती होने के 10 आज़माए हुए तरीके: सफल गर्भधारण के लिए रणनीतियाँ",
        description: "तेज़ी से गर्भवती होने के 10 आज़माए हुए तरीके: सफल गर्भधारण के लिए रणनीतियाँ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/schnell-en/schnell-en.component').then(m => m.SchnellHiComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "गर्भावस्था के दौरान स्तन वृद्धि और दर्द: कारण और सुझाव",
        description: "गर्भावस्था के दौरान स्तन वृद्धि और दर्द: कारण और सुझाव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/brust-en/brust-en.component').then(m => m.BrustHiComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "गर्भाशय ग्रीवा के श्लेष्म से अपने उपजाऊ दिनों को कैसे पहचानें: सटीक अवलोकन के लिए टिप्स",
        description: "गर्भाशय ग्रीवा के श्लेष्म से अपने उपजाऊ दिनों को कैसे पहचानें: सटीक अवलोकन के लिए टिप्स",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/zervix-en/zervix-en.component').then(m => m.ZervixHiComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "अंडाणु दान भारत में: अवसर, जोखिम और कानूनी प्रावधान",
        description: "अंडाणु दान भारत में: अवसर, जोखिम और कानूनी प्रावधान",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeHiComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "भारत में शुक्राणु बैंक की तुलना: लागत, उपचार और प्रतिफल",
        description: "भारत में शुक्राणु बैंक की तुलना: लागत, उपचार और प्रतिफल",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankHiComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "भारत के शीर्ष आईवीएफ क्लीनिकों की तुलना – खर्चे और सेवाएं",
        description: "भारत के शीर्ष आईवीएफ क्लीनिकों की तुलना – खर्चे और सेवाएं",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteHiComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "प्री-कम और गर्भधारण: क्या प्री-कम से गर्भवती हो सकते हैं? जोखिम कितना अधिक है?",
        description: "प्री-कम और गर्भधारण: क्या प्री-कम से गर्भवती हो सकते हैं? जोखिम कितना अधिक है?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateHiComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "सह-अभिभावकत्व 2024: आधुनिक पारिवारिक मॉडलों के बारे में आपको जो कुछ भी जानने की आवश्यकता है",
        description: "सह-अभिभावकत्व 2024: आधुनिक पारिवारिक मॉडलों के बारे में आपको जो कुछ भी जानने की आवश्यकता है",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingHiComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "ल्यूटियल फेज डिफिशिएंसी: कारण, लक्षण और प्रभावी उपचार विकल्प",
        description: "ल्यूटियल फेज डिफिशिएंसी: कारण, लक्षण और प्रभावी उपचार विकल्प",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeHiComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "लिनिया निग्रा: गर्भावस्था के दौरान गहरी रेखा के कारण और महत्व",
        description: "लिनिया निग्रा: गर्भावस्था के दौरान गहरी रेखा के कारण और महत्व",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/linea-en/linea-en.component').then(m => m.LineaNigraHiComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "किराए की कोख: कानूनी प्रश्न, लागत, पारंपरिक और गर्भधारण मॉडल",
        description: "किराए की कोख: कानूनी प्रश्न, लागत, पारंपरिक और गर्भधारण मॉडल",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/leihmut-en/leihmut-en.component').then(m => m.LeihmutHiComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "ओव्यूलेशन को समझना: अपनी प्रजनन क्षमता बढ़ाने की कुंजी",
        description: "ओव्यूलेशन को समझना: अपनी प्रजनन क्षमता बढ़ाने की कुंजी",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/ei-en/ei-en.component').then(m => m.EiHiComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "गर्भधारण: परिभाषा, समय और प्रारंभिक गर्भावस्था की महत्वपूर्ण जानकारी",
        description: "गर्भधारण: परिभाषा, समय और प्रारंभिक गर्भावस्था की महत्वपूर्ण जानकारी",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/einnistung-en/einnistung-en.component').then(m => m.EinnistungHiComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "भारत में प्रीइम्प्लांटेशन जेनेटिक डायग्नोसिस: प्रक्रिया, कानूनी पहलू और अनुभव",
        description: "भारत में प्रीइम्प्लांटेशन जेनेटिक डायग्नोसिस: प्रक्रिया, कानूनी पहलू और अनुभव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/PID/PID.component').then(m => m.PIDHiComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "शुक्राणु परीक्षण: पुरुष प्रजनन क्षमता की जाँच के लिए आवश्यक जानकारी",
        description: "शुक्राणु परीक्षण: पुरुष प्रजनन क्षमता की जाँच के लिए आवश्यक जानकारी",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammHiComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "घर में गर्भाधान: बिना सेक्स के गर्भवती होना",
        description: "घर में गर्भाधान: बिना सेक्स के गर्भवती होना",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/home-insemination/home-insemination.component').then(m => m.HomeInseminationHiComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "स्पर्म क्रैम्प: कारण, लक्षण और उपचार",
        description: "स्पर्म क्रैम्प: कारण, लक्षण और उपचार",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsHiComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "शुक्राणु संचय, अंडकोष का दर्द, नीले अंडकोष",
        description: "शुक्राणु संचय, अंडकोष का दर्द, नीले अंडकोष",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/samenstau-en/samenstau-en.component').then(m => m.SamenstauHiComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "शुक्राणु कितनी देर तक जीवित रहते हैं? शुक्राणु की गुणवत्ता में सुधार के तथ्य और सुझाव",
        description: "शुक्राणु कितनी देर तक जीवित रहते हैं? शुक्राणु की गुणवत्ता में सुधार के तथ्य और सुझाव",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienHiComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "क्लैपरस्टोर्क की किंवदंती: क्यों लाता है स्टोर्क बच्चे?",
        description: "क्लैपरस्टोर्क की किंवदंती: क्यों लाता है स्टोर्क बच्चे?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/klapper-en/klapper-en.component').then(m => m.KlapperHiComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "इन-विट्रो-निषेचन: व्यापक गाइड: लागत, चरण और अधिक",
        description: "इन-विट्रो-निषेचन: व्यापक गाइड: लागत, चरण और अधिक",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/ivf-en/ivf-en.component').then(m => m.IvfHiComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "इंट्रायूटेराइन इंसमिनेशन: प्रक्रिया, लागत और सफलता की संभावनाएं",
        description: "इंट्रायूटेराइन इंसमिनेशन: प्रक्रिया, लागत और सफलता की संभावनाएं",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/iui-en/iui-en.component').then(m => m.IuiHiComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "आईसीएसआई: पुरुष बांझपन के इलाज के लिए प्रजनन चिकित्सा में क्रांति",
        description: "आईसीएसआई: पुरुष बांझपन के इलाज के लिए प्रजनन चिकित्सा में क्रांति",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/icsi/icsi.component').then(m => m.IcsiHiComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "कृत्रिम गर्भाधान: तरीके, सफलता दर और लागत पर एक नजर",
        description: "कृत्रिम गर्भाधान: तरीके, सफलता दर और लागत पर एक नजर",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/kunst-en/kunst-en.component').then(m => m.KunstHiComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "इंट्रासर्विकल इंसैमिनेशन (आईसीआई): परिवार नियोजन की कोमल विधि",
        description: "इंट्रासर्विकल इंसैमिनेशन (आईसीआई): परिवार नियोजन की कोमल विधि",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/hi/ici-en/ici-en.component').then(m => m.IciHiComponent)
    },
  ];
  static readonly malayRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Soalan Lazim (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },

      loadComponent: () => import('./faq/ms/mal-faq.component').then(m => m.MalFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donasi Sel Telur di Indonesia dan Malaysia: Semua tentang Peluang, Risiko, dan Hukum",
        description: "Donasi Sel Telur di Indonesia dan Malaysia: Semua tentang Peluang, Risiko, dan Hukum",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeMsComponent)
    },

    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Pembiakan Buatan: Kos untuk Klinik Kesuburan, Bank Sperma, dan Bantuan Kewangan di Malaysia dan Indonesia",
        description: "Pembiakan Buatan: Kos untuk Klinik Kesuburan, Bank Sperma, dan Bantuan Kewangan di Malaysia dan Indonesia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/kostenAI/kostenAI.component').then(m => m.KostenaiMsComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Sindrom Ovari Polisistik (PCOS): Punca, Gejala dan Rawatan dalam Gambaran Keseluruhan",
        description: "Sindrom Ovari Polisistik (PCOS): Punca, Gejala dan Rawatan dalam Gambaran Keseluruhan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/pco-en/pco-en.component').then(m => m.PcoMsComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperma Tidak Divaksin vs. Sperma Divaksin: Fakta Mengenai Kualiti Sperma Selepas Vaksinasi COVID-19",
        description: "Sperma Tidak Divaksin vs. Sperma Divaksin: Fakta Mengenai Kualiti Sperma Selepas Vaksinasi COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/impf-en/impf-en.component').then(m => m.ImpfMsComponent)
    },
    {
      path: Paths.BlogMalaysia,
      data: {
        title: "Derma Sperma di Malaysia: Panduan, Perundangan, dan Mencari Penderma Sperma",
        description: "Derma Sperma di Malaysia: Panduan, Perundangan, dan Mencari Penderma Sperma.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/malaysia/malaysia.component').then(m => m.MalaysiaComponent)
    },
    {
      path: Paths.BlogIndonesien,
      data: {
        title: "Donor Sperma di Indonesia: Panduan, Aturan Hukum, dan Mencari Donor Sperma",
        description: "Donor Sperma di Indonesia: Panduan, Aturan Hukum, dan Mencari Donor Sperma.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/indonesien/indonesien.component').then(m => m.IndonesienComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Cara Semula Jadi untuk Melambatkan Menopaus: Kaedah dan Petua Pemakanan Berasaskan Sains",
        description: "Cara Semula Jadi untuk Melambatkan Menopaus: Kaedah dan Petua Pemakanan Berasaskan Sains",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/menopause-en/menopause-en.component').then(m => m.MenopauseMsComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Ketidaksuburan pada Lelaki: 10 Punca Paling Umum dan Cara Mengenalinya",
        description: "Ketidaksuburan pada Lelaki: 10 Punca Paling Umum dan Cara Mengenalinya",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtMsComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Penyakit Berjangkit Melalui Derma Sperma: Risiko dan Langkah-Langkah Perlindungan",
        description: "Penyakit Berjangkit Melalui Derma Sperma: Risiko dan Langkah-Langkah Perlindungan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/krank-en/krank-en.component').then(m => m.KrankMsComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Pembalikan Vasektomi: Peluang dan Risiko Refertilisasi",
        description: "Pembalikan Vasektomi: Peluang dan Risiko Refertilisasi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannMsComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Berhenti Mengambil Pil: Sebab, Kaedah, dan Apa Yang Berlaku Selepas Itu",
        description: "Berhenti Mengambil Pil: Sebab, Kaedah, dan Apa Yang Berlaku Selepas Itu",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenMsComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Pemulihan Kesuburan Selepas Sterilisasi: Pilihan dan Peluang Kejayaan",
        description: "Pemulihan Kesuburan Selepas Sterilisasi: Pilihan dan Peluang Kejayaan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauMsComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Mencari Penderma Sperma yang Sempurna: Panduan Lengkap untuk Wanita",
        description: "Mencari Penderma Sperma yang Sempurna: Panduan Lengkap untuk Wanita",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/findespender-en/findespender-en.component').then(m => m.FindeSpenderMsComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Bagaimana Menjadi Penderma Sperma? Panduan untuk Lelaki",
        description: "Bagaimana Menjadi Penderma Sperma? Panduan untuk Lelaki",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderMsComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Bagaimana Jangkitan Chlamydia Mempengaruhi Kesuburan Anda: Gejala, Risiko dan Langkah-langkah Perlindungan",
        description: "Bagaimana Jangkitan Chlamydia Mempengaruhi Kesuburan Anda: Gejala, Risiko dan Langkah-langkah Perlindungan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/clam-en/clam-en.component').then(m => m.ChlamydiaMsComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Bagaimana Menjadi Hamil Lebih Cepat: 10 Strategi Teruji untuk Kehamilan yang Berhasil",
        description: "Bagaimana Menjadi Hamil Lebih Cepat: 10 Strategi Teruji untuk Kehamilan yang Berhasil",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/schnell-en/schnell-en.component').then(m => m.SchnellMsComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Keguguran: Punca, Gejala, dan Bagaimana Anda Boleh Mendapatkan Bantuan",
        description: "Keguguran: Punca, Gejala, dan Bagaimana Anda Boleh Mendapatkan Bantuan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtMsComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Kelahiran Pra-matang: Punca, Gejala, dan Gambaran Keseluruhan Pilihan Rawatan Moden",
        description: "Kelahiran Pra-matang: Punca, Gejala, dan Gambaran Keseluruhan Pilihan Rawatan Moden",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtMsComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Bagaimana Lendir Serviks Mendedahkan Hari Subur Anda: Tips untuk Pemerhatian yang Tepat",
        description: "Bagaimana Lendir Serviks Mendedahkan Hari Subur Anda: Tips untuk Pemerhatian yang Tepat",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/zervix-en/zervix-en.component').then(m => m.ZervixMsComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Temu Bual: Pengalaman Saya Sebagai Anak Penderma dalam Keluarga LGBTQ+",
        description: "Temu Bual: Pengalaman Saya Sebagai Anak Penderma dalam Keluarga LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/emilia-en/emilia-en.component').then(m => m.EmiliaMsComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Pertumbuhan Payudara dan Sakit Semasa Kehamilan: Punca dan Petua",
        description: "Pertumbuhan Payudara dan Sakit Semasa Kehamilan: Punca dan Petua",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/brust-en/brust-en.component').then(m => m.BrustMsComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Pendarahan Semasa Kehamilan: Punca dan Perbezaan dengan Haid",
        description: "Pendarahan Semasa Kehamilan: Punca dan Perbezaan dengan Haid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/mensschw-en/mensschw-en.component').then(m => m.MensschwMsComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Ketidaksuburan pada Wanita: 10 Punca Utama dan Cara Mengesannya",
        description: "Ketidaksuburan pada Wanita: 10 Punca Utama dan Cara Mengesannya",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenMsComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Cecair Pra-ejakulasi dan Kehamilan: Bolehkah Saya Hamil dari Cecair Pra-ejakulasi? Apakah Risiko Sebenarnya?",
        description: "Cecair Pra-ejakulasi dan Kehamilan: Bolehkah Saya Hamil dari Cecair Pra-ejakulasi? Apakah Risiko Sebenarnya?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateMsComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Perbandingan Bank Sperma di Malaysia dan Indonesia: Kos, Rawatan, dan Pampasan",
        description: "Perbandingan Bank Sperma di Malaysia dan Indonesia: Kos, Rawatan, dan Pampasan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankMsComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Perbandingan Klinik Kesuburan Terbaik di Malaysia dan Indonesia – Kos dan Perkhidmatan",
        description: "Perbandingan Klinik Kesuburan Terbaik di Malaysia dan Indonesia – Kos dan Perkhidmatan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteMsComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Peningkatan LH dan Ovulasi: Panduan Anda untuk Memahami dan Memantau Kesuburan",
        description: "Peningkatan LH dan Ovulasi: Panduan Anda untuk Memahami dan Memantau Kesuburan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/lh/lh.component').then(m => m.LhMsComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Memahami Ovulasi: Kunci untuk Meningkatkan Kesuburan Anda",
        description: "Memahami Ovulasi: Kunci untuk Meningkatkan Kesuburan Anda",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/ei-en/ei-en.component').then(m => m.EiMsComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantasi: Definisi, Waktu, dan Informasi Penting tentang Kehamilan Dini",
        description: "Implantasi: Definisi, Waktu, dan Informasi Penting tentang Kehamilan Dini",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/einnistung-en/einnistung-en.component').then(m => m.EinnistungMsComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnosis Praimplantasi (PGD) di Indonesia: Prosedur, Aspek Hukum, dan Pengalaman",
        description: "Diagnosis Praimplantasi (PGD) di Indonesia: Prosedur, Aspek Hukum, dan Pengalaman",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/PID/PID.component').then(m => m.PIDMsComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogram: Semua yang Anda Perlu Tahu Mengenai Analisis Kesuburan Lelaki",
        description: "Spermiogram: Semua yang Anda Perlu Tahu Mengenai Analisis Kesuburan Lelaki",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammMsComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Punca dan Makna Garisan Gelap Semasa Kehamilan",
        description: "Linea Nigra: Punca dan Makna Garisan Gelap Semasa Kehamilan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/linea-en/linea-en.component').then(m => m.LineaNigraMsComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Berapa Lama Sperma Boleh Bertahan Hidup?",
        description: "Kehidupan sperma sering menjadi subjek perbincangan dengan banyak mitos dan salah faham.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienMsSComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Persenyawaan In Vitro (IVF): Panduan Lengkap: Kos, Langkah, dan Lain-lain",
        description: "Persenyawaan In Vitro (IVF): Panduan Lengkap: Kos, Langkah, dan Lain-lain",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/ivf-en/ivf-en.component').then(m => m.IvfMsComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Kekurangan Korpus Luteum: Punca, Gejala, dan Pilihan Rawatan Berkesan",
        description: "Kekurangan Korpus Luteum: Punca, Gejala, dan Pilihan Rawatan Berkesan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeMsComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Ibu Tumpang: Pertanyaan Hukum, Biaya, Model Tradisional dan Gestasional",
        description: "Ibu Tumpang: Pertanyaan Hukum, Biaya, Model Tradisional dan Gestasional",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/leihmut-en/leihmut-en.component').then(m => m.LeihmutMsComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Segala yang Anda Perlu Tahu Mengenai Model Keluarga Moden",
        description: "Co-Parenting 2024: Segala yang Anda Perlu Tahu Mengenai Model Keluarga Moden",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingMsComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Kekejangan Sperma: Punca, Gejala dan Rawatan",
        description: "Kekejangan Sperma: Punca, Gejala dan Rawatan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsMsComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Inseminasi di Rumah: Hamil Tanpa Seks",
        description: "Inseminasi di Rumah: Hamil Tanpa Seks",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/home-insemination/home-insemination.component').then(m => m.HomeInseminationMsComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Kesesakan Air Mani, Kesakitan Kavalier, Testis Sakit",
        description: "Kesesakan Air Mani, Kesakitan Kavalier, Testis Sakit",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/samenstau-en/samenstau-en.component').then(m => m.SamenstauMsComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Legenda Bangau Pembawa Anak: Mengapa Bangau Membawa Anak?",
        description: "Legenda Bangau Pembawa Anak: Mengapa Bangau Membawa Anak?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/klapper-en/klapper-en.component').then(m => m.KlapperMsComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Semua Mengenai Inseminasi Intrauterin (IUI): Proses, Kos, dan Kadar Kejayaan",
        description: "Semua Mengenai Inseminasi Intrauterin (IUI): Proses, Kos, dan Kadar Kejayaan",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/iui-en/iui-en.component').then(m => m.IuiMsComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Revolusi Perubatan Reproduktif untuk Merawat Kemandulan Lelaki",
        description: "ICSI: Revolusi Perubatan Reproduktif untuk Merawat Kemandulan Lelaki",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/icsi/icsi.component').then(m => m.IcsiMsComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Persenyawaan Buatan: Kaedah, Peluang Kejayaan, dan Kos",
        description: "Persenyawaan Buatan: Kaedah, Peluang Kejayaan, dan Kos",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/kunst-en/kunst-en.component').then(m => m.KunstMsComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminasi Intracervikal (ICI): Kaedah Lembut untuk Perancangan Keluarga",
        description: "Inseminasi Intracervikal (ICI): Kaedah Lembut untuk Perancangan Keluarga",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ms/ici-en/ici-en.component').then(m => m.IciMsComponent)
    },
  ];
  static readonly portugueseRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Perguntas Frequentes (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },

      loadComponent: () => import('./faq/pt/portu-faq.component').then(m => m.PortuFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Doação de Óvulos em Portugal: Tudo sobre oportunidades, riscos e legislação",
        description: "Doação de Óvulos em Portugal: Tudo sobre oportunidades, riscos e legislação",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/eizellenspende/eizellenspende.component').then(m => m.EizellenspendePtComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilidade Masculina: As 10 causas mais comuns e como identificá-las",
        description: "Infertilidade Masculina: As 10 causas mais comuns e como identificá-las",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtPtComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Fertilidade Assistida: Custos em Clínicas de Fertilidade, Bancos de Esperma e Apoio Financeiro em Portugal",
        description: "Fertilidade Assistida: Custos em Clínicas de Fertilidade, Bancos de Esperma e Apoio Financeiro em Portugal",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/kostenAI/kostenAI.component').then(m => m.KostenaiPtComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Bancos de Esperma em Portugal: Custos, Tratamentos e Compensações",
        description: "Bancos de Esperma em Portugal: Custos, Tratamentos e Compensações",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankPtComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Comparação das Principais Clínicas de Fertilidade em Portugal – Custos e Serviços",
        description: "Comparação das Principais Clínicas de Fertilidade em Portugal – Custos e Serviços",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasitePtComponent)
    },
    {
      path: Paths.BlogPortugal,
      data: {
        title: "Doação de esperma em Portugal: Guia, legislação e como encontrar um doador de esperma",
        description: "Doação de esperma em Portugal: Guia, legislação e como encontrar um doador de esperma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/portugal/portugal.component').then(m => m.PortugalComponent)
    },
    {
      path: Paths.BlogBrasilien,
      data: {
        title: "Doação de esperma no Brasil: Guia, legislação e como encontrar um doador de esperma",
        description: "Doação de esperma no Brasil: Guia, legislação e como encontrar um doador de esperma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/brasilien/brasilien.component').then(m => m.BrasilienComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnóstico Genético Pré-implantação (DGP) em Portugal: Procedimentos, Aspetos Legais e Experiências",
        description: "Diagnóstico Genético Pré-implantação (DGP) em Portugal: Procedimentos, Aspetos Legais e Experiências",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/PID/PID.component').then(m => m.PIDPortuComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Inseminação caseira: Engravidar sem sexo",
        description: "Inseminação caseira: Engravidar sem sexo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/home-insemination-portu/home-insemination-portu.component').then(m => m.HomeInseminationPortuComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Tudo o que você precisa saber sobre modelos familiares modernos",
        description: "Co-Parenting 2024: Tudo o que você precisa saber sobre modelos familiares modernos",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/co-paren-portu/co-paren-portu.component').then(m => m.CoParenPortuComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Gotas de prazer e gravidez: Posso engravidar com líquido seminal pré-ejaculatório? Qual é o risco real?",
        description: "Gotas de prazer e gravidez: Posso engravidar com líquido seminal pré-ejaculatório? Qual é o risco real?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/lusttropf-portu/lusttropf-portu.component').then(m => m.LusttropfPorComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestão de Esperma, Dor do Cavalheiro, Testículos Azuis",
        description: "Congestão de Esperma, Dor do Cavalheiro, Testículos Azuis",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/samenstau-portu/samenstau-portu.component').then(m => m.SamenstauPorComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Cãibras Seminais: Causas, Sintomas e Tratamento",
        description: "Cãibras Seminais: Causas, Sintomas e Tratamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/spermakrampf-portu/spermakrampf-portu.component').then(m => m.SpermakrampfPorComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        description: "Implantação: Definição, Momento e Informações Essenciais sobre o Início da Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/einnistung-portu/einnistung-portu.component').then(m => m.EinnistungPortuComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        description: "Inseminação Intracervical (ICI): O Método Suave para Planejamento Familiar",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/ici-portu/ici-portu.component').then(m => m.IciPortuComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fertilização In Vitro (FIV): Guia Completo: Custos, Etapas e Mais",
        description: "Fertilização In Vitro (FIV): Guia Completo: Custos, Etapas e Mais",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/ivf-portu/ivf-portu.component').then(m => m.IvfPortuComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestação de Substituição: Questões Legais, Custos, Modelos Tradicional e Gestacional",
        description: "Gestação de Substituição: Questões Legais, Custos, Modelos Tradicional e Gestacional",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/leihmut-portu/leihmut-portu.component').then(m => m.LeihmutPortuComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Síndrome dos Ovários Policísticos (SOP): Causas, Sintomas e Tratamentos em Resumo",
        description: "Síndrome dos Ovários Policísticos (SOP): Causas, Sintomas e Tratamentos em Resumo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/pco-portu/pco-portu.component').then(m => m.PcoPortuComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Espermograma: Tudo o que você precisa saber sobre a análise da fertilidade masculina",
        description: "Espermograma: Tudo o que você precisa saber sobre a análise da fertilidade masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/spermiogramm-portu/spermiogramm-portu.component').then(m => m.SpermiogrammPortuComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "A Origem da Lenda da Cegonha: Por que a Cegonha Traz os Bebês?",
        description: "A Origem da Lenda da Cegonha: Por que a Cegonha Traz os Bebês?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/klapper-portu/klapper-portu.component').then(m => m.KlapperPortuComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Como engravidar mais rápido: 10 estratégias comprovadas para uma gravidez bem-sucedida",
        description: "Como engravidar mais rápido: 10 estratégias comprovadas para uma gravidez bem-sucedida",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/schnell-portu/schnell-portu.component').then(m => m.SchnellPortuComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Doenças Transmissíveis na Doação de Sêmen: Riscos e Medidas de Proteção",
        description: "Doenças Transmissíveis na Doação de Sêmen: Riscos e Medidas de Proteção",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/krank-portu/krank-portu.component').then(m => m.KrankPortuComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        description: "Fertilização Artificial: Métodos, Taxas de Sucesso e Custos em Perspectiva",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/kunst-portu/kunst-portu.component').then(m => m.KunstPortuComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sangramentos na gravidez: Causas e diferenças para a menstruação",
        description: "Sangramentos na gravidez: Causas e diferenças para a menstruação",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/mensschw-portu/mensschw-portu.component').then(m => m.MensschwPortuComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Esperma não vacinado vs. Esperma vacinado: Fatos sobre a qualidade do esperma após a vacinação contra a COVID-19",
        description: "Esperma não vacinado vs. Esperma vacinado: Fatos sobre a qualidade do esperma após a vacinação contra a COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/impf-portu/impf-portu.component').then(m => m.ImpfPortuComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Compreendendo a Ovulação: A Chave para Aumentar sua Fertilidade",
        description: "Compreendendo a Ovulação: A Chave para Aumentar sua Fertilidade",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/ei-portu/ei-portu.component').then(m => m.EiPortuComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        description: "Tudo sobre Inseminação Intrauterina (IIU): Procedimento, Custos e Taxas de Sucesso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/iui-portu/iui-portu.component').then(m => m.IuiPortuComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        description: "ICSI: A Revolução da Medicina Reprodutiva no Tratamento da Infertilidade Masculina",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/icsi/icsi.component').then(m => m.IcsiPortuComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento de LH e Ovulação: Seu Guia para Compreender e Monitorar a Fertilidade",
        description: "Aumento de LH e Ovulação: Seu Guia para Compreender e Monitorar a Fertilidade",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/lh/lh.component').then(m => m.LhPortuComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Como as Infecções por Clamídia Afetam Sua Fertilidade: Sintomas, Riscos e Medidas de Proteção",
        description: "Como as Infecções por Clamídia Afetam Sua Fertilidade: Sintomas, Riscos e Medidas de Proteção",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/clam-en/clam-en.component').then(m => m.ChlamydiaPortuComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Entrevista: Minhas experiências como filho de doador em uma família LGBTQ+",
        description: "Entrevista: Minhas experiências como filho de doador em uma família LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/emilia-en/emilia-en.component').then(m => m.EmiliaPortuComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto Espontâneo: Causas, Sintomas e Como Encontrar Ajuda",
        description: "Aborto Espontâneo: Causas, Sintomas e Como Encontrar Ajuda",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtPortuComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: causas, sintomas e uma visão geral das opções modernas de tratamento",
        description: "Parto prematuro: causas, sintomas e uma visão geral das opções modernas de tratamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtPortuComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Reversão da Esterilização: Opções e Taxas de Sucesso",
        description: "Reversão da Esterilização: Opções e Taxas de Sucesso",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauPortuComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Reversão de Vasectomia: Oportunidades e Riscos da Refertilização",
        description: "Reversão de Vasectomia: Oportunidades e Riscos da Refertilização",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannPortuComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crescimento dos Seios e Dores Durante a Gravidez: Causas e Dicas",
        description: "Crescimento dos Seios e Dores Durante a Gravidez: Causas e Dicas",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/brust-en/brust-en.component').then(m => m.BrustPortuComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Como encontrar o doador de esperma perfeito: Um guia completo para mulheres",
        description: "Como encontrar o doador de esperma perfeito: Um guia completo para mulheres",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/findespender-en/findespender-en.component').then(m => m.FindeSpenderPortuComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Como se tornar um doador de sêmen? Um guia para homens",
        description: "Como se tornar um doador de sêmen? Um guia para homens",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderPortuComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilidade nas Mulheres: As 10 Causas Mais Comuns e Como Reconhecê-las",
        description: "Infertilidade nas Mulheres: As 10 Causas Mais Comuns e Como Reconhecê-las",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenPortuComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quanto Tempo os Espermatozoides Sobrevivem?",
        description: "Descubra Quanto Tempo os Espermatozoides Sobrevivem e Melhore a Qualidade do Seu Esperma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienPortuComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Parar de Tomar a Pílula: Motivos, Métodos e o que Acontece Depois",
        description: "Parar de Tomar a Pílula: Motivos, Métodos e o que Acontece Depois",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenPortuComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Como o muco cervical revela seus dias férteis: dicas para uma observação precisa",
        description: "Como o muco cervical revela seus dias férteis: dicas para uma observação precisa",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/zervix-en/zervix-en.component').then(m => m.ZervixPortuComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Métodos Naturais para Atrasar a Menopausa: Métodos Baseados em Evidências e Dicas Nutricionais",
        description: "Métodos Naturais para Atrasar a Menopausa: Métodos Baseados em Evidências e Dicas Nutricionais",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/menopause-en/menopause-en.component').then(m => m.MenopausePortuComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        description: "Linha Negra: Causas e Significado da Linha Escura Durante a Gravidez",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/linea-en/linea-en.component').then(m => m.LineaNigraPortuComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Deficiência da Fase Lútea: Causas, Sintomas e Opções de Tratamento Eficazes",
        description: "Deficiência da Fase Lútea: Causas, Sintomas e Opções de Tratamento Eficazes",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pt/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächePortuComponent)
    },
  ];
  static readonly russianRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Часто задаваемые вопросы (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },

      loadComponent: () => import('./faq/ru/ru-faq.component').then(m => m.RuFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Как хламидийные инфекции влияют на вашу фертильность: симптомы, риски и меры предосторожности",
        description: "Как хламидийные инфекции влияют на вашу фертильность: симптомы, риски и меры предосторожности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/clam-en/clam-en.component').then(m => m.ChlamydiaRuComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Донорство яйцеклеток в России: возможности, риски и законы",
        description: "Донорство яйцеклеток в России: возможности, риски и законы",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeRuComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Бесплодие у мужчин: 10 самых частых причин и как их распознать",
        description: "Бесплодие у мужчин: 10 самых частых причин и как их распознать",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtRuComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Искусственное оплодотворение: Стоимость услуг репродуктивных клиник, банков спермы и возможная финансовая поддержка в России",
        description: "Искусственное оплодотворение: Стоимость услуг репродуктивных клиник, банков спермы и возможная финансовая поддержка в России",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/kostenAI/kostenAI.component').then(m => m.KostenaiRuComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Рефертильзация: возможности и риски обращения вазэктомии",
        description: "Рефертильзация: возможности и риски обращения вазэктомии",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannRuComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Сравнение российских банков спермы: стоимость, процедуры и компенсации",
        description: "Сравнение российских банков спермы: стоимость, процедуры и компенсации",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankRuComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Сравнение лучших клиник по лечению бесплодия в России – стоимость и услуги",
        description: "Сравнение лучших клиник по лечению бесплодия в России – стоимость и услуги",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteRuComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Восстановление фертильности после стерилизации: возможности и шансы на успех",
        description: "Восстановление фертильности после стерилизации: возможности и шансы на успех",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauRuComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Бесплодие у женщин: 10 самых частых причин и как их распознать",
        description: "Бесплодие у женщин: 10 самых частых причин и как их распознать",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenRuComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Отмена противозачаточных таблеток: причины, методы и что происходит после этого",
        description: "Отмена противозачаточных таблеток: причины, методы и что происходит после этого",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenRuComponent)
    },
    {
      path: Paths.BlogRussland,
      data: {
        title: "Донорство спермы в России: руководство, законодательство и поиск донора спермы",
        description: "Донорство спермы в России: руководство, законодательство и поиск донора спермы",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/russland/russland.component').then(m => m.RusslandComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Найти идеального донора спермы: Полное руководство для женщин",
        description: "Найти идеального донора спермы: Полное руководство для женщин",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/findespender-en/findespender-en.component').then(m => m.FindeSpenderRuComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Как стать донором спермы? Руководство для мужчин",
        description: "Как стать донором спермы? Руководство для мужчин",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderRuComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Выкидыш: причины, симптомы и как найти помощь",
        description: "Выкидыш: причины, симптомы и как найти помощь",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtRuComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Рост груди и боли во время беременности: причины и советы",
        description: "Рост груди и боли во время беременности: причины и советы",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/brust-en/brust-en.component').then(m => m.BrustRuComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Предимплантационное генетическое тестирование (ПГТ) в России: Процедуры, юридические аспекты и текущие реалии",
        description: "Предимплантационное генетическое тестирование (ПГТ) в России: Процедуры, юридические аспекты и текущие реалии",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/PID/PID.component').then(m => m.PIDRuComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Домашнее оплодотворение: забеременеть без секса",
        description: "Домашнее оплодотворение: забеременеть без секса",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/h-i-ru/h-i-ru.component').then(m => m.HIRuComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Современные семейные модели в России: Совместное родительство 2024",
        description: "Современные семейные модели в России: Совместное родительство 2024",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/co-pa-ru/co-pa-ru.component').then(m => m.CoPaRuComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Предэякулят и беременность: можно ли забеременеть от предэякулята? Каков реальный риск?",
        description: "Предэякулят и беременность: можно ли забеременеть от предэякулята? Каков реальный риск?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/lusttropfen-ru/lusttropfen-ru.component').then(m => m.LusttropfenRuComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Преждевременные роды: причины, симптомы и обзор современных методов лечения",
        description: "Преждевременные роды: причины, симптомы и обзор современных методов лечения",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtRuComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Сперматостаз, кавалерская боль, синий яичек",
        description: "Сперматостаз, кавалерская боль, синий яичек",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/samenstau-ru/samenstau-ru.component').then(m => m.SamenstauRuComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Спермакрабы | RattleStork",
        description: "Спермакрабы",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/spermakrampf-ru/spermakrampf-ru.component').then(m => m.SpermakrampfRuComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Черная линия: Причины и значение тёмной линии во время беременности",
        description: "Черная линия: Причины и значение тёмной линии во время беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/linea-en/linea-en.component').then(m => m.LineaNigraRuComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Лютеиновая недостаточность: причины, симптомы и эффективные методы лечения",
        description: "Лютеиновая недостаточность: причины, симптомы и эффективные методы лечения",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeRuComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Имплантация: Определение, сроки и важная информация о ранней беременности",
        description: "Имплантация: Определение, сроки и важная информация о ранней беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/einnistung-ru/einnistung-ru.component').then(m => m.EinnistungRuComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Интрацервикальная инсеминация (ИЦИ): щадящий метод планирования семьи",
        description: "Интрацервикальная инсеминация (ИЦИ): щадящий метод планирования семьи",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/ici-ru/ici-ru.component').then(m => m.IciRuComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Экстракорпоральное оплодотворение (ЭКО): Полное руководство: стоимость, этапы и многое другое",
        description: "Экстракорпоральное оплодотворение (ЭКО): Полное руководство: стоимость, этапы и многое другое",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/ivf-ru/ivf-ru.component').then(m => m.IvfRuComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Суррогатное материнство: правовые вопросы, стоимость, традиционные и гестационные модели",
        description: "Суррогатное материнство: правовые вопросы, стоимость, традиционные и гестационные модели",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/leihmut-ru/leihmut-ru.component').then(m => m.LeihmutRuComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Интервью: Мой опыт как ребенка донора в семье LGBTQ+",
        description: "Интервью: Мой опыт как ребенка донора в семье LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/emilia-en/emilia-en.component').then(m => m.EmiliaRuComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Синдром поликистозных яичников (СПКЯ): Причины, симптомы и лечение",
        description: "Синдром поликистозных яичников (СПКЯ): Причины, симптомы и лечение",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/pco-ru/pco-ru.component').then(m => m.PcoRuComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Спермограмма: Важная информация об анализе мужской репродуктивной функции",
        description: "Спермограмма: Важная информация об анализе мужской репродуктивной функции",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/spermiogramm-ru/spermiogramm-ru.component').then(m => m.SpermiogrammRuComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "История и традиции аиста: Почему аист приносит детей?",
        description: "История и традиции аиста: Почему аист приносит детей?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/klapper-ru/klapper-ru.component').then(m => m.KlapperRuComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Как быстрее забеременеть: 10 проверенных стратегий для успешной беременности",
        description: "Как быстрее забеременеть: 10 проверенных стратегий для успешной беременности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/schnell-ru/schnell-ru.component').then(m => m.SchnellRuComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Инфекционные болезни при донорстве спермы: риски и меры защиты",
        description: "Инфекционные болезни при донорстве спермы: риски и меры защиты",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/krank-ru/krank-ru.component').then(m => m.KrankRuComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Искусственное оплодотворение: методы, шансы на успех и затраты",
        description: "Искусственное оплодотворение: методы, шансы на успех и затраты",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/kunst-ru/kunst-ru.component').then(m => m.KunstRuComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Как долго живут сперматозоиды?",
        description: "Узнайте, сколько времени сперматозоиды выживают и как улучшить качество вашего спермы",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienRuComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Кровотечения при беременности: причины и отличия от менструации",
        description: "Кровотечения при беременности: причины и отличия от менструации",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/mensschw-ru/mensschw-ru.component').then(m => m.MensschwRuComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Невакцинированная сперма vs. Вакцинированная сперма: Факты о качестве спермы после вакцинации от COVID-19",
        description: "Невакцинированная сперма vs. Вакцинированная сперма: Факты о качестве спермы после вакцинации от COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/impf-ru/impf-ru.component').then(m => m.ImpfRuComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Понимание овуляции: ключ к повышению вашей фертильности",
        description: "Понимание овуляции: ключ к повышению вашей фертильности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/ei-ru/ei-ru.component').then(m => m.EiRuComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Все о внутриматочной инсеминации (ВМИ): процесс, стоимость и шансы на успех",
        description: "Все о внутриматочной инсеминации (ВМИ): процесс, стоимость и шансы на успех",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/iui-ru/iui-ru.component').then(m => m.IuiRuComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ИКСИ: Революция в репродуктивной медицине для лечения мужского бесплодия",
        description: "ИКСИ: Революция в репродуктивной медицине для лечения мужского бесплодия",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/icsi/icsi.component').then(m => m.IcsiRuComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Как цервикальная слизь раскрывает ваши фертильные дни: советы по точному наблюдению",
        description: "Как цервикальная слизь раскрывает ваши фертильные дни: советы по точному наблюдению",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/zervix-en/zervix-en.component').then(m => m.ZervixRuComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Естественные способы задержки менопаузы: Научно обоснованные методы и советы по питанию",
        description: "Естественные способы задержки менопаузы: Научно обоснованные методы и советы по питанию",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/menopause-en/menopause-en.component').then(m => m.MenopauseRuComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Повышение уровня ЛГ и овуляция: Ваш гид по пониманию и мониторингу фертильности",
        description: "Повышение уровня ЛГ и овуляция: Ваш гид по пониманию и мониторингу фертильности",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/ru/lh/lh.component').then(m => m.LhRuComponent)
    }
  ];
  static readonly italianRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Frequently Asked Questions (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/it/it-faq.component').then(m => m.ItFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donazione di ovuli in Italia: Tutto su opportunità, rischi e leggi",
        description: "Donazione di ovuli in Italia: Tutto su opportunità, rischi e leggi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeItComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Libretto di Gravidanza: Un compagno indispensabile durante la gravidanza",
        description: "Libretto di Gravidanza: Un compagno indispensabile durante la gravidanza",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/mutterpass/mutterpass.component').then(m => m.MutterpassItComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Infertilità maschile: Le 10 cause più comuni e come riconoscerle",
        description: "Infertilità maschile: Le 10 cause più comuni e come riconoscerle",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtItComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Fecondazione Assistita: Panoramica dei Costi per Cliniche della Fertilità, Banche del Seme e Agevolazioni Finanziarie in Italia",
        description: "Fecondazione Assistita: Panoramica dei Costi per Cliniche della Fertilità, Banche del Seme e Agevolazioni Finanziarie in Italia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/kostenAI/kostenAI.component').then(m => m.KostenaiItComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Banche del Seme Italiane a Confronto: Costi, Trattamenti e Compensi",
        description: "Banche del Seme Italiane a Confronto: Costi, Trattamenti e Compensi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/kostenaufwandbank copy/kostenaufwandbank.component').then(m => m.KostenaufwandbankItComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Confronto delle migliori cliniche per la fertilità in Italia – Costi e servizi",
        description: "Confronto delle migliori cliniche per la fertilità in Italia – Costi e servizi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteItComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnosi Genetica Preimpianto (PGD) in Italia: Procedure, Aspetti Legali ed Esperienze",
        description: "Diagnosi Genetica Preimpianto (PGD) in Italia: Procedure, Aspetti Legali ed Esperienze",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/PID/PID.component').then(m => m.PIDItComponent)
    },
    {
      path: Paths.BlogItalien,
      data: {
        title: "Donazione di sperma in Italia: Guida, normativa e ricerca di un donatore di sperma",
        description: "Donazione di sperma in Italia: Guida, normativa e ricerca di un donatore di sperma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/italien/italien.component').then(m => m.ItalienComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Tutto ciò che devi sapere sui modelli di famiglie moderne",
        description: "Co-Parenting 2024: Tutto ciò che devi sapere sui modelli di famiglie moderne",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/co-parenting/co-parenting.component').then(m => m.CoParentingItComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Comprendere l'ovulazione: La chiave per aumentare la tua fertilità",
        description: "Comprendere l'ovulazione: La chiave per aumentare la tua fertilità",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/ei/ei.component').then(m => m.EiItComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Annidamento: Definizione, tempistiche e informazioni essenziali sulla gravidanza precoce",
        description: "Annidamento: Definizione, tempistiche e informazioni essenziali sulla gravidanza precoce?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/einnistung/einnistung.component').then(m => m.EinnistungItComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Inseminazione artificiale fatta in casa",
        description: "Inseminazione artificiale fatta in casa",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/heiminsemination/heiminsemination.component').then(m => m.HeiminseminationItComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminazione Intracervicale (ICI): Il Metodo Delicato per la Pianificazione Familiare",
        description: "Inseminazione Intracervicale (ICI): Il Metodo Delicato per la Pianificazione Familiare",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/ici/ici.component').then(m => m.IciItComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Sperma Non Vaccinato vs. Sperma Vaccinato: Fatti sulla Qualità dello Sperma dopo la Vaccinazione COVID-19",
        description: "Sperma Non Vaccinato vs. Sperma Vaccinato: Fatti sulla Qualità dello Sperma dopo la Vaccinazione COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/impf/impf.component').then(m => m.ImpfItComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Tutto sull'Inseminazione Intrauterina (IUI): Procedura, Costi e Probabilità di Successo",
        description: "Tutto sull'Inseminazione Intrauterina (IUI): Procedura, Costi e Probabilità di Successo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/iui/iui.component').then(m => m.IuiItComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Fecondazione In Vitro (FIV): Guida Completa: Costi, Fasi e Altro",
        description: "Fecondazione In Vitro (FIV): Guida Completa: Costi, Fasi e Altro",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/ivf/ivf.component').then(m => m.IvfItComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Malattie trasmissibili nella donazione di sperma: rischi e misure di protezione",
        description: "Malattie trasmissibili nella donazione di sperma: rischi e misure di protezione",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/krankheiten/krankheiten.component').then(m => m.KrankheitenItComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Fecondazione artificiale: Metodi, probabilità di successo e costi in sintesi",
        description: "Fecondazione artificiale: Metodi, probabilità di successo e costi in sintesi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/kunstl/kunstl.component').then(m => m.KunstlItComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Gestazione per Altri: Questioni Legali, Costi, Modelli Tradizionali e Gestazionali",
        description: "Gestazione per Altri: Questioni Legali, Costi, Modelli Tradizionali e Gestazionali",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/leihmut/leihmut.component').then(m => m.LeihmutItComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Gocce di piacere e gravidanza: Posso rimanere incinta con le gocce di piacere? Qual è il vero rischio?",
        description: "Gocce di piacere e gravidanza: Posso rimanere incinta con le gocce di piacere? Qual è il vero rischio?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/lusttropfen/lusttropfen.component').then(m => m.LusttropfenItComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Sanguinamenti in gravidanza: cause e differenze con le mestruazioni",
        description: "Sanguinamenti in gravidanza: cause e differenze con le mestruazioni",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/mensschwang/mensschwang.component').then(m => m.MensschwangItComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Sindrome dell'Ovaio Policistico (PCOS): Cause, Sintomi e Trattamenti in Sintesi",
        description: "Sindrome dell'Ovaio Policistico (PCOS): Cause, Sintomi e Trattamenti in Sintesi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/pco/pco.component').then(m => m.PCOItComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Crampi Spermatici: Cause, Sintomi e Trattamento",
        description: "Crampi Spermatici: Cause, Sintomi e Trattamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfItComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Come rimanere incinta più velocemente: 10 strategie comprovate per una gravidanza di successo",
        description: "Come rimanere incinta più velocemente: 10 strategie comprovate per una gravidanza di successo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangItComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Congestione Seminale, Dolore Testicolare, Testicoli Blu",
        description: "Congestione Seminale, Dolore Testicolare, Testicoli Blu",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/samenstau/samenstau.component').then(m => m.SamenstauItComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogramma: Tutto quello che c'è da sapere sull'analisi della fertilità maschile",
        description: "Spermiogramma: Tutto quello che c'è da sapere sull'analisi della fertilità maschile",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/spermiogramm/spermiogramm.component').then(m => m.SpermiogramItComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "La Leggenda della Cicogna: Perché la Cicogna Porta i Bambini?",
        description: "La Leggenda della Cicogna: Perché la Cicogna Porta i Bambini?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/klapper/klapper.component').then(m => m.KlapperItComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: La Rivoluzione della Medicina Riproduttiva per il Trattamento dell'Infertilità Maschile",
        description: "ICSI: La Rivoluzione della Medicina Riproduttiva per il Trattamento dell'Infertilità Maschile",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/icsi/icsi.component').then(m => m.IcsiItComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Aumento dell'LH e ovulazione: La tua guida per comprendere e monitorare la fertilità",
        description: "Aumento dell'LH e ovulazione: La tua guida per comprendere e monitorare la fertilità",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/lh/lh.component').then(m => m.LhItComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Come le infezioni da clamidia influenzano la tua fertilità: sintomi, rischi e misure di protezione",
        description: "Come le infezioni da clamidia influenzano la tua fertilità: sintomi, rischi e misure di protezione",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/clam/clam.component').then(m => m.ClamItComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Intervista: La mia esperienza come figlia di donatore in una famiglia LGBTQ+",
        description: "Intervista: La mia esperienza come figlia di donatore in una famiglia LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/emilia/emilia.component').then(m => m.EmiliaItComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Aborto spontaneo: cause, sintomi e come trovare aiuto",
        description: "Aborto spontaneo: cause, sintomi e come trovare aiuto",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtItComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Parto prematuro: cause, sintomi e panoramica delle moderne opzioni di trattamento",
        description: "Parto prematuro: cause, sintomi e panoramica delle moderne opzioni di trattamento",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/fruehgeb/fruehgeb.component').then(m => m.ClamItComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Riferilizzazione dopo sterilizzazione: possibilità e tassi di successo",
        description: "Riferilizzazione dopo sterilizzazione: possibilità e tassi di successo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/referti-frau/referti-frau.component').then(m => m.RefertiFrauItComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Riferitilizzazione: Opportunità e Rischi della Reversione di una Vasectomia",
        description: "Riferitilizzazione: Opportunità e Rischi della Reversione di una Vasectomia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/referti-mann/referti-mann.component').then(m => m.RefertiMannItComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Crescita e Dolori al Seno durante la Gravidanza: Cause e Consigli",
        description: "Crescita e Dolori al Seno durante la Gravidanza: Cause e Consigli",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangItComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Trovare il donatore di sperma perfetto: La guida completa per le donne",
        description: "Trovare il donatore di sperma perfetto: La guida completa per le donne",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/findespender/findespender.component').then(m => m.FindespenderItComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Come diventare donatore di sperma? Una guida per gli uomini",
        description: "Come diventare donatore di sperma? Una guida per gli uomini",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/werdespender/werdespender.component').then(m => m.WerdespenderItComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Infertilità femminile: Le 10 cause più comuni e come riconoscerle",
        description: "Infertilità femminile: Le 10 cause più comuni e come riconoscerle",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenItComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Quanto tempo sopravvivono gli spermatozoi",
        description: "Durata di vita degli spermatozoi: Fatti essenziali e consigli per migliorare la qualità",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienItComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Smettere di Prendere la Pillola: Motivi, Metodi e Cosa Succede Dopo",
        description: "Smettere di Prendere la Pillola: Motivi, Metodi e Cosa Succede Dopo",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenItComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Come il muco cervicale rivela i tuoi giorni fertili: consigli per un'osservazione accurata",
        description: "Come il muco cervicale rivela i tuoi giorni fertili: consigli per un'osservazione accurata",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/zervix/zervix.component').then(m => m.ZervixItComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Metodi naturali per ritardare la menopausa: Approcci scientifici e consigli nutrizionali",
        description: "Metodi naturali per ritardare la menopausa: Approcci scientifici e consigli nutrizionali",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/menopause/menopause.component').then(m => m.MenopauseItComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Cause e significato della linea scura durante la gravidanza",
        description: "Linea Nigra: Cause e significato della linea scura durante la gravidanza",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraItComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Insufficienza del corpo luteo: cause, sintomi e opzioni di trattamento efficaci",
        description: "Insufficienza del corpo luteo: cause, sintomi e opzioni di trattamento efficaci",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/it/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeItComponent)
    },
  ];
  static readonly dutchRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Frequently Asked Questions (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/nl/nl-faq.component').then(m => m.NlFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogNiederlande,
      data: {
        title: "Spermadonatie in Nederland: Gids, wetgeving en zoektocht naar een spermadonor",
        description: "Spermadonatie in Nederland: Gids, wetgeving en zoektocht naar een spermadonor",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/niederlande/niederlande.component').then(m => m.NiederlandeComponent)
    },
    {
      path: Paths.BlogMutterpass,
      data: {
        title: "Zwangerschapsdossier: Een Essentieel Document Voor Jouw Zwangerschap in Nederland",
        description: "Zwangerschapsdossier: Een Essentieel Document Voor Jouw Zwangerschap in Nederland",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/mutterpass/mutterpass.component').then(m => m.MutterpassNlComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Onvruchtbaarheid bij mannen: De 10 meest voorkomende oorzaken en hoe je ze kunt herkennen",
        description: "Onvruchtbaarheid bij mannen: De 10 meest voorkomende oorzaken en hoe je ze kunt herkennen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtNlComponent)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Eiceldonatie in Nederland: Alles over kansen, risico's en wetten",
        description: "Eiceldonatie in Nederland: Alles over kansen, risico's en wetten",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeNlComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Nederlandse Spermabanken in Vergelijking: Kosten, Behandelingen en Vergoedingen",
        description: "Nederlandse Spermabanken in Vergelijking: Kosten, Behandelingen en Vergoedingen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/kostenaufwandbank copy/kostenaufwandbank.component').then(m => m.KostenaufwandbankNlComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Vergelijking van de beste vruchtbaarheidsklinieken in Nederland – Kosten en Diensten",
        description: "Vergelijking van de beste vruchtbaarheidsklinieken in Nederland – Kosten en Diensten",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteNlComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Pre-implantatie Genetische Diagnostiek (PGD) in Nederland: Procedures, Juridische Aspecten en Ervaringen",
        description: "Pre-implantatie Genetische Diagnostiek (PGD) in Nederland: Procedures, Juridische Aspecten en Ervaringen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/PID/PID.component').then(m => m.PIDNlComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Alles wat je moet weten over moderne gezinsmodellen",
        description: "Co-Parenting 2024: Alles wat je moet weten over moderne gezinsmodellen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/co-parenting/co-parenting.component').then(m => m.CoParentingNlComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Ovulatie begrijpen: De sleutel tot het verhogen van uw vruchtbaarheid",
        description: "Ovulatie begrijpen: De sleutel tot het verhogen van uw vruchtbaarheid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/ei/ei.component').then(m => m.EiNlComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Innesteling: Definitie, Tijdstip en Essentiële Informatie over Vroege Zwangerschap",
        description: "Innesteling: Definitie, Tijdstip en Essentiële Informatie over Vroege Zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/einnistung/einnistung.component').then(m => m.EinnistungNlComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Zelfinseminatie: Zwanger worden zonder seks",
        description: "Zelfinseminatie: Zwanger worden zonder seks",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/heiminsemination/heiminsemination.component').then(m => m.HeiminseminationNlComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Intracervicale inseminatie (ICI): De zachte methode voor gezinsplanning",
        description: "Intracervicale inseminatie (ICI): De zachte methode voor gezinsplanning",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/ici/ici.component').then(m => m.IciNlComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Ongevaccineerd Sperma vs. Gevaccineerd Sperma: Feiten over Spermakwaliteit na de COVID-19-vaccinatie",
        description: "Ongevaccineerd Sperma vs. Gevaccineerd Sperma: Feiten over Spermakwaliteit na de COVID-19-vaccinatie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/impf/impf.component').then(m => m.ImpfNlComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Alles over Intra-uteriene Inseminatie (IUI): Proces, Kosten en Slaagkansen",
        description: "Alles over Intra-uteriene Inseminatie (IUI): Proces, Kosten en Slaagkansen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/iui/iui.component').then(m => m.IuiNlComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "In-vitrofertilisatie (IVF): Uitgebreide gids: Kosten, stappen en meer",
        description: "In-vitrofertilisatie (IVF): Uitgebreide gids: Kosten, stappen en meer",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/ivf/ivf.component').then(m => m.IvfNlComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Übertragbare Krankheiten bei der Samenspende | RattleStork",
        description: "Übertragbare Krankheiten bei der Samenspende",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/krankheiten/krankheiten.component').then(m => m.KrankheitenNlComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Overdraagbare ziekten bij spermadonatie: risico's en beschermingsmaatregelen",
        description: "Overdraagbare ziekten bij spermadonatie: risico's en beschermingsmaatregelen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/kunstl/kunstl.component').then(m => m.KunstlNlComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Draagmoederschap: Juridische vragen, kosten, traditionele en gestationele modellen",
        description: "Draagmoederschap: Juridische vragen, kosten, traditionele en gestationele modellen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/leihmut/leihmut.component').then(m => m.LeihmutNlComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Voorvocht en Zwangerschap: Kan ik zwanger worden van voorvocht? Hoe groot is het risico echt?",
        description: "Voorvocht en Zwangerschap: Kan ik zwanger worden van voorvocht? Hoe groot is het risico echt?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/lusttropfen/lusttropfen.component').then(m => m.LusttropfenNlComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Zwangerschapsbloedingen: Oorzaken en Verschillen met Menstruatie",
        description: "Zwangerschapsbloedingen: Oorzaken en Verschillen met Menstruatie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/mensschwang/mensschwang.component').then(m => m.MensschwangNlComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polycysteus Ovarium Syndroom (PCOS): Oorzaken, Symptomen en Behandeling in Overzicht",
        description: "Polycysteus Ovarium Syndroom (PCOS): Oorzaken, Symptomen en Behandeling in Overzicht",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/pco/pco.component').then(m => m.PCONlComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Spermakrampen: Oorzaken, Symptomen en Behandeling",
        description: "Spermakrampen: Oorzaken, Symptomen en Behandeling",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/spermakrampf/spermakrampf.component').then(m => m.SpermakrampfNlComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Hoe je sneller zwanger wordt: 10 bewezen strategieën voor een succesvolle zwangerschap",
        description: "Hoe je sneller zwanger wordt: 10 bewezen strategieën voor een succesvolle zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/schnellschwang/schnellschwang.component').then(m => m.SchnellSchwangNlComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Zaadstuwing, Testikelpijn, Blauwe Ballen",
        description: "Zaadstuwing, Testikelpijn, Blauwe Ballen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/samenstau/samenstau.component').then(m => m.SamenstauNlComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Spermiogram: Alles over de Analyse van Mannelijke Vruchtbaarheid",
        description: "Spermiogram: Alles over de Analyse van Mannelijke Vruchtbaarheid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/spermiogramm/spermiogramm.component').then(m => m.SpermiogramNlComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "De legende van de ooievaar: Waarom brengt de ooievaar kinderen?",
        description: "De legende van de ooievaar: Waarom brengt de ooievaar kinderen?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/klapper/klapper.component').then(m => m.KlapperNlComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: De Revolutie van de Reproductieve Geneeskunde voor de Behandeling van Mannelijke Onvruchtbaarheid",
        description: "ICSI: De Revolutie van de Reproductieve Geneeskunde voor de Behandeling van Mannelijke Onvruchtbaarheid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/icsi/icsi.component').then(m => m.IcsiNlComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH-stijging en Ovulatie: Uw Gids voor het Begrijpen en Monitoren van Vruchtbaarheid",
        description: "LH-stijging en Ovulatie: Uw Gids voor het Begrijpen en Monitoren van Vruchtbaarheid",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/lh/lh.component').then(m => m.LhNlComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Hoe Chlamydia-infecties uw vruchtbaarheid beïnvloeden: Symptomen, risico's en beschermende maatregelen",
        description: "Hoe Chlamydia-infecties uw vruchtbaarheid beïnvloeden: Symptomen, risico's en beschermende maatregelen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/clam/clam.component').then(m => m.ClamNlComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Interview: Mijn ervaringen als donorkind in een LGBTQ+-gezin",
        description: "Interview: Mijn ervaringen als donorkind in een LGBTQ+-gezin",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/emilia/emilia.component').then(m => m.EmiliaNlComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Miskraam: Oorzaken, Symptomen en hoe u hulp kunt vinden",
        description: "Miskraam: Oorzaken, Symptomen en hoe u hulp kunt vinden",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/fehlgeburt/fehlgeburt.component').then(m => m.FehlgeburtNlComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Vroeggeboorte: oorzaken, symptomen en overzicht van moderne behandelingsopties",
        description: "Vroeggeboorte: oorzaken, symptomen en overzicht van moderne behandelingsopties",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/fruehgeb/fruehgeb.component').then(m => m.ClamNlComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilisatie na sterilisatie: Mogelijkheden en slaagkansen",
        description: "Refertilisatie na sterilisatie: Mogelijkheden en slaagkansen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/referti-frau/referti-frau.component').then(m => m.RefertiFrauNlComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Reversibilisatie: Kansen en Risico's van het Omkeren van een Vasectomie",
        description: "Reversibilisatie: Kansen en Risico's van het Omkeren van een Vasectomie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/referti-mann/referti-mann.component').then(m => m.RefertiMannNlComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Borstgroei en Pijn tijdens de Zwangerschap: Oorzaken en Tips",
        description: "Borstgroei en Pijn tijdens de Zwangerschap: Oorzaken en Tips",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl//brustbeischwang/brustbeischwang.component').then(m => m.BrustbeischwangNlComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "De perfecte spermadonor vinden: De uitgebreide gids voor vrouwen",
        description: "De perfecte spermadonor vinden: De uitgebreide gids voor vrouwen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/findespender/findespender.component').then(m => m.FindespenderNlComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Hoe word je een spermadonor? Een gids voor mannen",
        description: "Hoe word je een spermadonor? Een gids voor mannen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/werdespender/werdespender.component').then(m => m.WerdespenderNlComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Onvruchtbaarheid bij vrouwen: De 10 meest voorkomende oorzaken en hoe je ze herkent",
        description: "Onvruchtbaarheid bij vrouwen: De 10 meest voorkomende oorzaken en hoe je ze herkent",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/zehnunfruchtfrauen/zehnunfruchtfrauen.component').then(m => m.ZehnunfruchtfrauenNlComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Hoelang blijft sperma leven?",
        description: "De overleving van spermacellen is een onderwerp dat veel mythen en misverstanden met zich meebrengt.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/ueberlebenspermien/ueberlebenspermien.component').then(m => m.UeberlebenspermienNlComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Stoppen met de Pil: Redenen, Methoden en Wat Daarna Gebeurt",
        description: "Stoppen met de Pil: Redenen, Methoden en Wat Daarna Gebeurt",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/pilleabsetzen/pilleabsetzen.component').then(m => m.PilleabsetzenNlComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Kunstmatige bevruchting: Kosten voor vruchtbaarheidsklinieken, spermabanken en financiële hulp in één overzicht",
        description: "Kunstmatige bevruchting: Kosten voor vruchtbaarheidsklinieken, spermabanken en financiële hulp in één overzicht",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/kostenAI/kostenAI.component').then(m => m.KostenaiNlComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Hoe cervixslijm je vruchtbare dagen onthult: Tips voor nauwkeurige observatie",
        description: "Hoe cervixslijm je vruchtbare dagen onthult: Tips voor nauwkeurige observatie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/zervix/zervix.component').then(m => m.ZervixNlComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Natuurlijke Manieren om de Menopauze te Vertragen: Wetenschappelijk Onderbouwde Methoden en Voedingsadviezen",
        description: "Natuurlijke Manieren om de Menopauze te Vertragen: Wetenschappelijk Onderbouwde Methoden en Voedingsadviezen",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/menopause/menopause.component').then(m => m.MenopauseNlComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Oorzaken en Betekenis van de Donkere Lijn tijdens de Zwangerschap",
        description: "Linea Nigra: Oorzaken en Betekenis van de Donkere Lijn tijdens de Zwangerschap",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/linea_nigra/linea_nigra.component').then(m => m.Linea_nigraNlComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteale Fase Deficiëntie: Oorzaken, Symptomen en Effectieve Behandelingsopties",
        description: "Luteale Fase Deficiëntie: Oorzaken, Symptomen en Effectieve Behandelingsopties",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/nl/gelbkörperschwäche/gelbkörperschwäche.component').then(m => m.GelbkörperschwächeNlComponent)
    }
  ];
  static readonly polishRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Najczęściej zadawane pytania (FAQ)",
        description: "Najczęściej zadawane pytania (FAQ)",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/pl/pl-faq.component').then(m => m.PlFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Donacja komórek jajowych w Polsce: Wszystko o szansach, ryzyku i przepisach",
        description: "Donacja komórek jajowych w Polsce: Wszystko o szansach, ryzyku i przepisach",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/eizellenspende/eizellenspende.component').then(m => m.EizellenspendePlComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Sztuczne zapłodnienie: Koszty klinik, banków nasienia i dostępne wsparcie finansowe w Polsce",
        description: "Sztuczne zapłodnienie: Koszty klinik, banków nasienia i dostępne wsparcie finansowe w Polsce",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/kostenAI/kostenAI.component').then(m => m.KostenaiNlComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Niepłodność u mężczyzn: 10 najczęstszych przyczyn i jak je rozpoznać",
        description: "Niepłodność u mężczyzn: 10 najczęstszych przyczyn i jak je rozpoznać",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtPlComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Polskie Banki Spermy w Porównaniu: Koszty, Zabiegi i Wynagrodzenia",
        description: "Polskie Banki Spermy w Porównaniu: Koszty, Zabiegi i Wynagrodzenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankPlComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Porównanie najlepszych klinik leczenia niepłodności w Polsce – Koszty i usługi",
        description: "Porównanie najlepszych klinik leczenia niepłodności w Polsce – Koszty i usługi",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasitePlComponent)
    },
    {
      path: Paths.BlogPolen,
      data: {
        title: "Donacja nasienia w Polsce: Przewodnik, przepisy prawne i poszukiwanie dawcy nasienia",
        description: "Donacja nasienia w Polsce: Przewodnik, przepisy prawne i poszukiwanie dawcy nasienia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/polen/polen.component').then(m => m.PolenComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Inseminacja Domowa Metodą Kubeczkową",
        description: "Inseminacja domowa to metoda sztucznego zapłodnienia, w której nasienie mężczyzny jest pozyskiwane poprzez masturbację i zbierane do specjalnego kubeczka.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/home-insemination/home-insemination.component').then(m => m.HomeInseminationPlComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "Diagnostyka Genetyczna Preimplantacyjna (PGD) w Polsce: Procedury, Aspekty Prawne i Doświadczenia",
        description: "Diagnostyka Genetyczna Preimplantacyjna (PGD) w Polsce: Procedury, Aspekty Prawne i Doświadczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/PID/PID.component').then(m => m.PIDPlComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Współrodzicielstwo 2024: Wszystko, co musisz wiedzieć o nowoczesnych modelach rodzinnych",
        description: "Współrodzicielstwo w ramach której dwie lub więcej dorosłych osób wspólnie wychowuje dzieci, nie będąc w związku romantycznym.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingPlComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "Płyn preejakulacyjny i ciąża: Czy mogę zajść w ciążę od preejakulatu? Jakie jest rzeczywiste ryzyko?",
        description: "Dowiedz się, czy preejakulat może prowadzić do ciąży i jakie jest ryzyko związane z tym płynem. Sprawdź fakty i mity dotyczące preejakulatu i antykoncepcji.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculatePlComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Zastój spermy, ból jąder i niebieskie jądra: Co warto wiedzieć?",
        description: "Dowiedz się, czym jest zastój nasienia, co to są 'niebieskie jądra' i jak można skutecznie zapobiegać bólowi kawalerskiemu. Praktyczne porady i informacje na temat zdrowia seksualnego.",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/samenstau-en/samenstau-en.component').then(m => m.SamenstauPlComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Skurcze po ejakulacji: Przyczyny, objawy i leczenie",
        description: "Skurcze po ejakulacji: Przyczyny, objawy i leczenie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsPlComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Implantacja: definicja, czas i istotne informacje dotyczące wczesnej ciąży",
        description: "Implantacja: definicja, czas i istotne informacje dotyczące wczesnej ciąży",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/einnistung-en/einnistung-en.component').then(m => m.EinnistungPlComponent)
    },
    {
      path: Paths.BlogICI,
      data: {
        title: "Inseminacja domaciczna (ICI): Delikatna metoda planowania rodziny",
        description: "Inseminacja domaciczna (ICI): Delikatna metoda planowania rodziny",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/ici-en/ici-en.component').then(m => m.IciPlComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Zapłodnienie in vitro (IVF): Kompleksowy przewodnik: Koszty, kroki i więcej",
        description: "Zapłodnienie in vitro (IVF): Kompleksowy przewodnik: Koszty, kroki i więcej",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/ivf-en/ivf-en.component').then(m => m.IvfPlComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Surogatki: Pytania prawne, koszty, modele tradycyjne i ciążowe",
        description: "Surogatki: Pytania prawne, koszty, modele tradycyjne i ciążowe",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/leihmut-en/leihmut-en.component').then(m => m.LeihmutPlComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Zespół policystycznych jajników (PCOS): Przyczyny, objawy i leczenie w skrócie",
        description: "Zespół policystycznych jajników (PCOS): Przyczyny, objawy i leczenie w skrócie",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/pco-en/pco-en.component').then(m => m.PcoPlComponent)
    },
    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Analiza nasienia: Wszystko, co musisz wiedzieć o badaniu męskiej płodności",
        description: "Analiza nasienia: Wszystko, co musisz wiedzieć o badaniu męskiej płodności",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammPlComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Legenda o bocianie: Dlaczego bocian przynosi dzieci?",
        description: "Legenda o bocianie: Dlaczego bocian przynosi dzieci?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/klapper-en/klapper-en.component').then(m => m.KlapperPlComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Jak szybciej zajść w ciążę: 10 sprawdzonych strategii na udaną ciążę",
        description: "Jak szybciej zajść w ciążę: 10 sprawdzonych strategii na udaną ciążę",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/schnell-en/schnell-en.component').then(m => m.SchnellPlComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Choroby zakaźne przy dawstwie nasienia: Ryzyka i środki ochronne",
        description: "Choroby zakaźne przy dawstwie nasienia: Ryzyka i środki ochronne",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/krank-en/krank-en.component').then(m => m.KrankPlComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Sztuczne zapłodnienie: Metody, szanse powodzenia i koszty",
        description: "Sztuczne zapłodnienie: Metody, szanse powodzenia i koszty",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/kunst-en/kunst-en.component').then(m => m.KunstPlComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Krwawienia w ciąży: przyczyny i różnice w porównaniu z menstruacją",
        description: "Krwawienia w ciąży: przyczyny i różnice w porównaniu z menstruacją",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/mensschw-en/mensschw-en.component').then(m => m.MensschwPlComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Nieszczepione sperma vs. Szczepione sperma: Fakty dotyczące jakości nasienia po szczepieniu przeciwko COVID-19",
        description: "Nieszczepione sperma vs. Szczepione sperma: Fakty dotyczące jakości nasienia po szczepieniu przeciwko COVID-19",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/impf-en/impf-en.component').then(m => m.ImpfPlComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Zrozumienie owulacji: Klucz do zwiększenia płodności",
        description: "Zrozumienie owulacji: Klucz do zwiększenia płodności",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/ei-en/ei-en.component').then(m => m.EiPlComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "Wszystko o inseminacji wewnątrzmacicznej (IUI): procedura, koszty i skuteczność",
        description: "Wszystko o inseminacji wewnątrzmacicznej (IUI): procedura, koszty i skuteczność",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/iui-en/iui-en.component').then(m => m.IuiPlComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Rewolucja w medycynie reprodukcyjnej w leczeniu męskiej niepłodności",
        description: "ICSI: Rewolucja w medycynie reprodukcyjnej w leczeniu męskiej niepłodności",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/icsi/icsi.component').then(m => m.IcsiPlComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "Wzrost LH i owulacja: Twój przewodnik po zrozumieniu i monitorowaniu płodności",
        description: "Wzrost LH i owulacja: Twój przewodnik po zrozumieniu i monitorowaniu płodności",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/lh/lh.component').then(m => m.LhPlComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Jak infekcje Chlamydia wpływają na twoją płodność: Objawy, ryzyka i środki zapobiegawcze",
        description: "Jak infekcje Chlamydia wpływają na twoją płodność: Objawy, ryzyka i środki zapobiegawcze",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/clam-en/clam-en.component').then(m => m.ChlamydiaPlComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Wywiad: Moje doświadczenia jako dziecko dawcy w rodzinie LGBTQ+",
        description: "Wywiad: Moje doświadczenia jako dziecko dawcy w rodzinie LGBTQ+",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/emilia-en/emilia-en.component').then(m => m.EmiliaPlComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Poronienie: Przyczyny, objawy i jak znaleźć pomoc",
        description: "Poronienie: Przyczyny, objawy i jak znaleźć pomoc",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtPlComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Przedwczesny poród: przyczyny, objawy i przegląd nowoczesnych opcji leczenia",
        description: "Przedwczesny poród: przyczyny, objawy i przegląd nowoczesnych opcji leczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtPlComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Refertilizacja po sterylizacji: możliwości i szanse powodzenia",
        description: "Refertilizacja po sterylizacji: możliwości i szanse powodzenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauPlComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertylizacja: Szanse i ryzyka odwrócenia wazektomii",
        description: "Refertylizacja: Szanse i ryzyka odwrócenia wazektomii",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannPlComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Wzrost piersi i ból podczas ciąży: przyczyny i porady",
        description: "Wzrost piersi i ból podczas ciąży: przyczyny i porady",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/brust-en/brust-en.component').then(m => m.BrustPlComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Jak znaleźć idealnego dawcę nasienia: Kompleksowy przewodnik dla kobiet",
        description: "Jak znaleźć idealnego dawcę nasienia: Kompleksowy przewodnik dla kobiet",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/findespender-en/findespender-en.component').then(m => m.FindeSpenderPlComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Jak zostać dawcą nasienia? Przewodnik dla mężczyzn",
        description: "Jak zostać dawcą nasienia? Przewodnik dla mężczyzn",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderPlComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Niepłodność u kobiet: 10 najczęstszych przyczyn i jak je rozpoznać",
        description: "Niepłodność u kobiet: 10 najczęstszych przyczyn i jak je rozpoznać",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenPlComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Jak długo przeżywają plemniki?",
        description: "Dowiedz się, jak długo plemniki żyją w różnych warunkach i jak poprawić ich jakość. Sprawdź wpływ technologii i toksyn na zdrowie plemników",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienPlComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Odstawienie Pigułki: Powody, Metody i Co Dzieje Się Później",
        description: "Odstawienie Pigułki: Powody, Metody i Co Dzieje Się Później",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenPlComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Jak śluz szyjkowy ujawnia dni płodne: wskazówki dotyczące dokładnej obserwacji",
        description: "Jak śluz szyjkowy ujawnia dni płodne: wskazówki dotyczące dokładnej obserwacji",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/zervix-en/zervix-en.component').then(m => m.ZervixPlComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Naturalne sposoby na opóźnienie menopauzy: Metody naukowe i porady żywieniowe",
        description: "Naturalne sposoby na opóźnienie menopauzy: Metody naukowe i porady żywieniowe",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/menopause-en/menopause-en.component').then(m => m.MenopausePlComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Przyczyny i znaczenie ciemnej linii podczas ciąży",
        description: "Linea Nigra: Przyczyny i znaczenie ciemnej linii podczas ciąży",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/linea-en/linea-en.component').then(m => m.LineaNigraPlComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Niedoczynność ciałka żółtego: przyczyny, objawy i skuteczne opcje leczenia",
        description: "Niedoczynność ciałka żółtego: przyczyny, objawy i skuteczne opcje leczenia",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/pl/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächePlComponent)
    },
  ];
  static readonly turkeyRoutes: Routes = [
    {
      path: Paths.FAQ,
      data: {
        title: "Frequently Asked Questions (FAQ) | RattleStork",
        description: "FAQ",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./faq/tr/tr-faq.component').then(m => m.TrFaqComponent)
    },
    {
      path: Paths.Impressum,
      loadChildren: () => import('./footer/impressum/en/impressum-en.module').then(m => m.ImpressumEnModule)
    },
    {
      path: Paths.BlogEizellenspende,
      data: {
        title: "Türkiye'de Yumurta Bağışı: Fırsatlar, Riskler ve Yasalar Hakkında Her Şey",
        description: "Türkiye'de Yumurta Bağışı: Fırsatlar, Riskler ve Yasalar Hakkında Her Şey",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/eizellenspende/eizellenspende.component').then(m => m.EizellenspendeTrComponent)
    },
    {
      path: Paths.BlogCostSpermBank,
      data: {
        title: "Türkiye'deki Sperm Bankalarının Karşılaştırması: Maliyetler, Tedaviler ve Ücretler",
        description: "Türkiye'deki Sperm Bankalarının Karşılaştırması: Maliyetler, Tedaviler ve Ücretler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/kostenaufwandbank/kostenaufwandbank.component').then(m => m.KostenaufwandbankTrComponent)
    },
    {
      path: Paths.BlogCostSpermBankCopy,
      data: {
        title: "Türkiye'deki En İyi Tüp Bebek Merkezlerinin Karşılaştırması – Maliyetler ve Hizmetler",
        description: "Türkiye'deki En İyi Tüp Bebek Merkezlerinin Karşılaştırması – Maliyetler ve Hizmetler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/kiwu-parasite/kiwu-parasite.component').then(m => m.KiwuParasiteTrComponent)
    },
    {
      path: Paths.BlogRisikofaktoren,
      data: {
        title: "Erkeklerde Kısırlık: En Yaygın 10 Sebep ve Nasıl Tanıyabilirsin",
        description: "Erkeklerde Kısırlık: En Yaygın 10 Sebep ve Nasıl Tanıyabilirsin",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/Riskikofrucht/Riskikofrucht.component').then(m => m.RiskikofruchtTrComponent)
    },
    {
      path: Paths.BlogPCO,
      data: {
        title: "Polikistik Over Sendromu (PCOS): Nedenler, Belirtiler ve Tedavi Yöntemleri",
        description: "Polikistik Over Sendromu (PCOS): Nedenler, Belirtiler ve Tedavi Yöntemleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/pco-en/pco-en.component').then(m => m.PcoTrComponent)
    },
    {
      path: Paths.BlogKostenAI,
      data: {
        title: "Tüp Bebek: Türkiye'deki Tüp Bebek Merkezleri, Sperm Bankaları ve Maddi Destekler",
        description: "Tüp Bebek: Türkiye'deki Tüp Bebek Merkezleri, Sperm Bankaları ve Maddi Destekler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/kostenAI/kostenAI.component').then(m => m.KostenaiTrComponent)
    },
    {
      path: Paths.BlogTürkei,
      data: {
        title: "Türkiye'de Sperm Bağışı: Kılavuz, Yasal Durum ve Sperm Donörü Bulma",
        description: "Türkiye'de Sperm Bağışı: Kılavuz, Yasal Durum ve Sperm Donörü Bulma",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/türkei/türkei.component').then(m => m.TürkeiComponent)
    },
    {
      path: Paths.BlogPid,
      data: {
        title: "İmplantasyon Öncesi Genetik Tanı (PGD) Türkiye'de: Prosedürler, Hukuki Boyutlar ve Deneyimler",
        description: "İmplantasyon Öncesi Genetik Tanı (PGD) Türkiye'de: Prosedürler, Hukuki Boyutlar ve Deneyimler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/PID/PID.component').then(m => m.PIDTrComponent)
    },
    {
      path: Paths.BlogHomeInsemination,
      data: {
        title: "Evde İnseminasyon: Seks Olmadan Hamile Kalmak",
        description: "Evde İnseminasyon: Seks Olmadan Hamile Kalmak",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/home-insemination/home-insemination.component').then(m => m.HomeInseminationTrComponent)
    },
    {
      path: Paths.BlogCoParenting,
      data: {
        title: "Co-Parenting 2024: Modern Aile Modelleri Hakkında Bilmeniz Gereken Her Şey",
        description: "Co-Parenting 2024: Modern Aile Modelleri Hakkında Bilmeniz Gereken Her Şey",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/co-parenting-en/co-parenting-en.component').then(m => m.CoParentingTrComponent)
    },
    {
      path: Paths.BlogPreEjaculate,
      data: {
        title: "cinsel uyarılma sırasında salgılanan sıvı ile Hamilelik? Ne kadar olası? | RattleStork",
        description: "cinsel uyarılma sırasında salgılanan sıvı ile Hamilelik? Ne kadar olası?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/pre-ejaculate/pre-ejaculate.component').then(m => m.PreEjaculateTrComponent)
    },
    {
      path: Paths.BlogSemenCongestion,
      data: {
        title: "Önsevişme Sıvısı ve Gebelik: Önsevişme Sıvısı ile Hamile Kalınır mı? Gerçek Risk Nedir?",
        description: "Önsevişme Sıvısı ve Gebelik: Önsevişme Sıvısı ile Hamile Kalınır mı? Gerçek Risk Nedir?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/samenstau-en/samenstau-en.component').then(m => m.SamenstauTrComponent)
    },
    {
      path: Paths.BlogSpermCramps,
      data: {
        title: "Sperm Krampları | RattleStork",
        description: "Sperm Krampları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/sperm-cramps/sperm-cramps.component').then(m => m.SpermCrampsTrComponent)
    },
    {
      path: Paths.BlogEinnistung,
      data: {
        title: "Yerleşme: Tanım, Zamanlama ve Erken Gebelikle İlgili Temel Bilgiler",
        description: "Yerleşme: Tanım, Zamanlama ve Erken Gebelikle İlgili Temel Bilgiler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/einnistung-en/einnistung-en.component').then(m => m.EinnistungTrComponent)
    },

    {
      path: Paths.BlogSpermiogram,
      data: {
        title: "Sperm Analizi: Erkek Üreme Sağlığı Hakkında Bilmeniz Gerekenler",
        description: "Sperm Analizi: Erkek Üreme Sağlığı Hakkında Bilmeniz Gerekenler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/spermiogramm-en/spermiogramm-en.component').then(m => m.SpermiogrammTrComponent)
    },
    {
      path: Paths.BlogKlapper,
      data: {
        title: "Leylek Efsanesi: Bebekleri Getiren Leylek Masalı",
        description: "Leylek Efsanesi: Bebekleri Getiren Leylek Masalı",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/klapper-en/klapper-en.component').then(m => m.KlapperTrComponent)
    },
    {
      path: Paths.Blog10TipsAndTricks,
      data: {
        title: "Daha Hızlı Hamile Kalmanın Yolları: Başarılı Bir Hamilelik İçin 10 Kanıtlanmış Strateji",
        description: "Daha Hızlı Hamile Kalmanın Yolları: Başarılı Bir Hamilelik İçin 10 Kanıtlanmış Strateji",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/schnell-en/schnell-en.component').then(m => m.SchnellTrComponent)
    },
    {
      path: Paths.BlogDiseases,
      data: {
        title: "Sperm Bağışında Bulaşıcı Hastalıklar: Riskler ve Korunma Yöntemleri",
        description: "Sperm Bağışında Bulaşıcı Hastalıklar: Riskler ve Korunma Yöntemleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/krank-en/krank-en.component').then(m => m.KrankTrComponent)
    },
    {
      path: Paths.BlogArtificialInsemnation,
      data: {
        title: "Tüp Bebek: Yöntemler, Başarı Şansı ve Maliyetler",
        description: "Tüp Bebek: Yöntemler, Başarı Şansı ve Maliyetler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/kunst-en/kunst-en.component').then(m => m.KunstTrComponent)
    },
    {
      path: Paths.BlogMenstruationDespitePregnancy,
      data: {
        title: "Hamilelikte Kanama: Nedenleri ve Adetle Farkları",
        description: "Hamilelikte Kanama: Nedenleri ve Adetle Farkları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/mensschw-en/mensschw-en.component').then(m => m.MensschwTrComponent)
    },
    {
      path: Paths.BlogVaccination,
      data: {
        title: "Aşısız Sperm vs. Aşılı Sperm: COVID-19 Aşısı Sonrası Sperm Kalitesi Hakkında Gerçekler",
        description: "Aşısız Sperm vs. Aşılı Sperm: COVID-19 Aşısı Sonrası Sperm Kalitesi Hakkında Gerçekler",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/impf-en/impf-en.component').then(m => m.ImpfTrComponent)
    },
    {
      path: Paths.BlogOvulation,
      data: {
        title: "Ovulasyonu Anlamak: Doğurganlığınızı Artırmanın Anahtarı",
        description: "Ovulasyonu Anlamak: Doğurganlığınızı Artırmanın Anahtarı",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/ei-en/ei-en.component').then(m => m.EiTrComponent)
    },
    {
      path: Paths.BlogIUI,
      data: {
        title: "İntrauterin İnseminasyon (IUI) Hakkında Her Şey: Süreç, Maliyetler ve Başarı Oranları",
        description: "İntrauterin İnseminasyon (IUI) Hakkında Her Şey: Süreç, Maliyetler ve Başarı Oranları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/iui-en/iui-en.component').then(m => m.IuiTrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "ICSI: Erkek Kısırlığının Tedavisi İçin Üreme Tıbbında Devrim",
        description: "ICSI: Erkek Kısırlığının Tedavisi İçin Üreme Tıbbında Devrim",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/icsi/icsi.component').then(m => m.IcsiTrComponent)
    },
    {
      path: Paths.BlogIcsi,
      data: {
        title: "İntraservikal İnseminasyon (ICI): Aile Planlamasında Hassas Yöntem",
        description: "İntraservikal İnseminasyon (ICI): Aile Planlamasında Hassas Yöntem",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/ici-en/ici-en.component').then(m => m.IciTrComponent)
    },
    {
      path: Paths.BlogIVF,
      data: {
        title: "Tüp Bebek (IVF): Kapsamlı Rehber: Maliyetler, Adımlar ve Daha Fazlası",
        description: "Tüp Bebek (IVF): Kapsamlı Rehber: Maliyetler, Adımlar ve Daha Fazlası",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/ivf-en/ivf-en.component').then(m => m.IvfTrComponent)
    },
    {
      path: Paths.BlogLhOvu,
      data: {
        title: "LH Artışı ve Ovulasyon: Doğurganlığı Anlama ve İzleme Rehberiniz",
        description: "LH Artışı ve Ovulasyon: Doğurganlığı Anlama ve İzleme Rehberiniz",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/lh/lh.component').then(m => m.LhTrComponent)
    },
    {
      path: Paths.BlogSurrogacy,
      data: {
        title: "Taşıyıcı Anneliğin Hukuki Sorunları, Maliyetleri, Geleneksel ve Gestasyonel Modeller",
        description: "Taşıyıcı Anneliğin Hukuki Sorunları, Maliyetleri, Geleneksel ve Gestasyonel Modeller",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/leihmut-en/leihmut-en.component').then(m => m.LeihmutTrComponent)
    },
    {
      path: Paths.BlogChlamydia,
      data: {
        title: "Klamidya Enfeksiyonlarının Doğurganlığınızı Nasıl Etkilediği: Belirtiler, Riskler ve Korunma Yolları",
        description: "Klamidya Enfeksiyonlarının Doğurganlığınızı Nasıl Etkilediği: Belirtiler, Riskler ve Korunma Yolları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/clam-en/clam-en.component').then(m => m.ChlamydiaTrComponent)
    },
    {
      path: Paths.BlogEmilia,
      data: {
        title: "Röportaj: LGBTQ+ Bir Ailede Bağışçı Çocuk Olarak Deneyimlerim",
        description: "Röportaj: LGBTQ+ Bir Ailede Bağışçı Çocuk Olarak Deneyimlerim",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/emilia-en/emilia-en.component').then(m => m.EmiliaTrComponent)
    },
    {
      path: Paths.BlogFehlgeburt,
      data: {
        title: "Düşük: Nedenler, Belirtiler ve Yardım Bulmanın Yolları",
        description: "Düşük: Nedenler, Belirtiler ve Yardım Bulmanın Yolları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/fehlgeburt-en/fehlgeburt-en.component').then(m => m.FehlgeburtTrComponent)
    },
    {
      path: Paths.BlogFrühgeburt,
      data: {
        title: "Erken Doğum: Nedenler, Belirtiler ve Modern Tedavi Seçeneklerine Genel Bakış",
        description: "Erken Doğum: Nedenler, Belirtiler ve Modern Tedavi Seçeneklerine Genel Bakış",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/fruehgeb-en/fruehgeb-en.component').then(m => m.FrühgeburtTrComponent)
    },
    {
      path: Paths.BlogRefertilisierungFrau,
      data: {
        title: "Sterilizasyondan Sonra Yeniden Gebelik: Yöntemler ve Başarı Şansları",
        description: "Sterilizasyondan Sonra Yeniden Gebelik: Yöntemler ve Başarı Şansları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/refertifrau-en/refertifrau-en.component').then(m => m.RefertilisierungFrauTrComponent)
    },
    {
      path: Paths.BlogRefertilisierungMann,
      data: {
        title: "Refertilizasyon: Vazektomi Geri Döndürülmesinin Şansları ve Riskleri",
        description: "Refertilizasyon: Vazektomi Geri Döndürülmesinin Şansları ve Riskleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/refertimann-en/refertimann-en.component').then(m => m.RefertilisierungMannTrComponent)
    },
    {
      path: Paths.BlogBrust,
      data: {
        title: "Hamilelikte Göğüs Büyümesi ve Ağrıları: Nedenler ve İpuçları",
        description: "Hamilelikte Göğüs Büyümesi ve Ağrıları: Nedenler ve İpuçları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/brust-en/brust-en.component').then(m => m.BrustTrComponent)
    },
    {
      path: Paths.BlogFindeSpender,
      data: {
        title: "Mükemmel Sperm Donörünü Bulmak: Kadınlar İçin Kapsamlı Rehber",
        description: "Mükemmel Sperm Donörünü Bulmak: Kadınlar İçin Kapsamlı Rehber",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/findespender-en/findespender-en.component').then(m => m.FindeSpenderTrComponent)
    },
    {
      path: Paths.BlogWerdeSpender,
      data: {
        title: "Sperm Bağışçısı Nasıl Olunur? Erkekler İçin Bir Kılavuz",
        description: "Sperm Bağışçısı Nasıl Olunur? Erkekler İçin Bir Kılavuz",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/werdespender-en/werdespender-en.component').then(m => m.WerdeSpenderTrComponent)
    },
    {
      path: Paths.BlogZehnUnfruchtFrauen,
      data: {
        title: "Kadınlarda Kısırlık: En Yaygın 10 Neden ve Nasıl Tanınır?",
        description: "Kadınlarda Kısırlık: En Yaygın 10 Neden ve Nasıl Tanınır?",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/zehnunfrucht-en/zehnunfrucht-en.component').then(m => m.ZehnUnfruchtFrauenTrComponent)
    },
    {
      path: Paths.BlogUeberlebenSpermien,
      data: {
        title: "Spermler Ne Kadar Süre Yaşar? Sperm Kalitesini Artırmanın Yolları",
        description: "Spermler Ne Kadar Süre Yaşar? Sperm Kalitesini Artırmanın Yolları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/ueberleben-en/ueberleben-en.component').then(m => m.UeberlebenSpermienTrComponent)
    },
    {
      path: Paths.BlogPilleAbsetzen,
      data: {
        title: "Doğum Kontrol Hapını Bırakmak: Nedenler, Yöntemler ve Sonrasında Ne Olur",
        description: "Doğum Kontrol Hapını Bırakmak: Nedenler, Yöntemler ve Sonrasında Ne Olur",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/pilleab-en/pilleab-en.component').then(m => m.PilleAbsetzenTrComponent)
    },
    {
      path: Paths.BlogZervix,
      data: {
        title: "Servikal Mukusunuz Verimli Günlerinizi Nasıl Açığa Çıkarır: Doğru Gözlem İçin İpuçları",
        description: "Servikal Mukusunuz Verimli Günlerinizi Nasıl Açığa Çıkarır: Doğru Gözlem İçin İpuçları",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/zervix-en/zervix-en.component').then(m => m.ZervixTrComponent)
    },
    {
      path: Paths.BlogMenopause,
      data: {
        title: "Menopozu Geciktirmenin Doğal Yolları: Bilimsel Yöntemler ve Beslenme Tavsiyeleri",
        description: "Menopozu Geciktirmenin Doğal Yolları: Bilimsel Yöntemler ve Beslenme Tavsiyeleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/menopause-en/menopause-en.component').then(m => m.MenopauseTrComponent)
    },
    {
      path: Paths.BlogLineaNigra,
      data: {
        title: "Linea Nigra: Hamilelik Sırasında Oluşan Koyu Çizginin Nedenleri ve Anlamı",
        description: "Linea Nigra: Hamilelik Sırasında Oluşan Koyu Çizginin Nedenleri ve Anlamı",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/linea-en/linea-en.component').then(m => m.LineaNigraTrComponent)
    },
    {
      path: Paths.BlogGelbkörperschwäche,
      data: {
        title: "Luteal Faz Yetmezliği: Nedenleri, Belirtileri ve Etkili Tedavi Seçenekleri",
        description: "Luteal Faz Yetmezliği: Nedenleri, Belirtileri ve Etkili Tedavi Seçenekleri",
        ogUrl: 'https://rattlestork.org/'
      },
      loadComponent: () => import('./blog/blogs/tr/gelbkörper-en/gelbkörper-en.component').then(m => m.GelbkörperschwächeTrComponent)
    },
  ];

  /**
   * Adds the localized AppRoutingModule.generalRoutes to the router config, e.g. the faq route
   * with its localized component.
   */
  static addLocalizedRoutes(router: Router, LOCALE_ID: InjectionToken<string>, PLATFORM_ID: InjectionToken<Object>): () => Promise<void> {

    return () =>
      new Promise((resolve) => {

        switch (LOCALE_ID.toString()) {
          case 'de': AppRoutingModule.addGermanRoutes(router); break;
          case 'en': AppRoutingModule.addEnglishRoutes(router); break;
          case 'zh': AppRoutingModule.addChineseRoutes(router); break;
          case 'es': AppRoutingModule.addSpanishRoutes(router); break;
          case 'fr': AppRoutingModule.addFrenchRoutes(router); break;
          case 'hi': AppRoutingModule.addHindiRoutes(router); break;
          case 'ms': AppRoutingModule.addMalayRoutes(router); break;
          case 'pt': AppRoutingModule.addPortugueseRoutes(router); break;
          case 'ru': AppRoutingModule.addRussianRoutes(router); break;
          case 'pl': AppRoutingModule.addPolishRoutes(router); break;
          case 'tr': AppRoutingModule.addTurkishRoutes(router); break;
          case 'nl': AppRoutingModule.addDutchRoutes(router); break;
          case 'it': AppRoutingModule.addItalianRoutes(router); break;
          default: AppRoutingModule.addEnglishRoutes(router); break;
        }

        resolve();

      });
  }
  static addGermanRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.germanRoutes
    ]);

  }
  static addEnglishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.englishRoutes
    ]);

  }
  static addChineseRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.chineseRoutes
    ]);

  }
  static addSpanishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.spanishRoutes
    ]);

  }
  static addFrenchRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.frenchRoutes
    ]);

  }
  static addHindiRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.hindiRoutes
    ]);

  }
  static addMalayRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.malayRoutes
    ]);

  }
  static addPortugueseRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.portugueseRoutes
    ]);

  }
  static addRussianRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.russianRoutes
    ]);

  }
  static addPolishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.polishRoutes
    ]);

  }
  static addTurkishRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.turkeyRoutes
    ]);

  }
  static addDutchRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.dutchRoutes
    ]);

  }
  static addItalianRoutes(router: Router) {

    router.resetConfig([
      ...AppRoutingModule.generalRoutes,
      ...AppRoutingModule.italianRoutes
    ]);

  }
}






