import { ValidatorFn, FormGroup, ValidationErrors, FormControl, AbstractControl } from '@angular/forms';

export function validAge(): ValidatorFn {
  return (fc: AbstractControl): ValidationErrors => {

      let result: ValidationErrors = null;

      let today = new Date();
      let date = new Date(fc.value);

      if (!date.getTime()) result = {error: 'Invalid date format!'}
      else if (today.getTime() - date.getTime() < 568024668000) result = {error: 'You must be at least 18!'}
      else if (today.getTime() - date.getTime() > 3155692600000.1) result = {error: 'You are probably a liar!'}

      return result;

    };
}
