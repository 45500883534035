<ios-status-bar></ios-status-bar>

<!-- <router-outlet></router-outlet> -->

@if (splashScreenService.showSplashScreen()) {

<rs-splash-screen></rs-splash-screen>

}

<div [ngStyle]="{'visibility': splashScreenService.showSplashScreen() ? 'hidden' : 'visible'}">
  @if (this.showNavigation()) {
  <rs-nav-bar id="nav-bar-container"></rs-nav-bar>
  }
  <router-outlet></router-outlet>
</div>
